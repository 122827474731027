import { Box, Typography } from '@mui/material'
// import { ScreenIcon } from 'src/component/icons/screen'
// import { ArrowUpIcon } from 'src/component/icons/arrow-up'
import React, { FC } from 'react'
// import { ArrowDownIcon } from '../icons/arrow-down'

interface Props {
  title: string
  time: string
  value: string
  variation: string
  variationType: 'up' | 'down'
  icon: React.ReactNode
}

const CardResume: FC<Props> = ({
  time,
  title,
  value,
  variation,
  variationType,
  icon,
}) => {
  return (
    <Box
      bgcolor="#fff"
      borderRadius="24px"
      width="363px"
      height="120px"
      border="solid 1px #4865CC"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      paddingTop="12px"
      paddingLeft="18px"
      paddingRight="24px"
      paddingBottom="12px"
      boxSizing="border-box"
    >
      <Typography fontWeight={700} fontSize="16px" color="#1E3466">
        {title}
      </Typography>
      <Box display="flex" alignItems="center">
        <Box
          bgcolor="#5ED1A2"
          width="48px"
          height="48px"
          borderRadius="50%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {icon}
        </Box>
        <Box marginLeft="10px">
          <Typography fontSize="12px" fontWeight={400} color="#1E3466">
            {time}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography color="#1E3466" fontWeight={700} fontSize="24px">
              {value}
            </Typography>
            {/* <Box display="flex" alignItems="center" marginLeft="5px">
              {variationType === 'up' ? <ArrowUpIcon /> : <ArrowDownIcon />}
              <Typography
                color={variationType === 'up' ? '#03B575' : '#DB2D25'}
                fontWeight={400}
                fontSize="10px"
              >
                {`${variation}`}
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export { CardResume }
