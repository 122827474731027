import {
  CompanyApiRemoteService,
  TCompanyBigNumbersResponse,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListCompanyBigNumbersUseCase
  implements
    IUsecase<
      { companyIds?: string[]; accountIds?: string[] },
      TCompanyBigNumbersResponse
    >
{
  constructor(private userApi: CompanyApiRemoteService) {}

  public async handle({
    companyIds,
    accountIds,
  }: {
    companyIds?: string[]
    accountIds?: string[]
  }): Promise<Result<TCompanyBigNumbersResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.userApi.getCompaniesBigNumbers({
        companyIds,
        accountIds,
      })

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar os dados de big numbers'),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(
          new Error('Erro ao recuperar os dados de big numbers'),
        )
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
