import axios from 'axios'
import { StorageLocalstorage } from 'src/shared/storage/storage-localstorage'

// Use a single environment variable for API base URL and WebSocket URL if they are the same in both environments.
// It's recommended to not hard-code URLs and tokens in your codebase. Instead, use environment variables.
const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL ??
  // 'https://engenharia.api.openi.com.br/api'
  'http://localhost:49121/api'

// Apply default headers to axios for authorization token.
axios.defaults.headers.common = {
  'Content-Type': 'application/json',
  get 'x-access-token'() {
    return StorageLocalstorage.get('auth')?.token ?? undefined
  },
}
// axios.defaults.baseURL = API_BASE_URL
// axios.defaults.baseURL = 'http://localhost:49121/api'
axios.defaults.baseURL = 'https://engenharia.api.openi.com.br/api'
// axios.defaults.baseURL = 'https://api.openi.com.br/api'

export const Api = { API_BASE_URL, axios }
