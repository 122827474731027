import {
  CompanyApiRemoteService,
  ICompany,
  TCompanyCreateDTO,
  TCompanyMasCreateResponse,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, string, array } from 'yup'

export class CreateCompanyMassImportUseCase
  implements IFormUsecase<TCompanyCreateDTO[], TCompanyMasCreateResponse>
{
  constructor(private repository: CompanyApiRemoteService) {}

  public async handle(
    data: TCompanyCreateDTO[],
  ): Promise<Result<TCompanyMasCreateResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.createMass({ companies: data })

      if (response.isFailure) {
        return response
      }

      return Result.ok(response.getValue() as TCompanyMasCreateResponse)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    companies: array(
      object({
        name: string().required('Nome é obrigatório'),
        cnpj: string().required('CNPJ é obrigatório'),
      }),
    ),
  })
}
