import React, { createContext, useContext, useMemo, useState } from 'react'
import { ISubscriptionPlan } from 'src/service/service-company'

export type CompanyMassImportContextType = {
  companies: {
    id: number
    name: string
    cnpj: string
    plan: ISubscriptionPlan
  }[]
  setCompanies: (
    companies: {
      id: number
      name: string
      cnpj: string
      plan: ISubscriptionPlan
    }[],
  ) => void
}

const CompanyMassImportContext = createContext(
  {} as CompanyMassImportContextType,
)

export const CompanyMassImportProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [companies, setCompanies] = useState<
    { id: number; name: string; cnpj: string; plan: ISubscriptionPlan }[]
  >([])

  const dataProviderValue = useMemo(
    () => ({ companies, setCompanies }),
    [companies, setCompanies],
  )

  return (
    <CompanyMassImportContext.Provider value={dataProviderValue}>
      {children}
    </CompanyMassImportContext.Provider>
  )
}

export function useCompaniesMassImportContext() {
  const context = useContext(CompanyMassImportContext)
  if (!context) {
    throw new Error(
      'useCompaniesMassImportContext must be used within a DataProvider',
    )
  }
  return context
}
