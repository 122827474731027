import { useEffect, useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseSelectInput } from 'src/component/base-component/base-select-input'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import {
  BankAccountPersonTypeEnum,
  BankAccountTypeEnum,
  TAccountCreateDTO,
} from 'src/service/service-account'

import { CreateForm } from 'src/shared/util/form/form-util'
import { CreateAccountStepThreeUseCase } from 'src/usecase/bank-account/usecase-account-create-step-tree-form'
import { ListBanksUseCase } from 'src/usecase/bank/usecase-bank-list'
import { IBank } from 'src/service/service-bank'
import { BaseInputMoney } from 'src/component/base-component/base-input-money'
import { maskBankAccount, maskBankAgency } from 'src/utils/stringFormatterUtils'

interface CreateAccountPageProps {
  handleContinue: (data: Partial<TAccountCreateDTO>) => void
  useCase: CreateAccountStepThreeUseCase
  listBanksUseCase: ListBanksUseCase
  defaultAgencyNumber?: string
  defaultPersonType?: string | BankAccountPersonTypeEnum | undefined
  defaultBankNumber?: string
  defaultAccountNumber?: string
  defaultActualBalance?: string
  defaultBankLogo?: string
  defaultAccountType?: string | BankAccountTypeEnum | undefined
  isOpenFinanceFlow?: boolean
}

export function CreateSystemicAccountStepThreePage(
  props: CreateAccountPageProps,
) {
  const { companyId } = useParams()
  const navigate = useNavigate()
  const { ShowToast } = ToastFullContext()
  const [banks, setBanks] = useState<IBank[]>([])
  const [currencies, setCurrencies] = useState<
    {
      symbol: string
      name: string
    }[]
  >([])

  const stepThreeForm = CreateForm({
    fields: [
      'name',
      'personType',
      'bankNumber',
      'agencyNumber',
      'accountNumber',
      'transactionCommencementDate',
      'amount',
      'currency',
    ],
    validation: props.useCase.validation,
  })

  const loadBanks = async () => {
    const banksResult = await props.listBanksUseCase.handle()

    if (banksResult.isFailure) {
      ShowToast('error', 'Erro ao carregar a lista de bancos! Tente novamente.')
      return
    }
    const banks = banksResult.getValue()
    if (banks?.length === 0 || !banks) {
      ShowToast('error', 'Erro ao carregar a lista de bancos! Tente novamente.')
      return
    }

    setBanks(banks)

    if (
      props?.defaultAccountType &&
      [BankAccountTypeEnum.CAIXINHA, BankAccountTypeEnum.OUTROS]?.includes(
        props?.defaultAccountType as BankAccountTypeEnum,
      )
    ) {
      stepThreeForm.fields.bankNumber.setState('99999')
    }
  }

  const loadCurrencies = async () => {
    setCurrencies([
      {
        symbol: 'R$',
        name: 'R$ - Reais (BRL)',
      },
      {
        symbol: '$',
        name: '$ - Dollar (USD)',
      },
      {
        symbol: '€',
        name: '€ - Euro (EUR)',
      },
      {
        symbol: '¥',
        name: '¥ - Japanese Yen (JPY)',
      },
      {
        symbol: '£',
        name: '£ - British Pound (GBP)',
      },
      {
        symbol: 'CHF',
        name: 'CHF - Swiss Franc (CHF)',
      },
      {
        symbol: 'C$',
        name: 'C$ - Canadian Dollar (CAD)',
      },
      {
        symbol: '₹',
        name: '₹ - Indian Rupee (INR)',
      },
      {
        symbol: '₽',
        name: '₽ - Russian Ruble (RUB)',
      },
    ])

    stepThreeForm.fields.currency.setState('R$')
  }

  useEffect(() => {
    loadBanks()
    loadCurrencies()
  }, [])

  useEffect(() => {
    if (props.defaultBankNumber) {
      if (
        props?.defaultAccountType &&
        [BankAccountTypeEnum.CAIXINHA, BankAccountTypeEnum.OUTROS]?.includes(
          props?.defaultAccountType as BankAccountTypeEnum,
        )
      ) {
        stepThreeForm.fields.bankNumber.setState('99999')
      } else {
        stepThreeForm.fields.bankNumber.setState(
          Number(props.defaultBankNumber).toString(),
        )
      }
    }
    if (props.defaultAgencyNumber) {
      stepThreeForm.fields.agencyNumber.setState(
        () => props.defaultAgencyNumber,
      )
    }
    if (props.defaultAccountNumber) {
      stepThreeForm.fields.accountNumber.setState(
        () => props.defaultAccountNumber,
      )
    }
    if (props.defaultPersonType !== null) {
      stepThreeForm.fields.personType.setState(() => props.defaultPersonType)
    }
    if (props.defaultActualBalance) {
      stepThreeForm.fields.amount.setState(() => props.defaultActualBalance)
    }
  }, [props])

  const handleCreateAcountUsecase = async () => {
    const bank = banks.find(
      (f) => f.code === stepThreeForm.fields.bankNumber.value,
    )?.name
    const tempCommencementDate = new Date(
      stepThreeForm.fields.transactionCommencementDate.value,
    )
    tempCommencementDate.setUTCHours(12, 0, 0, 0)
    const transactionCommencementDateFormatted =
      tempCommencementDate.toUTCString()

    const data = {
      name: stepThreeForm.fields.name.value,
      personType: stepThreeForm.fields.personType.value,
      bankNumber: stepThreeForm.fields.bankNumber.value,
      bank,
      agencyNumber: stepThreeForm.fields.agencyNumber.value,
      accountNumber: stepThreeForm.fields.accountNumber.value,
      transactionCommencementDate: new Date(
        transactionCommencementDateFormatted,
      ),
      amount: stepThreeForm.fields.amount.value,
      bankLogo: props.defaultBankLogo,
    }

    props.handleContinue(data)
  }

  const handleValidate = async () => {
    try {
      const validationResult = await stepThreeForm.validate()

      if (validationResult.isFailure) {
        ShowToast('error', 'Verifique todos os campos')
        return
      }

      if (!props.isOpenFinanceFlow && !stepThreeForm.fields.amount.value) {
        ShowToast('error', 'Saldo atual é obrigatório')
        return
      }

      handleCreateAcountUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.',
      )
    }
  }

  const handleCancel = () => {
    navigate(`/company/${companyId}/account/list`)
  }

  const handleBlurAgency = async (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    stepThreeForm.fields.agencyNumber.setState(
      maskBankAgency(stepThreeForm.fields.agencyNumber.value),
    )
  }

  const handleBlurAcountNumber = async (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    stepThreeForm.fields.accountNumber.setState(
      maskBankAccount(stepThreeForm.fields.accountNumber.value),
    )
  }

  return (
    <Stack
      width="100%"
      height="100%"
      maxWidth="780px"
      sx={{ backgroundColor: '#F4F8FA' }}
      gap="24px"
      marginTop="32px"
    >
      <Typography
        fontWeight="700"
        fontSize="24px"
        lineHeight="32px"
        color="#1E1E1E"
      >
        Vamos preencher alguns dados importantes da sua conta!
      </Typography>

      <Stack direction="column" gap="24px" marginTop="24px">
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          gap="24px"
        >
          <BaseSelectInput
            label="Pessoa Física ou Jurídica"
            fullWidth
            labelBackground="#F4F8FA"
            disabled={props.isOpenFinanceFlow}
            options={[
              {
                label: 'Pessoa física',
                value: BankAccountPersonTypeEnum.FISICA,
              },
              {
                label: 'Pessoa jurídica',
                value: BankAccountPersonTypeEnum.JURIDICA,
              },
            ]}
            {...stepThreeForm.fields.personType}
          />
          <BaseSelectInput
            label="Instituição financeira"
            labelBackground="#F4F8FA"
            disabled={
              props.isOpenFinanceFlow ||
              !!(
                props?.defaultAccountType &&
                [
                  BankAccountTypeEnum.CAIXINHA,
                  BankAccountTypeEnum.OUTROS,
                ]?.includes(props?.defaultAccountType as BankAccountTypeEnum)
              )
            }
            options={banks.map((bank) => {
              return {
                label: bank.name,
                value: bank.code,
              }
            })}
            fullWidth
            showSearchInput
            {...stepThreeForm.fields.bankNumber}
          />
        </Stack>

        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          gap="24px"
        >
          <BaseInput
            label="Agência"
            fullWidth
            disabled={
              props.isOpenFinanceFlow ||
              !!(
                props?.defaultAccountType &&
                [
                  BankAccountTypeEnum.CAIXINHA,
                  BankAccountTypeEnum.OUTROS,
                ]?.includes(props?.defaultAccountType as BankAccountTypeEnum)
              )
            }
            labelBackground="#F4F8FA"
            onBlurEvent={handleBlurAgency}
            {...stepThreeForm.fields.agencyNumber}
          />
          <BaseInput
            label="Número da conta"
            fullWidth
            disabled={
              props.isOpenFinanceFlow ||
              !!(
                props?.defaultAccountType &&
                [
                  BankAccountTypeEnum.CAIXINHA,
                  BankAccountTypeEnum.OUTROS,
                ]?.includes(props?.defaultAccountType as BankAccountTypeEnum)
              )
            }
            labelBackground="#F4F8FA"
            onBlurEvent={handleBlurAcountNumber}
            {...stepThreeForm.fields.accountNumber}
          />
        </Stack>

        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          gap="24px"
        >
          <BaseInput
            label="Início dos lançamentos (DD/MM/AAAA)"
            fullWidth
            labelBackground="#F4F8FA"
            type="date"
            {...stepThreeForm.fields.transactionCommencementDate}
          />
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            gap="24px"
          >
            <BaseSelectInput
              label="Moeda"
              labelBackground="#F4F8FA"
              options={currencies.map((currency) => {
                return {
                  label: currency.name,
                  value: currency.symbol,
                }
              })}
              fullWidth
              {...stepThreeForm.fields.currency}
            />
            <BaseInputMoney
              label={props.isOpenFinanceFlow ? 'Saldo atual' : 'Saldo inicial'}
              prefix={`${
                stepThreeForm.fields.currency.value?.trim()?.length !== 0
                  ? stepThreeForm.fields.currency.value
                  : 'R$'
              } `}
              disabled={props.isOpenFinanceFlow}
              fullWidth
              labelBackground="#F4F8FA"
              {...stepThreeForm.fields.amount}
            />
          </Stack>
        </Stack>

        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          gap="24px"
        >
          <BaseInput
            label="Nome da Conta"
            fullWidth
            {...stepThreeForm.fields.name}
          />
        </Stack>

        <Stack
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
          direction="row"
          gap="32px"
        >
          <Button
            fullWidth={false}
            onClick={handleCancel}
            variant="text"
            sx={{
              padding: '12px 24px 12px 16px',
              gap: '8px',
              width: '110px',
              height: '40px',
            }}
          >
            Cancelar
          </Button>
          <Button
            fullWidth={false}
            variant="contained"
            onClick={handleValidate}
            sx={{
              padding: '12px 24px 12px 16px',
              gap: '8px',
              width: '120px',
              height: '40px',
            }}
          >
            Continuar
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
