import {
  AccountApiRemoteService,
  IBankAccount,
} from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class CompanyAccountGetActualOpenFinanceBalanceUseCase
  implements
    IUsecase<
      { accountId: string; holderId: string },
      { actualBalance: string }
    >
{
  constructor(private userApi: AccountApiRemoteService) {}

  public async handle({
    accountId,
    holderId,
  }: {
    accountId: string
    holderId: string
  }): Promise<Result<{ actualBalance: string }>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response =
        await this.userApi.getCompanyAccountActualOpenFinanceBalance(
          accountId,
          holderId,
        )

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar o saldo atual da conta'),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(
          new Error('Erro ao recuperar o saldo atual da conta'),
        )
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
