import { Button, Stack } from '@mui/material'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInputMoney } from 'src/component/base-component/base-input-money'
import { ModalFullContext } from 'src/component/modal/modal-provider'

export const SyncSystemicAccountsModal: React.FC<{
  currentSelectedAccount: string
  currentSelectedAccountBalance: string
  currentSelectedAccountStartDate: string
  setLedgerAccount: (value: React.SetStateAction<string>) => void
  setLedgerAccountBalance: (value: React.SetStateAction<string>) => void
  setLedgerAccountStartDate: (value: React.SetStateAction<string>) => void
  handle: (
    selectedAccount: string,
    currentSelectedAccountBalance: string,
    currentSelectedAccountStartDate: string,
  ) => void
}> = ({
  currentSelectedAccount,
  currentSelectedAccountBalance,
  currentSelectedAccountStartDate,
  setLedgerAccount,
  setLedgerAccountBalance,
  setLedgerAccountStartDate,
  handle,
}) => {
  const { HideModal } = ModalFullContext()

  const handleClose = () => {
    HideModal()
  }

  const handleSync = (account: string) => {
    if (!account) {
      return
    }
    handle &&
      handle(
        currentSelectedAccount as string,
        currentSelectedAccountBalance as string,
        currentSelectedAccountStartDate as string,
      )
  }

  return (
    <Stack width="500px" height={'150px'} gap="16px">
      <Stack width="100%">
        <BaseInput
          label="Conta contábil"
          fullWidth
          labelBackground="#F4F8FA"
          error=""
          value={currentSelectedAccount}
          setState={(e) => setLedgerAccount(e)}
        />
      </Stack>
      <Stack width="100%" direction="row" gap="12px">
        <BaseInputMoney
          label="Saldo Inicial"
          fullWidth
          labelBackground="#F4F8FA"
          value={currentSelectedAccountBalance}
          error=""
          setState={(e) => setLedgerAccountBalance(e)}
        />
        <BaseInput
          label="Início dos lançamentos (DD/MM/AAAA)"
          fullWidth
          labelBackground="#F4F8FA"
          disabled
          type="text"
          value={currentSelectedAccountStartDate}
          error=""
          setState={(e) => setLedgerAccountStartDate(e)}
        />
      </Stack>
      <Stack
        direction="row"
        gap="16px"
        marginTop="24px"
        justifyContent="end"
        alignItems="end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => handleClose()}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Cancelar
        </Button>

        <Button
          fullWidth={false}
          variant={
            [null, undefined, ''].includes(currentSelectedAccount)
              ? 'outlined'
              : 'contained'
          }
          disabled={[null, undefined, ''].includes(currentSelectedAccount)}
          onClick={() => handleSync(currentSelectedAccount)}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Vincular
        </Button>
      </Stack>
    </Stack>
  )
}
