import { CompanyApiRemoteService } from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, string } from 'yup'

export class TransferCompanyOwnerUseCase
  implements IFormUsecase<{ companyId: string; userId: string }, void>
{
  constructor(private repository: CompanyApiRemoteService) {}

  public async handle(data: {
    companyId: string
    userId: string
  }): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.transferCompanyOwner(data)

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    companyId: string().required('Empresa é obrigatória'),
  })
}
