import { ErrorOutlineOutlined } from '@mui/icons-material'
import {
  FormControlLabel,
  InputAdornment,
  SvgIconTypeMap,
  Switch,
  TextField,
  useTheme,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import ReactInputMask from 'react-input-mask'
import { JsxElement } from 'typescript'

export type BaseSwitchProps = {
  setState: (value: boolean) => void
  error: string | null
  label?: string
  checked: boolean
  defaultChecked?: boolean
  disabled?: boolean
}
export const BaseSwitch = ({
  setState,
  error,
  label,
  checked,
  defaultChecked,
  disabled = false,
}: BaseSwitchProps) => {
  const theme = useTheme()
  return (
    <FormControlLabel
      control={
        <Switch
          defaultChecked={defaultChecked}
          onChange={(event) => setState(event.target.checked)}
          disabled={disabled}
        />
      }
      label={label}
      checked={checked}
    />
  )
}
