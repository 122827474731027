/* eslint-disable prettier/prettier */
import { useMemo, useState } from 'react';
import { ToastFullContext } from 'src/component/base-component/base-snackbar';
import {
  CompanyAccountEntryConciliationStatusEnum,
  IAccountingEntries,
  IListAccountingEntriesBalanceResponseDTO,
  IListAccountingEntriesBankBalanceResponseDTO,
  IListAccountingEntriesCashflowResponseDTO,
  IListAccountingEntriesInformations, IListAccountingEntriesResponseDTO,
  TCompanyAccountEntryCreate
} from 'src/service/service-company';


import { IconButton, Stack, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { BaseDropDownInput } from 'src/component/base-component/base-dropdown';
import { BaseInputAutocomplete } from 'src/component/base-component/base-input-autocomplete';
import { MenuDotIcon } from 'src/component/icons/menu-dot';
import { ITableModalType } from 'src/component/table/filterModal/table-filter-modal';
import { ColumnsCommon } from 'src/component/table/header';
import { HeaderWithFilter } from 'src/component/table/header-with-filter';
import { formatMoney } from 'src/shared/util/formatter/formatter-utils';
import { ListLedgerAccountsUseCase } from 'src/usecase/bank-account/usecase-account-get-ledger-accounts';
import { ListAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-list';
import { UpdateAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-update';
import { ListCompanyVendorsUseCase } from 'src/usecase/classifier/usecase-company-vendors-list';
import { ListAccountingEntriesBankBalanceUseCase } from 'src/usecase/classifier/usecase-accounting-entries-bank-balance-list';
import { ListAccountingEntriesBalanceUseCase } from 'src/usecase/classifier/usecase-accounting-entries-balance-list';
import { ListAccountingEntriesCashflowUseCase } from 'src/usecase/classifier/usecase-accounting-entries-cashflow-list';
import { CascadeButton } from 'src/component/buttons/cascade-button';
import { ClassifyAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-classify';
import { BankAccountTransferMethodEnum } from 'src/service/service-bank';
import { ModalFullContext } from 'src/component/modal/modal-provider';
import { DismemberEntryModal } from 'src/component/modal/classifier/modal-classifier-dismember';
import { AccountingEntryDismemberUseCase } from 'src/usecase/classifier/usecase-accounting-entries-dismember';
import { ModalClassifierEditEntry } from 'src/component/modal/classifier/modal-edit-entry';

export type IFilterModalField = 'date' | 'description' | 'client' | 'value' | 'credit'  | 'debit' | 'financialCategory'
export type IFilterModalType = {
  modalType: ITableModalType,
  fieldType: IFilterModalField,
  open: boolean
}

export function useClassifier(
  listAccoutingEntriesUseCase: ListAccountingEntriesUseCase,
  updateAccountingEntriesUseCase: UpdateAccountingEntriesUseCase,
  listCompanyVendorsUseCase: ListCompanyVendorsUseCase,
  listLedgerAccountsUseCase: ListLedgerAccountsUseCase,
  listAccountingEntriesBankBalanceUseCase: ListAccountingEntriesBankBalanceUseCase,
  listAccountingEntriesBalanceUseCase: ListAccountingEntriesBalanceUseCase,
  listAccountingEntriesCashflowUseCase: ListAccountingEntriesCashflowUseCase,
  classifyAccountingEntriesUseCase: ClassifyAccountingEntriesUseCase,
  accountingEntryDismemberUseCase: AccountingEntryDismemberUseCase,
  companyId: string,
) {
  const { ShowToast } = ToastFullContext();
  const { ShowModal, HideModal } = ModalFullContext()
  
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGridIds, setSelectedGridIds] = useState<string[]>([]);
  const [accountingEntries, setAccountingEntries] = useState<
    IAccountingEntries[]
  >([]);
  const [informationsBankBalance, setInformationsBankBalance] = useState<IListAccountingEntriesBankBalanceResponseDTO>()
  const [informationsCashflow, setInformationsCashflow] = useState<IListAccountingEntriesCashflowResponseDTO>()
  const [informationsBalance, setInformationsBalance] = useState<IListAccountingEntriesBalanceResponseDTO>()
  const [vendors, setVendors] = useState<string[]>([]);
  const [finalcialCategories, setFinancialCategories] = useState<string[]>([]);
  const [debits, setDebits] = useState<string[]>([]);
  const [ledgerAccounts, setLedgerAccounts] = useState<string[]>([]);
  const [credits, setCredits] = useState<string[]>([]);
  const [columnsTableSelected, setColumnsTableSelected] = useState<string[]>([
    'date',
    'description',
    'debit',
    'credit',
    'value',
    'historic',
    'financialCategory'
  ]);
  const [currentFilterModal, setCurrentFilterModal] = useState<IFilterModalType | undefined>(undefined)
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState<string>('')
  const [manualClassifyFinish, setManualClassifyFinish] = useState<boolean>(false)
  const [updateCashflow, setUpdateCashflow] = useState<boolean>(false)

  const handleSelectColumns = (column: string) => {
    const exists = columnsTableSelected.find(i => i === column);

    if (exists) {
      const filtered = columnsTableSelected.filter(i => i !== column);
      setColumnsTableSelected(filtered);
    } else {
      setColumnsTableSelected(prev => [...prev, column]);
    }
  };

  const handleUpdateAccountingEntry = async ({
    entryId,
    oldValue,
    newValue,
    field,
  }: {
    entryId: string;
    oldValue: string;
    newValue: string;
    field: string;
  }) => {
    try {
      const normalizeNewValue = newValue === '' ? null : newValue;
      if (oldValue === normalizeNewValue) return;

      const usecaseResult = await updateAccountingEntriesUseCase.handle({
        companyId,
        entryId,
        field,
        value: newValue,
      });

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao atualizar os dados do classificador. Tente novamente mais tarde.',
        );
      }
      setAccountingEntries((prevItems) => 
        prevItems.map((currentItem) => 
          currentItem.id === entryId ? { ...currentItem, [field]: newValue } : currentItem
        )
      );
      setUpdateCashflow(!manualClassifyFinish)
    } catch (error) {
      ShowToast('error', 'Ocorreu um erro ao buscar atualizar os dados.');
    }
  };

  const vendorsOptions = useMemo(() => {
    const payload = [
      { value: 'none', label: 'Sem fornecedor' },
    ];
    if (vendors?.length) {
      const normalize = vendors?.map(vendor => ({
        value: vendor,
        label: vendor,
      }));
      return [...payload, ...normalize];
    } else {
      return payload;
    }
  }, [vendors]);

  const handleListAccountingEntries = 
    async (
      companyId: string,
      page: number,
      initialDate: Date | undefined,
      finalDate: Date | undefined,
      minValue: string | undefined,
      maxValue: string | undefined,
      accountsSelected: string[],
      selectedVendors: string[] | undefined,
      selectedDebits: string[] | undefined,
      selectedCredits: string[] | undefined,
      query?: string | undefined,
      accountingEntriesAlreadyClassified?: 'true' | 'false' | undefined,
      showDespised?: 'true' | 'false' | undefined
    ) => {
      
      if (accountsSelected.length === 0) {
        setAccountingEntries([])
        return
      }

      try {
        setIsLoading(true);
  
        // Chamada à API para obter os dados
        const usecaseResult = await listAccoutingEntriesUseCase.handle({
          companyId,
          bankAccountId: accountsSelected,
          page,
          vendor: selectedVendors,
          dateStart: initialDate,
          dateEnd: finalDate,
          query,
          valueMaximum: maxValue,
          valueMinimum: minValue,
          credit: selectedCredits,
          debit: selectedDebits,
          accountingEntriesAlreadyClassified,
          showDespised,
        });
        if (usecaseResult.isFailure) {
          ShowToast(
            'error',
            'Ocorreu um erro ao buscar os dados do classificador. Tente novamente mais tarde.',
          );
          setIsLoading(false);
          return;
        }
  
        const transfersData =
          usecaseResult.getValue() as IListAccountingEntriesResponseDTO;

        const normalize: IAccountingEntries[] =
          transfersData?.companyAccountingEntries?.map((entry) => {
            let description = "Transferência -";
            let partieName = ''

            if (
              entry.bankTransferPartie && (
              entry.bankTransferPartie.name ||
              entry.bankTransferPartie.document ||
              entry.bankTransferPartie.type)
            ) {
              if (entry.bankTransferPartie.name) {
                description += ` ${entry.bankTransferPartie.name}`;
                partieName = `${entry.bankTransferPartie.name} ${entry.bankTransferPartie.document ? entry.bankTransferPartie.document : ''}`
              }
              if (![null, undefined, ""].includes(entry.bankTransferType)) {
                description += ` ${entry.bankTransferType}`;
              }
              if (entry.bankTransferPartie.type) {
                description += ` ${
                  entry.bankTransferPartie.type === "PESSOA_NATURAL" ? "PF" : "PJ"
                }`;
              }
              if (entry.bankTransferPartie.document) {
                if (entry.bankTransferPartie.document.length > 11) {
                  const formattedDocument = entry.bankTransferPartie.document;
                  description += ` ${formattedDocument}`;
                } else {
                  const formattedDocument = entry.bankTransferPartie.document;
                  description += ` ${formattedDocument}`;
                }
              }
            } else {
              if (![null, undefined, ""].includes(entry.bankTransferType)) {
                description += ` ${entry.bankTransferType}`;
              }
              if (!entry.bankTransferType) {
                if (entry.description) {
                  description += ` ${entry.description}`;
                } else {
                  description += ` ${entry.method}`;
                }
              }
            }
            return {
              id: entry.id,
              date: format(new Date(entry.date), 'dd/MM/yyyy'),
              bankTransferDate: format(new Date(entry.bankTransferDate), 'dd/MM/yyyy'),
              description,
              supplier: partieName,
              value: `${entry?.method === BankAccountTransferMethodEnum.DEBITO ? '-' : ''} ${formatMoney(Number(entry.value))}`,
              debit: entry?.debit,
              credit: entry?.credit,
              historic: entry?.historic,
              financialCategory: entry?.financialCategory,
              status: entry?.status,
              conciliatedEntries: entry?.conciliatedEntries,
              origin: entry?.origin,
            }
          }) || [];

        if (normalize.length) {
          if (page === 1) {
            setAccountingEntries(normalize);
          } else {
            setAccountingEntries(prev => {
              const updated = [...prev, ...normalize];
              return updated;
            });
          }
          
        } else {
          setAccountingEntries([])
        }
  
        setCurrentPage(page);
        setTotalPages(transfersData.pages);
        setTotalRegisters(transfersData.count);
        await handleListAccountingEntriesCashflow(
          companyId,
          page,
          initialDate,
          finalDate,
          minValue,
          maxValue,
          accountsSelected,
          selectedVendors,
          query,
          accountingEntriesAlreadyClassified,
          showDespised
        )
        await handleListAccountingEntriesBalance(
          companyId,
          page,
          initialDate,
          finalDate,
          minValue,
          maxValue,
          accountsSelected,
          selectedVendors,
          query,
          accountingEntriesAlreadyClassified,
          showDespised
        )
        await handleListAccountingEntriesBankBalance(
          companyId,
          page,
          initialDate,
          finalDate,
          minValue,
          maxValue,
          accountsSelected,
          selectedVendors,
          query,
          accountingEntriesAlreadyClassified,
          showDespised
        )
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        ShowToast('error', 'Ocorreu um erro ao buscar os dados da listagem.');
      }
    };

  const handleListAccountingEntriesCashflow = 
    async (
      companyId: string,
      page: number,
      initialDate: Date | undefined,
      finalDate: Date | undefined,
      minValue: string | undefined,
      maxValue: string | undefined,
      accountsSelected: string[],
      selectedVendors: string[] | undefined,
      query?: string | undefined,
      accountingEntriesAlreadyClassified?: 'true' | 'false' | undefined,
      showDespised?: 'true' | 'false' | undefined
    ) => {
      try {
        const tempSelectedAccounts = accountsSelected?.filter(f => f !== "null");

        if (tempSelectedAccounts?.length === 0) return

        const usecaseResult = await listAccountingEntriesCashflowUseCase.handle({
          companyId,
          bankAccountId: tempSelectedAccounts,
          page,
          vendor: selectedVendors,
          dateStart: initialDate,
          dateEnd: finalDate,
          query,
          valueMaximum: maxValue,
          valueMinimum: minValue,
          accountingEntriesAlreadyClassified,
          showDespised
        });
        if (usecaseResult.isFailure) {
          ShowToast(
            'error',
            'Ocorreu um erro ao buscar os dados do classificador. Tente novamente mais tarde.',
          );
          return;
        }
  
        const transfersData =
          usecaseResult.getValue();

          if (transfersData) {
          setInformationsCashflow(transfersData)
        }
      } catch (error) {
        ShowToast('error', 'Ocorreu um erro ao buscar os dados da listagem.');
      }
    };

  const handleListAccountingEntriesBalance = 
    async (
      companyId: string,
      page: number,
      initialDate: Date | undefined,
      finalDate: Date | undefined,
      minValue: string | undefined,
      maxValue: string | undefined,
      accountsSelected: string[],
      selectedVendors: string[] | undefined,
      query?: string | undefined,
      accountingEntriesAlreadyClassified?: 'true' | 'false' | undefined,
      showDespised?: 'true' | 'false' | undefined
    ) => {
      try {
        const tempSelectedAccounts = accountsSelected?.filter(f => f !== "null");

        if (tempSelectedAccounts?.length === 0) return
        // Chamada à API para obter os dados
        const usecaseResult = await listAccountingEntriesBalanceUseCase.handle({
          companyId,
          bankAccountId: tempSelectedAccounts,
          page,
          vendor: selectedVendors,
          dateStart: initialDate,
          dateEnd: finalDate,
          query,
          valueMaximum: maxValue,
          valueMinimum: minValue,
          accountingEntriesAlreadyClassified,
          showDespised,
        });
        if (usecaseResult.isFailure) {
          ShowToast(
            'error',
            'Ocorreu um erro ao buscar os dados do classificador. Tente novamente mais tarde.',
          );
          return;
        }
  
        const transfersData =
          usecaseResult.getValue();

          if (transfersData) {
          setInformationsBalance(transfersData)
        }
      } catch (error) {
        ShowToast('error', 'Ocorreu um erro ao buscar os dados da listagem.');
      }
    };

  const handleListAccountingEntriesBankBalance = 
    async (
      companyId: string,
      page: number,
      initialDate: Date | undefined,
      finalDate: Date | undefined,
      minValue: string | undefined,
      maxValue: string | undefined,
      accountsSelected: string[],
      selectedVendors: string[] | undefined,
      query?: string | undefined,
      accountingEntriesAlreadyClassified?: 'true' | 'false' | undefined,
      showDespised?: 'true' | 'false' | undefined
    ) => {
      try {
        const tempSelectedAccounts = accountsSelected?.filter(f => f !== "null");

        if (tempSelectedAccounts?.length === 0) return

        // Chamada à API para obter os dados
        const usecaseResult = await listAccountingEntriesBankBalanceUseCase.handle({
          companyId,
          bankAccountId: tempSelectedAccounts,
          page,
          vendor: selectedVendors,
          dateStart: initialDate,
          dateEnd: finalDate,
          query,
          valueMaximum: maxValue,
          valueMinimum: minValue,
          accountingEntriesAlreadyClassified,
          showDespised
        });
        if (usecaseResult.isFailure) {
          ShowToast(
            'error',
            'Ocorreu um erro ao buscar os dados do classificador. Tente novamente mais tarde.',
          );
          return;
        }
  
        const transfersData =
          usecaseResult.getValue();

          if (transfersData) {
          setInformationsBankBalance(transfersData)
        }
      } catch (error) {
        ShowToast('error', 'Ocorreu um erro ao buscar os dados da listagem.');
      }
    };

  const handleListCompanyVendors = async (companyId: string) => {
    try {
      setIsLoading(true);
      const usecaseResult = await listCompanyVendorsUseCase.handle(companyId);
      setIsLoading(false);

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao buscar os dados dos clientes e fornecedores. Tente novamente mais tarde.',
        );
      }

      const value = usecaseResult.getValue();

      if (value) {
        setVendors(value.vendors);
        setFinancialCategories(value.financialCategories)
      }
    } catch (error) {
      setIsLoading(false);
      ShowToast('error', 'Ocorreu um erro ao buscar o dados dos clientes e fornecedores.');
    }
  };

  const handleGeLedgerAccounts = async () => {
    setIsLoading(true)
    const ledgerAccountResult = await listLedgerAccountsUseCase.handle({
      companyId: companyId as string,
      query: '',
    })

    if (ledgerAccountResult.isFailure) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar a contas bancária. Tente novamente mais tarde.',
      )
    }

    const ledgerAccountData = ledgerAccountResult.getValue()
    setCredits(ledgerAccountData?.credits ?? [])
    setDebits(ledgerAccountData?.debits ?? [])
    const combined = [...new Set([...ledgerAccountData?.credits ?? [], ...ledgerAccountData?.debits ?? []])]
    setLedgerAccounts(combined)
    setIsLoading(false)
  }

  const handleClassifyManual = async (item: IAccountingEntries) => {
    try {
      setIsLoading(true)
      const usecaseResult = await classifyAccountingEntriesUseCase.handle(
        {
          data: {
            companyId: companyId as string,
            bankAccountId: undefined,
            entryId: [item.id],
            reclassify: true,
          },
        },
      )

      setIsLoading(false)

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
        )
      }
      setManualClassifyFinish(!manualClassifyFinish)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
      )
    }
  }

  const handleDespise = async (item: IAccountingEntries) => {
    setIsLoading(true)

    try {
      await updateAccountingEntriesUseCase.handle({
        companyId: companyId as string,
        entryId: item.id,
        field: 'status',
        value: CompanyAccountEntryConciliationStatusEnum.DESPISED,
      })
      setAccountingEntries((prevItems) => 
        prevItems.map((currentItem) => 
          currentItem.id === item.id ? { ...item, status: CompanyAccountEntryConciliationStatusEnum.DESPISED } : currentItem
        )
      );
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleDismember = async (data: {value: string, partieName: string, description: string}[], entryId: string) => {
    setIsLoading(true)

    try {
      await accountingEntryDismemberUseCase.handle({
        companyId: companyId as string,
        entryId,
        data,
      })
      setManualClassifyFinish(!manualClassifyFinish);
      HideModal();
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleDismemberModal = async (item: IAccountingEntries) => {
    if (item.status === CompanyAccountEntryConciliationStatusEnum.CONCILIATED || item.status === CompanyAccountEntryConciliationStatusEnum.CONCILIATED_PARTIAL) {
      return ShowToast(
        'error',
        'Registros já conciliados total ou parcialmente não podem ser desmembrados.',
      )
    }
    return ShowModal({
      content: (
        <DismemberEntryModal
          handleCancelAction={() => HideModal()}
          handleConfirmAction={handleDismember}
          data={item}
        />
      ),
      title: '',
    })
  }

  const handleEdit = async (item: TCompanyAccountEntryCreate) => {
    setIsLoading(true)

    try {
      await updateAccountingEntriesUseCase.handle({
        companyId: companyId as string,
        entryId: item.id as string,
        multipleFields: [
          {
            field: 'value',
            value: item.value as string,
          },
          {
            field: 'date',
            value: item.date as string,
          },
          {
            field: 'credit',
            value: item.credit  as string,
          },
          {
            field: 'debit',
            value: item.debit  as string,
          },
          {
            field: 'financialCategory',
            value: item.financialCategory  as string,
          },
          {
            field: 'historic',
            value: item.historic  as string,
          },
        ]
      })
      setAccountingEntries((prevItems) => 
        prevItems.map((currentItem) => 
          currentItem.id === item.id as string ? { ...currentItem, ...item, value: formatMoney(Number(item.value)) } : currentItem
        )
      );
      HideModal();
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleEditEntry = async (item: TCompanyAccountEntryCreate) => {
    return ShowModal({
      content: (
        <ModalClassifierEditEntry
          handle={handleEdit}
          companyId={companyId}
          data={item}
        />
      ),
      title: 'Editar lançamento',
    })
  }


  const handleRecover = async (item: IAccountingEntries) => {
    setIsLoading(true)

    try {
      await updateAccountingEntriesUseCase.handle({
        companyId: companyId as string,
        entryId: item.id,
        field: 'status',
        value: CompanyAccountEntryConciliationStatusEnum.NOT_CONCILIATED,
      })
      setAccountingEntries((prevItems) => 
        prevItems.map((currentItem) => 
          currentItem.id === item.id ? { ...item, status: CompanyAccountEntryConciliationStatusEnum.NOT_CONCILIATED } : currentItem
        )
      );
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const HistoricVariables = [
    {
      label: 'Data',
      value: 'data',
    },
    {
      label: 'Descrição',
      value: 'descricao',
    },
    {
      label: 'Valor',
      value: 'valor',
    },
    {
      label: 'Débito',
      value: 'debito',
    },
    {
      label: 'Crédito',
      value: 'credito',
    },
  ]
  const columns = useMemo(() => {
    const order = [
      'Data',
      'Descrição - Clientes e fornecedor',
      'Valor',
      'Débito',
      'Crédito',
      'Categoria financeira',
      'Histórico',
      'Ações',
    ]
    const makeGrid: GridColDef[] = [
      {
        ...ColumnsCommon,
        flex: 0.5,
        ...{
          field: 'Ações',
          headerName: 'Ações',
          minWidth: 100,
          align: 'left',
          renderCell: ({ row }) => (
            <CascadeButton
              variant="secondary"
              direction="right"
              options={[
                {
                  label: 'Editar',
                  onClick: () => handleEditEntry(row),
                },
                {
                  label: 'Aplicar regra',
                  onClick: () => handleClassifyManual(row),
                },
                {
                  label: row.status === CompanyAccountEntryConciliationStatusEnum.DESPISED ? 'Recuperar' : 'Desprezar',
                  onClick: () => row.status === CompanyAccountEntryConciliationStatusEnum.DESPISED ? handleRecover(row) : handleDespise(row),
                },
                {
                  label: 'Desmembrar',
                  onClick: () => handleDismemberModal(row),
                },
              ]}
              iconButton
            />
          ),
        },
      },
    ]

    if (columnsTableSelected.includes('financialCategory')) {
      makeGrid.unshift(
        {
          ...ColumnsCommon,
          align: 'center',
          flex: 0.6,
          ...{
            minWidth: 250,
            field: 'Categoria financeira',
            headerName: 'Categoria financeira',
          },
          renderHeader: () => (
            <HeaderWithFilter
              title="Categoria financeira"
              clickEvent={() =>
                setCurrentFilterModal({modalType: 'only-text', fieldType: 'financialCategory', open: true})
              }
            />
          ),
          renderCell: ({ row }) => (
            <Stack>
              <BaseInputAutocomplete
                companyId={companyId}
                options={finalcialCategories}
                variant="credits"
                error=""
                label=""
                setState={(e) => handleUpdateAccountingEntry({ entryId: row.id, field: 'financialCategory', newValue: e, oldValue: row.financialCategory })}
                value={row.financialCategory}
                inputVariant='bottomBordered'
              />
            </Stack>
          ),
        },
      )
    }

    if (columnsTableSelected.includes('historic')) {
      makeGrid.unshift(
        {
          ...ColumnsCommon,
          flex: 0.6,
          align: 'center',
          minWidth: 250,
          ...{
            field: 'Histórico',
            headerName: 'Histórico',
            headerAlign: 'center',
          },
          renderCell: ({ row }) => (
            <Tooltip 
                title={row?.historic ? row.historic.split('|').join(', ') : ''}
                arrow
                sx={{
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: '#777C84',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '19.07px',
                    color: 'white',
                  },
                }}
                key={row?.id}
              >
                <Stack width="100%">
                  <BaseDropDownInput
                    options={HistoricVariables}
                    error=""
                    setState={(e) => handleUpdateAccountingEntry({
                      entryId: row.id, 
                      field: 'historic', 
                      newValue: e?.join('|'), 
                      oldValue: row.historic
                    })}
                    value={row?.historic?.split('|')}
                  />
                </Stack>
            </Tooltip>
          ),
        },
      )
    }

    if (columnsTableSelected.includes('date')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.5,
        ...{
          field: 'Data',
          minWidth: 130,
          renderHeader: () => (
            <HeaderWithFilter
              title="Data"
              clickEvent={() =>
                setCurrentFilterModal({modalType: 'between-dates-filter', fieldType: 'date', open: true})
              }
            />
          ),
        },
        renderCell: (params) => `${params.row.bankTransferDate || ''}`,
      })
    }

    if (columnsTableSelected.includes('description')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 1,
        align: 'left',
        ...{
          field: 'Descrição - Clientes e fornecedor',
          minWidth: 350,
          renderHeader: () => (
            <HeaderWithFilter
              title="Descrição - Clientes e fornecedor"
              clickEvent={() =>
                setCurrentFilterModal({modalType: 'text-with-filter', fieldType: 'description', open: true})
              }
            />
          ),
        },
        renderCell: (params) => `${params.row.description}`,
      })
    }

    if (columnsTableSelected.includes('value')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'left',
        ...{
          field: 'Valor',
          minWidth: 150,
          renderHeader: () => (
            <HeaderWithFilter
              title="Valor"
              clickEvent={() =>
                setCurrentFilterModal({modalType: 'between-values-filter', fieldType: 'value', open: true})
              }
            />
          ),
        },
        renderCell: (params) => `${params.row.value}`,
      })
    }

    if (columnsTableSelected.includes('debit')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'left',
        ...{
          field: 'Débito',
          headerName: 'Débito',
          minWidth: 150,
          renderHeader: () => (
            <HeaderWithFilter
              title="Débito"
              clickEvent={() =>
                setCurrentFilterModal({modalType: 'debit-options', fieldType: 'debit', open: true})
              }
            />
          ),
        },
        renderCell: ({ row }) => (
          <Stack>
            <BaseInputAutocomplete
              companyId={companyId}
              options={debits}
              variant="debits"
              error=""
              label=""
              setState={(e) => handleUpdateAccountingEntry({ entryId: row.id, field: 'debit', newValue: e, oldValue: row.debit })}
              value={row.debit}
              inputVariant='bottomBordered'
            />
          </Stack>
        ),
      })
    }

    if (columnsTableSelected.includes('credit')) {
      makeGrid.unshift({
        ...ColumnsCommon,
        flex: 0.6,
        align: 'left',
        ...{
          field: 'Crédito',
          headerName: 'Crédito',
          minWidth: 150,
          renderHeader: () => (
            <HeaderWithFilter
              title="Crédito"
              clickEvent={() =>
                setCurrentFilterModal({modalType: 'credit-options', fieldType: 'credit', open: true})
              }
            />
          ),
        },
        renderCell: ({ row }) => (
          <Stack>
            <BaseInputAutocomplete
              companyId={companyId}
              options={credits}
              variant="credits"
              error=""
              label=""
              setState={(e) => handleUpdateAccountingEntry({ entryId: row.id, field: 'credit', newValue: e, oldValue: row.credit })}
              value={row.credit}
              inputVariant='bottomBordered'
            />
          </Stack>
        ),
      })
    }

    return order
      .map((key) => makeGrid.find((column) => column.field === key))
      .filter((column): column is GridColDef => column !== undefined)
  }, [vendorsOptions, columnsTableSelected])

  return {
    columns,
    isLoading,
    selectedGridIds,
    setSelectedGridIds,
    setIsLoading,
    handleListAccountingEntries,
    accountingEntries,
    handleListCompanyVendors,
    handleSelectColumns,
    columnsTableSelected,
    totalPages,
    totalRegisters,
    currentPage,
    informationsCashflow,
    informationsBalance,
    informationsBankBalance,
    vendorsOptions,
    currentFilterModal,
    handleGeLedgerAccounts,
    credits,
    debits,
    ledgerAccounts,
    manualClassifyFinish,
    setFilterText,
    filterText,
    handleDespise,
    handleRecover,
    handleListAccountingEntriesCashflow,
    updateCashflow,
  };
}
