import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

export type PlanCardProps = {
  data: {
    Icon: () => JSX.Element
    title: string
    value: string
    since: string
    aditionalData?: {
      title?: string
      value?: string
    }
  }[]
}

export const CompanyTotalizerCard = ({ data = [] }: PlanCardProps) => {
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(`(max-width: 1024px)`)

  return (
    <Stack
      width="100%"
      gap="24px"
      height="100%"
      alignItems="center"
      direction="row"
      justifyContent="center"
    >
      {data &&
        data.map(({ Icon, title, value, since, aditionalData }, index) => {
          return (
            <Stack
              key={`${value}-${index}`}
              width="100%"
              height="120px"
              borderRadius="24px"
              border={`1px solid ${theme.palette.success.main}`}
              sx={{ backgroundColor: theme.palette.common.white }}
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Stack
                width="100%"
                direction={isMediumScreen ? 'column' : 'row'}
                justifyContent="center"
                alignItems="center"
                gap="8px"
              >
                {<Icon />}
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    spacing={isMediumScreen ? 1 : 2}
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        color: '#1E3466',
                        fontSize: isMediumScreen ? '18px' : '32px',
                        fontWeight: 700,
                      }}
                    >
                      {value}
                    </Typography>
                    <Box
                      sx={{
                        color: '#777C84',
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        width: '110px',
                      }}
                    >
                      {title}
                    </Box>
                  </Stack>
                  {aditionalData && (
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography
                        sx={{
                          color: '#1E3466',
                          fontSize: isMediumScreen ? '18px' : '32px',
                          fontWeight: 700,
                        }}
                      >
                        {aditionalData.value}
                      </Typography>
                      <Box
                        sx={{
                          color: '#777C84',
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: 'normal',
                          width: '110px',
                        }}
                      >
                        {aditionalData.title}
                      </Box>
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack width="90%" justifyContent="center" alignItems="end">
                <Box
                  sx={{
                    color: '#777C84',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: 'normal',
                  }}
                >
                  {since}
                </Box>
              </Stack>
            </Stack>
          )
        })}
    </Stack>
  )
}
