import { Search } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'
import { format } from 'date-fns'
import { useRef, useState } from 'react'
import {
  BaseDateSelect,
  HandleBaseSelectInput,
} from 'src/component/base-component/base-date-select'
import { BaseDropDownInput } from 'src/component/base-component/base-dropdown'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInputAutocomplete } from 'src/component/base-component/base-input-autocomplete'
import { BaseInputMoney } from 'src/component/base-component/base-input-money'
import { BaseInputSingleSelect } from 'src/component/base-component/base-input-single-select'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { TCompanyAccountEntryCreate } from 'src/service/service-company'

const HistoricVariables = [
  {
    label: 'Data',
    value: 'data',
  },
  {
    label: 'Descrição',
    value: 'descricao',
  },
  {
    label: 'Valor',
    value: 'valor',
  },
  {
    label: 'Débito',
    value: 'debito',
  },
  {
    label: 'Crédito',
    value: 'credito',
  },
]

export const ModalClassifierPaymentEntry: React.FC<{
  handle: (data: TCompanyAccountEntryCreate[]) => Promise<void>
  companyId: string
  type: 'transfer' | 'made' | 'provision' | 'conciliator'
  accountsOptions: {
    value: string | number
    label: string
    icon?: React.ReactNode
  }[]
}> = ({ handle, accountsOptions, type, companyId }) => {
  const { HideModal } = ModalFullContext()
  const [bankAccount, setBankAccount] = useState('')
  const [bankAccountTo, setBankAccountTo] = useState('')

  const [financialCategory, setFiancialCategory] = useState('')
  const [debit, setDebit] = useState('')
  const [credit, setCredit] = useState('')

  const [financialCategoryBankAccountTo, setFiancialCategoryBankAccountTo] =
    useState('')
  const [debitBankAccountTo, setDebitBankAccountTo] = useState('')
  const [creditBankAccountTo, setCreditBankAccountTo] = useState('')

  const [date, setDate] = useState<Date | undefined>(undefined)

  const DateInitialRef = useRef<HandleBaseSelectInput>(null)
  const [value, setValue] = useState('')
  const [description, setDescription] = useState('')
  const [historic, setHistoric] = useState('')
  const [client, setClient] = useState('')

  const handleSelectDate = (e: Date | undefined) => {
    setDate(e)
  }

  const handleCreate = () => {
    if (!date || !value) return
    const amount = Math.abs(Number(value?.replace(/[^\d-]/g, '')) / 100)
    const localDate = new Date(date)
    const data = [
      {
        date: localDate.toUTCString(),
        value: amount.toString(),
        method: amount <= 0 || type === 'transfer' ? 'DEBITO' : 'CREDITO',
        credit,
        debit,
        description,
        financialCategory,
        historic,
        vendor: undefined,
        bankAccountFromId: bankAccount,
      },
    ]

    if (type === 'transfer') {
      data.push({
        date: localDate.toUTCString(),
        value: amount.toString(),
        method: 'CREDITO',
        credit,
        debit,
        description,
        financialCategory,
        historic,
        vendor: undefined,
        bankAccountFromId: bankAccountTo,
      })
    }
    handle && handle(data)
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content',
      }}
    >
      <Box
        sx={{
          width: '600px',

          borderRadius: '16px',
        }}
      >
        {/* Conta Origem */}
        {(type === 'transfer' || type === 'made') && (
          <BaseInputSingleSelect
            options={accountsOptions}
            onSelect={setBankAccount}
            placeholder={type === 'made' ? 'Conta bancária' : 'Conta Origem'}
            value={bankAccount}
          />
        )}

        {type !== 'conciliator' && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="18px"
          >
            <BaseInputAutocomplete
              companyId={companyId}
              options={[]}
              variant="debits"
              error=""
              label="Cat. financeira"
              fullWidth
              setState={(e) => setFiancialCategory(e)}
              value={financialCategory}
              containerStyle={{ width: '100%' }}
              iconEnd={<Search />}
              inputVariant="bordered"
            />
            <BaseInputAutocomplete
              companyId={companyId}
              options={[]}
              variant="debits"
              error=""
              label="Conta débito"
              fullWidth
              setState={(e) => setDebit(e)}
              value={debit}
              containerStyle={{ width: '100%' }}
              iconEnd={<Search />}
              inputVariant="bordered"
            />
            <BaseInputAutocomplete
              companyId={companyId}
              options={[]}
              variant="debits"
              error=""
              label="Conta crédito"
              fullWidth
              setState={(e) => setCredit(e)}
              value={credit}
              containerStyle={{ width: '100%' }}
              iconEnd={<Search />}
              inputVariant="bordered"
            />
          </Stack>
        )}

        {type === 'transfer' && (
          <Box
            width="100%"
            borderBottom="dashed 1px #777C84"
            marginTop="20px"
          />
        )}

        {/* Conta Destino */}
        {type === 'transfer' && (
          <>
            <BaseInputSingleSelect
              options={accountsOptions}
              onSelect={setBankAccountTo}
              placeholder="Conta Destino"
              value={bankAccountTo}
            />

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap="18px"
            >
              <BaseInputAutocomplete
                companyId={companyId}
                options={[]}
                variant="debits"
                error=""
                label="Cat. financeira"
                fullWidth
                setState={(e) => setFiancialCategoryBankAccountTo(e)}
                value={financialCategoryBankAccountTo}
                containerStyle={{ width: '100%' }}
                iconEnd={<Search />}
                inputVariant="bordered"
              />

              <BaseInputAutocomplete
                companyId={companyId}
                options={[]}
                variant="debits"
                error=""
                label="Conta débito"
                fullWidth
                setState={(e) => setDebitBankAccountTo(e)}
                value={debitBankAccountTo}
                containerStyle={{ width: '100%' }}
                iconEnd={<Search />}
                inputVariant="bordered"
              />

              <BaseInputAutocomplete
                companyId={companyId}
                options={[]}
                variant="debits"
                error=""
                label="Conta crédito"
                fullWidth
                setState={(e) => setCreditBankAccountTo(e)}
                value={creditBankAccountTo}
                containerStyle={{ width: '100%' }}
                iconEnd={<Search />}
                inputVariant="bordered"
              />
            </Stack>
          </>
        )}

        {type === 'transfer' && (
          <Box
            width="100%"
            borderBottom="dashed 1px #777C84"
            marginTop="20px"
            marginBottom="20px"
          />
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="18px"
          marginTop="18px"
        >
          <BaseDateSelect
            title="Data"
            onSelect={(e) => handleSelectDate(e)}
            ref={DateInitialRef}
            containerStyle={{ width: '50%' }}
            buttonStyle={{ width: '100%' }}
            useCalendarPosition={false}
          />

          <BaseInputMoney
            label="Valor"
            type="text"
            fullWidth={true}
            value={value}
            setState={(e) => setValue(e)}
            error=""
            containerStyle={{ width: '50%' }}
          />
        </Stack>

        <Stack direction="column" gap="18px" marginY="18px">
          <BaseInputAutocomplete
            companyId={companyId}
            options={[]}
            variant="vendors"
            error=""
            label="Clientes e fornecedores"
            fullWidth
            setState={(e) => setClient(e)}
            value={client}
            containerStyle={{ width: '100%' }}
            iconEnd={<Search />}
            inputVariant="bordered"
          />

          {(type === 'transfer' || type === 'made') && (
            <BaseInput
              label="Descrição"
              fullWidth
              setState={(e) => setDescription(e)}
              error=""
              value={description}
              containerStyle={{ width: '100%' }}
            />
          )}

          <BaseDropDownInput
            options={HistoricVariables}
            buttonLabel="Histórico"
            showInternalLabel
            setState={(e) => setHistoric(e?.join('|'))}
            error=""
            variant="outlined"
            value={historic ? historic?.split('|') : undefined}
          />
        </Stack>
      </Box>

      {/* Footer */}

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content max-content',
          gap: '14px',
          justifySelf: 'end',
          padding: '0 4px',
        }}
      >
        <Button
          variant="outlined"
          onClick={HideModal}
          sx={{ width: '104px', height: '40px' }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleCreate()
          }}
          sx={{ width: '175px', height: '40px' }}
        >
          Criar lançamento
        </Button>
      </Box>
    </Box>
  )
}
