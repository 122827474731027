import { Box, Button, Stack, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { format } from 'date-fns'
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { CalendarIcon } from '../icons/calendar'

export interface HandleBaseSelectInput {
  reset: () => void
}

interface Props {
  title: string
  onSelect: (data?: Date | undefined) => void
  externalReset?: () => void
  buttonStyle?: object
  containerStyle?: object
  containerCalendarStyle?: object
  useCalendarPosition?: boolean
  selectedDate?: Date
  disabled?: boolean
}

const BaseDateSelectComponent: ForwardRefRenderFunction<
  HandleBaseSelectInput,
  Props
> = (
  {
    title,
    onSelect,
    externalReset,
    buttonStyle = {},
    containerStyle = {},
    containerCalendarStyle = {},
    useCalendarPosition = true,
    selectedDate,
    disabled = false,
  },
  ref,
) => {
  const [openCalendar, setOpenCalendar] = useState(false)
  const [internalControlDateSelected, setInternalControlDateSelected] =
    useState<Date | undefined>(selectedDate)
  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 })

  const ButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (!useCalendarPosition) return
    if (openCalendar && ButtonRef.current) {
      const rect = ButtonRef?.current?.getBoundingClientRect()
      setButtonPosition({ top: rect.bottom, left: rect.left })
    }
  }, [openCalendar])

  useEffect(() => {
    if (selectedDate) {
      setInternalControlDateSelected(selectedDate)
    }
  }, [selectedDate])

  const dateFormatted = useMemo(() => {
    return internalControlDateSelected
      ? format(internalControlDateSelected, 'dd/MM/yyyy')
      : ''
  }, [internalControlDateSelected])

  const handleSelectDate = (e: any) => {
    const newDate = new Date(e)
    setInternalControlDateSelected(newDate)
    setOpenCalendar(false)
    onSelect(newDate)
  }

  const reset = () => {
    setInternalControlDateSelected(undefined)
    onSelect(undefined)
    externalReset && externalReset()
  }

  useImperativeHandle(ref, () => {
    return {
      reset,
    }
  })

  return (
    <Stack
      flexDirection="column"
      alignItems="flex-end"
      width="216px"
      position="relative"
      sx={containerStyle}
    >
      <Button
        variant="outlined"
        endIcon={<CalendarIcon />}
        sx={{ width: '216px', height: '56px', ...buttonStyle }}
        onClick={() => !disabled && setOpenCalendar((prev) => !prev)}
        style={{ justifyContent: 'space-between' }}
        ref={ButtonRef}
      >
        <Typography fontWeight="500" fontSize="14px" lineHeight="16.41px">
          {dateFormatted || title}
        </Typography>
      </Button>

      {openCalendar && (
        <Box
          sx={{
            backgroundColor: 'white',
            boxShadow: 4,
            padding: 0.5,
            borderRadius: '4px',
            marginTop: '12px',
            position: 'fixed',
            top: useCalendarPosition ? buttonPosition.top : 'auto',
            left: useCalendarPosition ? buttonPosition.left : 'auto',
            zIndex: 99999999,
            ...containerCalendarStyle,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar disabled={disabled} onChange={handleSelectDate} />
          </LocalizationProvider>
        </Box>
      )}
    </Stack>
  )
}

export const BaseDateSelect = forwardRef(BaseDateSelectComponent)
