import { Checkbox, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { FC } from 'react'
import { Logo } from 'src/component/base-component/base-logo-component'
import { EditTableIcon } from 'src/component/icons/edit'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'

export interface IBankAccountCard {
  id: string
  name: string
  amount: string
  agency: string
  account: string
  bankLogo?: string
  bankName: string
}

interface Props {
  data: IBankAccountCard
  index: number // remover depois, serve apenas para mocar as logos dos bancos por enquanto
  containerStyle?: object
  brandStyle?: object
  amountStyle?: object
  agencyStyle?: object
  accountStyle?: object
  selectable?: boolean
  editable?: boolean
  value: boolean
  onSelect?: (data?: any) => void
  editableClick?: (data?: any) => void
  checkboxSide?: 'left' | 'right'
}

const BankAccountCard: FC<Props> = ({
  data,
  containerStyle = {},
  accountStyle = {},
  agencyStyle = {},
  amountStyle = {},
  selectable = false,
  editable = false,
  value = false,
  onSelect,
  editableClick,
  checkboxSide = 'right',
}) => {
  return (
    <Tooltip title={data.name} key={data.id}>
      <Stack
        direction="row"
        alignItems="center"
        width="188px"
        height="60px"
        bgcolor="#fff"
        gap="8px"
        borderRadius="12px"
        justifyContent="center"
        boxShadow="0px 4px 4px 0px #00000040"
        padding="12px"
        style={containerStyle}
      >
        <Stack
          direction="row"
          width="100%"
          gap="24px"
          justifyContent="flex-start"
        >
          <Logo
            name={data.bankName}
            width={46}
            height={40}
            logo={data.bankLogo}
          />

          <Stack ml="5px">
            <Typography
              display="flex"
              color="#1E1E1E"
              fontWeight={700}
              fontSize="14px"
              lineHeight="19.07px"
              style={amountStyle}
              flexDirection="row"
            >
              {formatMoney(Number(data.amount))}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                color="#777C84"
                fontWeight={400}
                fontSize="12px"
                lineHeight="16.34px"
                style={agencyStyle}
              >{`Ag: ${data.agency}`}</Typography>
              <Typography
                color="#777C84"
                fontWeight={400}
                fontSize="12px"
                lineHeight="16.34px"
                style={accountStyle}
                marginLeft="12px"
              >{`C: ${data.account}`}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="flex-start"
        >
          {selectable && checkboxSide === 'left' && (
            <Checkbox
              sx={{
                color: '#4865CC',
                '&.Mui-checked': {
                  color: '#4865CC',
                },
                padding: 0,
              }}
              checked={value}
              value={value}
              onChange={onSelect}
            />
          )}
          {editable && (
            <IconButton sx={{ marginTop: '-17px' }} onClick={editableClick}>
              <EditTableIcon />
            </IconButton>
          )}
          {selectable && checkboxSide === 'right' && (
            <Checkbox
              sx={{
                color: '#4865CC',
                '&.Mui-checked': {
                  color: '#4865CC',
                },

                padding: 0,
              }}
              checked={value}
              value={value}
              onChange={onSelect}
            />
          )}
        </Stack>
      </Stack>
    </Tooltip>
  )
}

export { BankAccountCard }
