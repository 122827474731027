import { Stack } from '@mui/material'
import { ApexOptions } from 'apexcharts'
import { useEffect, useRef } from 'react'
import Chart from 'react-apexcharts'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'

export interface IVariationData {
  x: string
  y: number[] | string[]
}
interface Props {
  series: {
    name: string
    data: IVariationData[]
  }[]
  categories: string[]
  min: number
  max: number
}

const VariationsChart = ({ series, max, min, categories }: Props) => {
  const chartRef = useRef<any>(null)
  const chartLineRef = useRef<any>(null)

  // Calcular a variação de caixa (diferença entre o máximo e o mínimo)
  const variationSeries = categories.map((category, index) => {
    const inflow = series[0]?.data[index]?.y[0] || 0
    const outflow = series[1]?.data[index]?.y[0] || 0
    const variation = Number(inflow) + Number(outflow)
    return { x: category, y: variation.toFixed(2) }
  })

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    series: [
      {
        name: 'Entrada',
        data: series[0]?.data,
        color: '#03B575',
      },
      {
        name: 'Saída',
        data: series[1]?.data,
        color: '#DB2D25',
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '90%',
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (value) => formatMoney(Number(value)),
      style: {
        fontSize: '12px',
        colors: ['#0F7D55FF', '#8E130CFF'],
      },
    },
    xaxis: {
      categories,
      type: 'category',
    },
    yaxis: {
      max,
      min,
      labels: {
        formatter: (value) => formatMoney(Number(value)), // Formatação para valores monetários no eixo Y
      },
    },
    colors: ['#03B575', '#DB2D25'],
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
  }

  const optionsLine: ApexOptions = {
    chart: {
      type: 'line',
      height: 350,
      zoom: {
        enabled: false,
      },
      parentHeightOffset: 0, // Ajusta a altura do gráfico para permitir hover das barras
      toolbar: {
        show: false,
      },
    },
    series: [
      {
        name: 'Variação de Caixa',
        data: variationSeries,
      },
    ],
    tooltip: {
      intersect: false, // Permite que o tooltip de linha apareça sem bloquear o gráfico de barras
      y: {
        formatter: (value) => formatMoney(Number(value)), // Formata o valor como monetário em R$
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth', // Define a curva suave para a linha
      colors: ['#E7B92D'], // Linha amarela para a variação
      width: 3,
    },
    xaxis: {
      categories,
      labels: {
        show: false, // Oculta as legendas do eixo x
      },
      axisBorder: {
        show: false, // Oculta a borda do eixo x
      },
      axisTicks: {
        show: false, // Oculta os ticks do eixo x
      },
    },
    yaxis: {
      min,
      max,
      labels: {
        show: false, // Oculta as legendas do eixo y
      },
      axisBorder: {
        show: false, // Oculta a borda do eixo y
      },
      axisTicks: {
        show: false, // Oculta os ticks do eixo y
      },
    },
    grid: {
      show: false,
    },
    legend: {
      show: false, // Remove a legenda do gráfico de linha
    },
  }

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = new ApexCharts(chartRef.current, options)
      chartInstance.render()
    }
    if (chartLineRef.current) {
      const chartInstance = new ApexCharts(chartLineRef.current, optionsLine)
      chartInstance.render()
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy()
      }
      if (chartLineRef.current) {
        chartLineRef.current.destroy()
      }
    }
  }, [])

  return (
    <Stack id="chart" width="100%" position="relative">
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          width: '100%',
        }}
      >
        <Chart
          options={options}
          series={options.series}
          type="bar"
          height={350}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 50, // 31
          zIndex: 10,
          width: `calc(100% - ${50}px)`, // 31
        }}
      >
        <Chart
          options={optionsLine}
          series={optionsLine.series}
          type="line"
          height={280}
        />
      </div>
    </Stack>
  )
}

export { VariationsChart }
