import {
  ArrowDropDown,
  ArrowRightSharp,
  ArrowLeftSharp,
} from '@mui/icons-material'
import {
  Button,
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { MenuDotIcon } from 'src/component/icons/menu-dot'

interface Props {
  title?: string
  iconButton?: boolean
  icon?: React.ReactNode
  menuStyle?: object
  subMenuStyle?: object
  options: {
    label: string
    onClick: (data?: any) => any
    subItens?: {
      label: string
      onClick: (data?: any) => any
      type: 'single' | 'multi' // tipo de action do subitem. Multi = checkbox com várias opções
      default?: 'checked' | string // podemos usar para casos futuros quando for checkbox ou outras variações
      value?: string
    }[]
    subItensSelected?: string[]
  }[]
  variant?: 'primary' | 'secondary'
  direction?: 'right' | 'left'
}

export function CascadeButton({
  icon,
  title,
  options,
  iconButton = false,
  menuStyle = {},
  subMenuStyle = {},
  variant = 'secondary',
  direction = 'right',
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [subMenuAnchorEl, setSubMenuAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const [openSubMenuIndex, setOpenSubMenuIndex] = React.useState<number | null>(
    null,
  )

  const open = Boolean(anchorEl)
  const subMenuOpen = Boolean(subMenuAnchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setSubMenuAnchorEl(null)
    setOpenSubMenuIndex(null)
  }

  const handleSubMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setSubMenuAnchorEl(event.currentTarget)
    setOpenSubMenuIndex(index)
  }

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null)
    setOpenSubMenuIndex(null)
  }

  return (
    <Stack>
      {!iconButton && (
        <Button
          variant="outlined"
          endIcon={<ArrowDropDown />}
          sx={{ minWidth: '190px', height: '40px' }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Typography fontWeight="500" fontSize="14px" lineHeight="16.41px">
            {title || ''}
          </Typography>
        </Button>
      )}

      {iconButton && (
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            backgroundColor: variant === 'primary' ? '#4865CC' : '#ffffff',
            color: variant === 'primary' ? '#ffffff' : '#4865CC',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            padding: 0,
          }}
        >
          {icon || (
            <MenuDotIcon
              customColor={variant === 'primary' ? '#ffffff' : '#4865CC'}
            />
          )}
        </IconButton>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ marginTop: '15px', marginLeft: '-10px' }}
        style={menuStyle}
      >
        {options.map((option, index) => (
          <div
            key={option.label}
            onMouseEnter={(event) => handleSubMenuOpen(event, index)}
            onMouseLeave={handleSubMenuClose}
          >
            <MenuItem
              onClick={
                option.subItens
                  ? undefined
                  : () => {
                      option.onClick()
                      handleClose()
                    }
              }
              id={option.label}
              sx={{ height: '56px', width: '320px' }}
            >
              {!!option.subItens?.length && direction === 'left' && (
                <ArrowLeftSharp />
              )}
              <ListItemText primary={option.label} />
              {!!option.subItens?.length && direction === 'right' && (
                <ArrowRightSharp />
              )}
            </MenuItem>
            {option.subItens && (
              <Menu
                anchorEl={subMenuAnchorEl}
                open={openSubMenuIndex === index && subMenuOpen}
                onClose={handleSubMenuClose}
                MenuListProps={{
                  'aria-labelledby': option.label,
                }}
                sx={{
                  marginTop: `calc(-63px + ${index * 15}px)`,
                  marginLeft: direction === 'right' ? '280px' : '-290px',
                }}
                style={subMenuStyle}
              >
                {option.subItens.map((subItem) => (
                  <MenuItem
                    key={subItem.label}
                    onClick={() => {
                      subItem.onClick()

                      if (subItem.type === 'single') {
                        handleClose()
                      }
                    }}
                    sx={{ height: '56px', width: '320px' }}
                  >
                    {subItem.type === 'multi' && (
                      <Checkbox
                        sx={{
                          color: '#4865CC',
                          '&.Mui-checked': {
                            color: '#4865CC',
                          },
                        }}
                        checked={option?.subItensSelected?.includes(
                          String(subItem?.value),
                        )}
                        // checked={value.indexOf(option.value.toString()) > -1}
                        // checked={subItem.default === "checked"}
                      />
                    )}
                    <ListItemText primary={subItem.label} />
                  </MenuItem>
                ))}
              </Menu>
            )}
          </div>
        ))}
      </Menu>
    </Stack>
  )
}
