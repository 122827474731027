import {
  BankApiRemoteService,
  IBankAccountTransferResponse,
} from 'src/service/service-bank'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListTransfersUseCase
  implements
    IUsecase<
      { accountsIds?: string[]; date?: string[]; page: number },
      IBankAccountTransferResponse
    >
{
  constructor(private userApi: BankApiRemoteService) {}

  public async handle({
    accountsIds,
    date,
    page,
  }: {
    accountsIds?: string[]
    date?: string[]
    page: number
  }): Promise<Result<IBankAccountTransferResponse>> {
    try {
      // eslint-disable-next-line prettier/prettier
      const response = await this.userApi.listBankAccountsTransfer({
        accountsIds,
        date,
        page,
      })

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar dados das transferências.'),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar os dados'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
