import React, { FC, useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Checkbox, ListItemText } from '@mui/material'

interface BaseDropDownInputProps {
  options: { value: string | number; label: string; icon?: React.ReactNode }[]
  value?: string[] // Torna o value opcional
  error?: string | null
  showInternalLabel?: boolean
  buttonLabel?: string
  setState: (value: string[]) => void
  variant?: 'text' | 'contained' | 'outlined'
}

export const BaseDropDownInput: FC<BaseDropDownInputProps> = ({
  options,
  value = [], // Valor padrão para evitar erros
  showInternalLabel,
  buttonLabel = '',
  setState,
  variant = 'text',
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (e: string) => {
    // Garantir que value seja sempre um array antes de usar métodos como find e filter
    const selectedValues = Array.isArray(value) ? value : []
    const exists = selectedValues.find((item) => item === e)

    if (exists) {
      const filtered = selectedValues.filter((item) => item !== e)
      setState(filtered)
    } else {
      const newValues = [...selectedValues, e] // Simples adição de valores
      setState(newValues)
    }
  }

  const label = showInternalLabel ? buttonLabel : ''

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant={variant}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          width: '100%',
          borderRadius: variant === 'outlined' ? '100px' : 0,
        }}
      >
        {!Array.isArray(value) || !value.length
          ? label
          : value.length > 1
            ? `${value[0]} +${value.length - 1}`
            : value[0]}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={() => handleSelect(String(option.value))}
          >
            <Checkbox
              sx={{
                color: '#4865CC',
                '&.Mui-checked': {
                  color: '#4865CC',
                },
              }}
              checked={
                Array.isArray(value) && value.includes(String(option.value))
              }
              onClick={() => handleSelect(String(option.value))}
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
