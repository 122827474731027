import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BaseInternalHeader } from 'src/component/base-component/base-internal-header'
import { Logo } from 'src/component/base-component/base-logo-component'
import { BaseMultipleSelectInput } from 'src/component/base-component/base-multiple-select'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { CascadeButton } from 'src/component/buttons/cascade-button'
import { DefaultHeader } from 'src/component/header/header'
import { NotificationBellIcon } from 'src/component/icons/notification-bell'
import { ModalClassifierExport } from 'src/component/modal/classifier/modal-classifier-export'
import { ModalClassifierPaymentEntry } from 'src/component/modal/classifier/modal-classifier-payment-entry'
import { ModalClassifierPendency } from 'src/component/modal/classifier/modal-classifier-pendency'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import {
  ITableModalType,
  TableFilterModal,
} from 'src/component/table/filterModal/table-filter-modal'
import { Table } from 'src/component/table/table'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'
import { ListLedgerAccountsUseCase } from 'src/usecase/bank-account/usecase-account-get-ledger-accounts'
import { ListUserCompaniesAccountsUseCase } from 'src/usecase/bank-account/usecase-user-companies-list'
import { ClassifyAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-classify'
import { ListAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-list'
import { UpdateAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-update'
import { ListCompanyDebitUseCase } from 'src/usecase/classifier/usecase-campany-debit-list'
import { ListCompanyVendorsUseCase } from 'src/usecase/classifier/usecase-company-vendors-list'
import { extractBankName } from 'src/utils/normalizedBankLogos'
import BlueHeader from '../../assets/background/header-blue.png'
import { useUserAccount } from '../systemic-accounts/useUserAccount'
import { IFilterModalField, useClassifier } from './useClassifier'
import { ListAccountingEntriesBankBalanceUseCase } from 'src/usecase/classifier/usecase-accounting-entries-bank-balance-list'
import { ListAccountingEntriesBalanceUseCase } from 'src/usecase/classifier/usecase-accounting-entries-balance-list'
import { ListAccountingEntriesCashflowUseCase } from 'src/usecase/classifier/usecase-accounting-entries-cashflow-list'
import { CreateManualAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-create'
import {
  CompanyAccountEntryConciliationStatusEnum,
  TCompanyAccountEntryCreate,
} from 'src/service/service-company'
import { generateXLS } from 'src/utils/generateXLS'
import { AccountingEntryDismemberUseCase } from 'src/usecase/classifier/usecase-accounting-entries-dismember'
import { BaseInput } from 'src/component/base-component/base-input'
import SearchIcon from '@mui/icons-material/Search'

interface TemporaryProps {
  listUserCompaniesAccountsUseCase: ListUserCompaniesAccountsUseCase
  listAccountingEntriesUseCase: ListAccountingEntriesUseCase
  updateAccountingEntriesUseCase: UpdateAccountingEntriesUseCase
  listCompanyVendorsUserCase: ListCompanyVendorsUseCase
  listCompanyDebitsUserCase: ListCompanyDebitUseCase
  classifyAccountingEntriesUseCase: ClassifyAccountingEntriesUseCase
  listLedgerAccountsUseCase: ListLedgerAccountsUseCase
  listAccountingEntriesBankBalanceUseCase: ListAccountingEntriesBankBalanceUseCase
  listAccountingEntriesBalanceUseCase: ListAccountingEntriesBalanceUseCase
  listAccountingEntriesCashflowUseCase: ListAccountingEntriesCashflowUseCase
  createManualAccountingEntriesUseCase: CreateManualAccountingEntriesUseCase
  accountingEntryDismemberUseCase: AccountingEntryDismemberUseCase
}

export function ClassifierPage(props: TemporaryProps) {
  const { ShowToast } = ToastFullContext()
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')
  const useTwoLinesHeader = useMediaQuery('(max-width: 1280px)')
  const { companyId } = useParams()
  const [showResults, setShowResults] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const [accountsSelected, setAccountsSelected] = useState<string[]>([])
  const [initialDate, setInitialDate] = useState<Date | undefined>(
    startOfMonth(subMonths(new Date(), 3)),
  )
  const [finalDate, setFinalDate] = useState<Date | undefined>(
    endOfMonth(new Date()),
  )
  const [minValue, setMinValue] = useState('')
  const [maxValue, setMaxValue] = useState('')
  const [searchQuery, setSearchQuery] = useState<string>('')
  const ContainerRef = useRef<HTMLDivElement>(null)
  const { ShowModal, HideModal } = ModalFullContext()
  const [
    accountingEntriesAlreadyClassified,
    setAccountingEntriesAlreadyClassified,
  ] = useState<'true' | 'false' | undefined>(undefined)
  const [showDespised, setshowDespised] = useState<
    'true' | 'false' | undefined
  >(undefined)
  const [selectedVendors, setSelectedVendors] = useState<string[] | undefined>(
    undefined,
  )
  const [selectedDebits, setSelectedDebits] = useState<string[] | undefined>(
    undefined,
  )
  const [selectedCredits, setSelectedCredits] = useState<string[] | undefined>(
    undefined,
  )
  const { handleListUserCompaniesAccounts, userCompaniesAccounts } =
    useUserAccount(props.listUserCompaniesAccountsUseCase)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const bankAccountOptions = userCompaniesAccounts.map((item) => {
    return {
      label: item.name,
      value: item.id,
      icon: (
        <Logo
          name={extractBankName(item.bank)}
          width={32}
          height={32}
          logo={item.bankLogo}
        />
      ),
    }
  })

  const handleSelectDate = (e: Date | undefined, type: 'initial' | 'final') => {
    if (type === 'initial') {
      setInitialDate(e)
      return
    }
    setFinalDate(e)
  }

  const {
    columns,
    isLoading,
    selectedGridIds,
    setIsLoading,
    handleListAccountingEntries,
    setSelectedGridIds,
    accountingEntries,
    handleListCompanyVendors,
    handleSelectColumns,
    columnsTableSelected,
    totalPages,
    totalRegisters,
    currentPage,
    informationsCashflow,
    informationsBankBalance,
    vendorsOptions,
    currentFilterModal,
    credits,
    debits,
    manualClassifyFinish,
    handleGeLedgerAccounts,
    filterText,
    setFilterText,
    handleDespise,
    handleRecover,
    updateCashflow,
    handleListAccountingEntriesCashflow,
  } = useClassifier(
    props.listAccountingEntriesUseCase,
    props.updateAccountingEntriesUseCase,
    props.listCompanyVendorsUserCase,
    props.listLedgerAccountsUseCase,
    props.listAccountingEntriesBankBalanceUseCase,
    props.listAccountingEntriesBalanceUseCase,
    props.listAccountingEntriesCashflowUseCase,
    props.classifyAccountingEntriesUseCase,
    props.accountingEntryDismemberUseCase,
    companyId as string,
  )

  useEffect(() => {
    if (currentFilterModal?.open) {
      handleShowFilterModal(
        currentFilterModal.modalType,
        currentFilterModal.fieldType,
      )
    }
  }, [currentFilterModal])

  const handleCreateNewEntries = async (data: TCompanyAccountEntryCreate[]) => {
    try {
      setIsLoading(true)
      const usecaseResult =
        await props.createManualAccountingEntriesUseCase.handle({
          data,
          companyId: companyId as string,
        })

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
        )
      }
      if (accountsSelected) {
        await handleListAccountingEntries(
          companyId as string,
          1,
          initialDate,
          finalDate,
          minValue,
          maxValue,
          accountsSelected,
          selectedVendors,
          selectedDebits,
          selectedCredits,
          filterText,
          accountingEntriesAlreadyClassified === 'true' ? 'true' : undefined,
          showDespised === 'true' ? 'true' : undefined,
        )
      }
      HideModal()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
      )
    }
  }

  const handleApply = (
    currentInitialDate: Date | undefined,
    currentFinalDate: Date | undefined,
    currentMinValue: string | undefined,
    currentmMaxValue: string | undefined,
    currentVendors: string[] | undefined,
    currenctDebits: string[] | undefined,
    currentCredits: string[] | undefined,
  ) => {
    handleListAccountingEntries(
      companyId as string,
      1,
      currentInitialDate,
      currentFinalDate,
      currentMinValue,
      currentmMaxValue,
      accountsSelected,
      currentVendors,
      currenctDebits,
      currentCredits,
      filterText,
      accountingEntriesAlreadyClassified === 'true' ? 'true' : undefined,
      showDespised === 'true' ? 'true' : undefined,
    )
    HideModal()
  }

  const handleShowFilterModal = useCallback(
    (modalType: ITableModalType, fieldType: IFilterModalField) => {
      const modalData = {
        modalType,
        options:
          fieldType === 'debit'
            ? debits?.map((item) => {
                return {
                  label: item,
                  value: item,
                }
              })
            : fieldType === 'credit'
              ? credits?.map((item) => {
                  return {
                    label: item,
                    value: item,
                  }
                })
              : vendorsOptions,
        selectedOptions: selectedVendors,
        selectedOptionsDebits: selectedDebits,
        selectedOptionsCredits: selectedCredits,
        setSelectedOptions: setSelectedVendors,
        setSelectedOptionsDebits: setSelectedDebits,
        setSelectedOptionsCredits: setSelectedCredits,
        searchQuery,
        setSearchQuery,
        initialDate,
        finalDate,
        handleSelectDate,
        minValue,
        maxValue,
        setMinValue,
        setMaxValue,
      }
      return ShowModal({
        content: (
          <TableFilterModal
            handleCancel={() => HideModal()}
            handleApply={handleApply}
            modalData={modalData}
          />
        ),
        title: 'Filtrar lançamentos',
        closeButton: true,
      })
    },
    [
      companyId,
      initialDate,
      finalDate,
      minValue,
      maxValue,
      accountsSelected,
      selectedVendors,
    ],
  )

  useEffect(() => {
    if (companyId) {
      handleListCompanyVendors(companyId)
      handleListUserCompaniesAccounts([companyId])
      handleGeLedgerAccounts()
    }
  }, [companyId, manualClassifyFinish])

  useEffect(() => {
    handleListAccountingEntriesCashflow(
      companyId as string,
      1,
      initialDate,
      finalDate,
      minValue,
      maxValue,
      accountsSelected,
      selectedVendors,
      filterText,
      accountingEntriesAlreadyClassified === 'true' ? 'true' : undefined,
      showDespised === 'true' ? 'true' : undefined,
    )
  }, [updateCashflow])

  const handleClassifyAll = async () => {
    try {
      setIsLoading(true)
      const usecaseResult = await props.classifyAccountingEntriesUseCase.handle(
        {
          data: {
            companyId: companyId as string,
            bankAccountId: undefined,
            entryId: selectedGridIds ?? undefined,
            reclassify: false,
          },
        },
      )

      await handleListAccountingEntries(
        companyId as string,
        1,
        initialDate,
        finalDate,
        minValue,
        maxValue,
        accountsSelected,
        selectedVendors,
        selectedDebits,
        selectedCredits,
        filterText,
        accountingEntriesAlreadyClassified === 'true' ? 'true' : undefined,
        showDespised === 'true' ? 'true' : undefined,
      )

      setIsLoading(false)

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
        )
      }
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao executar a classificação. Tente novamente mais tarde.',
      )
    }
  }

  const bigNumbersNames = [
    {
      name: 'Saldo do extrato',
    },
    {
      name: 'Saldo dos lançamentos',
    },
    {
      name: 'Variação de caixa',
    },
    {
      name: 'Total de crédito',
    },
    {
      name: 'Total de débito',
    },
  ]

  useEffect(() => {
    handleListAccountingEntries(
      companyId as string,
      1,
      initialDate,
      finalDate,
      minValue,
      maxValue,
      accountsSelected,
      selectedVendors,
      selectedDebits,
      selectedCredits,
      filterText,
      accountingEntriesAlreadyClassified === 'true' ? 'true' : undefined,
      showDespised === 'true' ? 'true' : undefined,
    )
    if (accountsSelected?.length > 0) {
      setShowResults(true)
    } else {
      setShowResults(false)
    }
  }, [
    accountsSelected,
    accountingEntriesAlreadyClassified,
    filterText,
    showDespised,
  ])

  useEffect(() => {
    if (ContainerRef.current) {
      setContainerWidth(ContainerRef.current.offsetWidth)
    }
  }, [])

  const ExportOptions = useMemo(() => {
    const normalizeOptions = [
      { name: 'Bling', premium: false },
      { name: 'DBCorp', premium: true },
    ]?.map((option, index) => {
      return {
        label: option.name,
        value: option.name,
        component: (
          <Stack direction="row" alignItems="center" padding="0 20px">
            <Typography marginLeft="15px" fontSize={14} fontWeight={400}>
              {option.name}
            </Typography>
            {option.premium && (
              <Stack
                alignItems="center"
                justifyContent="center"
                bgcolor="#FCD24C"
                borderRadius="16px"
                padding="8px 16px"
                marginLeft="10px"
              >
                <Typography fontSize="12px" fontWeight={700} color="#1E3466">
                  Premium
                </Typography>
              </Stack>
            )}
          </Stack>
        ),
      }
    })

    return normalizeOptions
  }, [])

  const handleGenerateXLS = async () => {
    const columns = [
      { header: 'Data', dataKey: 'date' },
      { header: 'Descrição - Cliente / Fornecedor', dataKey: 'description' },
      { header: 'Valor', dataKey: 'value' },
      { header: 'Debito', dataKey: 'debit' },
      { header: 'Credito', dataKey: 'credit' },
      { header: 'Categoria Financeira', dataKey: 'category' },
    ]

    const data: any[] = []

    await Promise.all(
      accountingEntries.map((item) => {
        const date = item.bankTransferDate
        const value = item.value
        const description = item.description
        const category = item.financialCategory
        const debit = item.debit
        const credit = item.credit

        data.push([date, description, value, debit, credit, category])
        return item
      }),
    )

    const sheets = [
      {
        sheetName: 'Relatório Classificador',
        columns: columns.map((column) => column.header),
        data,
      },
    ]

    await generateXLS(
      sheets,
      `Relatorio-Classificador-${format(new Date(), 'dd-MM-yyyy')}.xls`,
    )
  }

  const handleExport = (type: string) => {
    if (type === 'excel') {
      ShowModal({
        content: <ModalClassifierPendency handle={() => handleGenerateXLS()} />,
        title: 'Classificação pendente',
      })
    }

    if (type === 'erp') {
      ShowToast('warning', 'Função não implementada.')
    }
  }

  const handleReturnBigNumber = (name: string) => {
    switch (name) {
      case 'Saldo do extrato':
        return informationsBankBalance?.bankBalance

      case 'Saldo dos lançamentos':
        return informationsCashflow?.bankCashflow?.balance

      case 'Variação de caixa':
        return informationsCashflow?.bankCashflow?.total
      case 'Total de crédito':
        return informationsCashflow?.bankCashflow?.inflow
      case 'Total de débito':
        return informationsCashflow?.bankCashflow?.outflow

      default:
        return '0'
    }
  }

  const handleRecoverAll = async () => {
    if (selectedGridIds.length <= 0) {
      return
    }

    await Promise.all(
      selectedGridIds.map(async (item) => {
        const itemEntry = accountingEntries.find((f) => f.id === item)

        if (itemEntry) {
          await handleRecover(itemEntry)
        }

        return item
      }),
    )

    setSelectedGridIds([])
  }

  const handleDespiesell = async () => {
    if (selectedGridIds.length <= 0) {
      return
    }

    await Promise.all(
      selectedGridIds.map(async (item) => {
        const itemEntry = accountingEntries.find((f) => f.id === item)

        if (itemEntry) {
          await handleDespise(itemEntry)
        }

        return item
      }),
    )
    setSelectedGridIds([])
  }

  const selectedItemActions = [
    {
      title: 'Desprezar',
      action: () => handleDespiesell(),
    },
    {
      title: 'Recuperar',
      action: () => handleRecoverAll(),
    },
  ]

  return (
    <Stack
      sx={{
        backgroundColor: '#F4F8FA',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
      ref={ContainerRef}
    >
      <DefaultHeader
        breadcumbItems={[{ title: 'Home' }, { title: 'Classificador' }]}
        containerStyle={{
          height: '56px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '16px',
          zIndex: 9,
          position: 'relative',
        }}
        buttons={
          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '24px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => console.log('empty')}
            >
              <NotificationBellIcon />
            </IconButton>
          </Stack>
        }
      />

      <Stack
        width="100%"
        direction="row"
        gap="24px"
        sx={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Stack
          width="100%"
          padding="32px"
          gap="24px"
          sx={{
            flex: 1,
            overflowY: 'auto',
          }}
        >
          <BaseInternalHeader
            leftComponent={
              <BasePageTitle color="#1E3466" text="Classificador" />
            }
          />

          <Stack
            direction="row"
            justifyContent={!useTwoLinesHeader ? 'space-between' : 'flex-start'}
          >
            <Stack direction="row" width="351px">
              <Stack flexDirection="row" alignItems="flex-end" gap="18px">
                <Stack width="280px">
                  <BaseMultipleSelectInput
                    label="Conta bancária"
                    labelBackground="transparent"
                    options={[
                      ...bankAccountOptions,
                      {
                        label: 'Sem Conta',
                        value: 'null',
                        icon: (
                          <Logo
                            name={extractBankName('Sem Conta')}
                            width={32}
                            height={32}
                            logo={undefined}
                          />
                        ),
                      },
                    ]}
                    fullWidth
                    error=""
                    value={accountsSelected}
                    setState={(e) => setAccountsSelected(e)}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              gap={!useTwoLinesHeader ? '18px' : '12px'}
            >
              {!isSmallScreen && (
                <Box width={!useTwoLinesHeader ? '350px' : '200px'}>
                  <BaseInput
                    fullWidth
                    labelBackground="#F4F8FA"
                    type="text"
                    label={'Buscar'}
                    setState={(event) => {
                      if (!event) {
                        return setFilterText && setFilterText('')
                      }

                      setFilterText && setFilterText(event)
                    }}
                    value={filterText ?? ''}
                    error={null}
                    iconStart={<SearchIcon />}
                  />
                </Box>
              )}

              <Button
                variant="text"
                sx={{ minWidth: '123px', height: '40px' }}
                onClick={() =>
                  ShowModal({
                    content: (
                      <ModalClassifierExport
                        handle={(e) => handleExport(e)}
                        options={ExportOptions}
                      />
                    ),
                    title: 'Exportar',
                  })
                }
              >
                <Typography
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="16.41px"
                >
                  Exportar
                </Typography>
              </Button>

              <Button
                variant="outlined"
                sx={{ minWidth: '123px', height: '40px' }}
                onClick={() => handleClassifyAll()}
              >
                <Typography
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="16.41px"
                >
                  Aplicar regras
                </Typography>
              </Button>

              <CascadeButton
                variant="primary"
                direction="left"
                options={[
                  {
                    label: 'Criar lançamento',
                    onClick: () => console.log('go to my account'),
                    subItens: [
                      {
                        label: 'Realizada',
                        onClick: () =>
                          ShowModal({
                            content: (
                              <ModalClassifierPaymentEntry
                                accountsOptions={bankAccountOptions}
                                handle={handleCreateNewEntries}
                                type="made"
                                companyId={String(companyId)}
                              />
                            ),
                            title: 'Criar lançamento',
                          }),
                        type: 'single',
                      },
                      {
                        label: 'Provisão',
                        onClick: () =>
                          ShowModal({
                            content: (
                              <ModalClassifierPaymentEntry
                                accountsOptions={bankAccountOptions}
                                handle={handleCreateNewEntries}
                                type="provision"
                                companyId={String(companyId)}
                              />
                            ),
                            title: 'Criar lançamento',
                          }),
                        type: 'single',
                      },
                      {
                        label: 'Transferência',
                        onClick: () =>
                          ShowModal({
                            content: (
                              <ModalClassifierPaymentEntry
                                accountsOptions={bankAccountOptions}
                                handle={handleCreateNewEntries}
                                type="transfer"
                                companyId={String(companyId)}
                              />
                            ),
                            title: 'Criar lançamento',
                          }),
                        type: 'single',
                      },
                    ],
                  },
                  {
                    label: 'Colunas',
                    onClick: () => console.log('press me'),
                    subItensSelected: columnsTableSelected,
                    subItens: [
                      {
                        label: 'Data',
                        onClick: () => handleSelectColumns('date'),
                        type: 'multi',
                        default: 'checked',
                        value: 'date',
                      },
                      {
                        label: 'Descrição - Cliente Fornecedor',
                        onClick: () => handleSelectColumns('description'),
                        type: 'multi',
                        value: 'description',
                      },
                      {
                        label: 'Valor',
                        onClick: () => handleSelectColumns('value'),
                        type: 'multi',
                        value: 'value',
                      },
                      {
                        label: 'Débito',
                        onClick: () => handleSelectColumns('debit'),
                        type: 'multi',
                        value: 'debit',
                      },
                      {
                        label: 'Crédito',
                        onClick: () => handleSelectColumns('credit'),
                        type: 'multi',
                        value: 'credit',
                      },
                      {
                        label: 'Cat. Financeira',
                        onClick: () => handleSelectColumns('financialCategory'),
                        type: 'multi',
                        value: 'financialCategory',
                      },
                      {
                        label: 'Histórico',
                        onClick: () => handleSelectColumns('historic'),
                        type: 'multi',
                        value: 'historic',
                      },
                    ],
                  },
                ]}
                iconButton
              />
            </Stack>
          </Stack>

          {!accountsSelected?.length && (
            <Stack alignItems="center" justifyContent="center">
              <Typography fontWeight={400} fontSize="16px" marginTop="50px">
                Selecione uma ou mais contas bancárias para visualizar os
                lançamentos
              </Typography>
            </Stack>
          )}

          {showResults && (
            <Stack flex={1} width="100%" overflow="hidden">
              <Stack
                direction="row"
                bgcolor="#FFFFFF"
                borderRadius="16px"
                width="100%"
              >
                <Stack
                  width="100%"
                  direction="row"
                  padding={isSmallScreen ? '16px' : '24px'}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {bigNumbersNames?.map((item) => (
                    <Box key={item.name}>
                      <Typography
                        color="#777C84"
                        fontSize="14px"
                        fontWeight={600}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        color="#1E3466"
                        fontSize={isSmallScreen ? '16px' : '24px'}
                        fontWeight={700}
                      >
                        {formatMoney(Number(handleReturnBigNumber(item.name)))}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                gap="16px"
                marginTop="12px"
              >
                <Box marginRight={isSmallScreen ? '16px' : '24px'}>
                  <Button
                    variant="outlined"
                    disabled={selectedGridIds.length <= 0}
                    onClick={handleClick}
                  >
                    Ações nos selecionados
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{
                      boxShadow: '0px 4px 4px 0px #00000040',
                      marginTop: '8px',
                    }}
                  >
                    {selectedItemActions.map((selectedItem, index) => {
                      return (
                        <MenuItem
                          key={index}
                          sx={{
                            width: 'auto',
                            minWidth: '197px',
                          }}
                          onClick={() => {
                            handleClose()
                            selectedItem.action && selectedItem.action()
                          }}
                        >
                          {selectedItem.title}
                        </MenuItem>
                      )
                    })}
                  </Menu>
                </Box>
                <Stack direction="row" gap="12px" alignItems="center">
                  <Checkbox
                    sx={{
                      color: '#4865CC',
                      '&.Mui-checked': {
                        color: '#4865CC',
                      },
                    }}
                    checked={accountingEntriesAlreadyClassified === 'true'}
                    onChange={() => {
                      setAccountingEntriesAlreadyClassified(
                        accountingEntriesAlreadyClassified === 'true'
                          ? undefined
                          : 'true',
                      )
                    }}
                  />
                  <ListItemText
                    primary={
                      isSmallScreen
                        ? 'Apenas classificados'
                        : 'Mostrar apenas lançamentos classificados'
                    }
                  />
                </Stack>
                <Stack direction="row" gap="12px" alignItems="center">
                  <Checkbox
                    sx={{
                      color: '#4865CC',
                      '&.Mui-checked': {
                        color: '#4865CC',
                      },
                    }}
                    checked={showDespised === 'true'}
                    onChange={() => {
                      setshowDespised(
                        showDespised === 'true' ? undefined : 'true',
                      )
                    }}
                  />
                  <ListItemText
                    primary={
                      isSmallScreen
                        ? 'Mostrar desprezados'
                        : 'Mostrar lançamentos desprezados'
                    }
                  />
                </Stack>
              </Stack>
              {isLoading && (
                <Stack
                  width="100%"
                  height="200px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Stack>
              )}
              {!isLoading && (
                <Table
                  columns={columns}
                  rows={accountingEntries}
                  loadMore={(valuePage) =>
                    handleListAccountingEntries(
                      String(companyId),
                      valuePage,
                      initialDate,
                      finalDate,
                      minValue,
                      maxValue,
                      accountsSelected,
                      selectedVendors,
                      selectedDebits,
                      selectedCredits,
                      filterText,
                      accountingEntriesAlreadyClassified === 'true'
                        ? 'true'
                        : undefined,
                      showDespised === 'true' ? 'true' : undefined,
                    )
                  }
                  tableStyle={{
                    flex: 1,
                  }}
                  tableContainerStyle={{
                    maxHeight: '650px',
                    flex: 1,
                    maxWidth: `${containerWidth - 48}px`,
                    overflowY: 'auto',
                    overflowX: 'auto',
                  }}
                  disabledStatuses={[
                    CompanyAccountEntryConciliationStatusEnum.DESPISED,
                  ]}
                  totalPages={totalPages}
                  totalRegisters={totalRegisters}
                  currentPage={currentPage}
                  allowSelectAll={true}
                  loading={false}
                  showFilterButton={true}
                  showSearchButton={true}
                  setSelectedGridIds={setSelectedGridIds}
                  showNoResultsOverlay={false}
                />
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
