import { useState } from 'react'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import {
  IBankAccountTransfer,
  IBankAccountTransferResponse,
} from 'src/service/service-bank'
import { ListTransfersUseCase } from 'src/usecase/transfers/list-transfers'

export function useTransfers(listTransfersUseCase: ListTransfersUseCase) {
  const { ShowToast } = ToastFullContext()
  const [isLoading, setIsLoading] = useState(false)

  const [transfers, setTransfers] = useState<IBankAccountTransfer[]>([])
  const [balance, setBalance] = useState(0)

  const handleListTransfersUsecase = async ({
    accountsIds,
    date,
  }: {
    date?: string[]
    accountsIds?: string[]
  }) => {
    try {
      if (!accountsIds || accountsIds.length === 0) {
        setTransfers([])
        setBalance(0)
        return
      }
      let page = 1
      let allTransfers = [] as IBankAccountTransfer[]

      setIsLoading(true)

      try {
        let hasMorePages = true

        while (hasMorePages) {
          const usecaseResult = await listTransfersUseCase.handle({
            accountsIds,
            date,
            page,
          })

          if (usecaseResult.isFailure) {
            ShowToast(
              'error',
              'Ocorreu um erro ao buscar as transferências. Tente novamente mais tarde.',
            )
            break
          }

          const transfersData =
            usecaseResult.getValue() as IBankAccountTransferResponse

          if (page === 1) {
            setBalance(Number(transfersData.balance))
          }

          // Concatenar as transferências atuais com as anteriores
          allTransfers = [...allTransfers, ...transfersData.transfers]

          // Checar se a página atual é a última
          if (page >= transfersData.pages) {
            hasMorePages = false // Parar o loop se não houver mais páginas
          } else {
            page++ // Incrementa o número da página para a próxima requisição
          }
        }

        // Atualiza o estado com todas as transferências acumuladas
        setTransfers(allTransfers)
      } catch (error) {
        ShowToast('error', 'Ocorreu um erro inesperado. Tente novamente.')
      } finally {
        setIsLoading(false) // Garante que o loading será removido após todas as requisições
      }
    } catch (error) {
      setIsLoading(false)
      ShowToast('error', 'Ocorreu um erro ao buscar as transferências.')
    }
  }

  const handleListTransfers = async ({
    accountsIds,
    date,
  }: {
    date?: string[]
    accountsIds?: string[]
  }) => {
    try {
      handleListTransfersUsecase({ accountsIds, date })
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as transferências. Tente novamente mais tarde.',
      )
    }
  }

  return {
    isLoading,
    transfers,
    setIsLoading,
    handleListTransfers,
    balance,
  }
}
