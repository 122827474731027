import {
  CompanyApiRemoteService,
  IAccountingEntriesConciliatorRequest,
  IListAccountingEntriesConciliatorResponseDTO,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListAccountingEntriesToConciliatorUseCase
  implements
    IUsecase<
      IAccountingEntriesConciliatorRequest,
      IListAccountingEntriesConciliatorResponseDTO
    >
{
  constructor(private companyApi: CompanyApiRemoteService) {}

  public async handle(
    data: IAccountingEntriesConciliatorRequest,
  ): Promise<Result<IListAccountingEntriesConciliatorResponseDTO>> {
    try {
      const { companyId, ledgerAccount, dateEnd, dateStart, query, status } =
        data

      const response = await this.companyApi.getAccountingEntriesConciliator(
        companyId,
        status,
        ledgerAccount,
        dateStart,
        dateEnd,
        query,
      )

      if (response.isFailure) {
        return Result.fail(new Error('Erro ao recuperar dados do conciliador.'))
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao recuperar os dados'))
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
