import React from 'react'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import TransferWithinAStationOutlinedIcon from '@mui/icons-material/TransferWithinAStationOutlined'
import { Checkbox, Divider, Stack, Typography, useTheme } from '@mui/material'
import {
  CompanyUserPermissionTypeEnum,
  CompanyUserPermissionTypeEnumUtil,
} from 'src/service/service-company'
import { DeleteTableIcon } from '../icons/delete'
import { EditTableIcon } from '../icons/edit'

export type CompanyUserCardProps = {
  id: string
  name: string
  userName: string
  permission: CompanyUserPermissionTypeEnum
  isSelected: boolean
  editButtonAction?: (companyUserId: string) => void
  deleteButtonAction?: (companyUserId: string) => void
  handleSelectionChange: (companyUserId: string) => void
  transferOwnerButtonAction?: () => void
  allowTransferOwner?: boolean
  disableActions?: boolean
  isOwner?: boolean
}

// Função utilitária para extrair iniciais
const getInitials = (fullName: string) => {
  if (!fullName) {
    return ''
  }
  const [firstName, lastName] = fullName?.split(' ')
  return `${firstName[0]}${lastName ? lastName[0] : ''}`.toUpperCase()
}

export const CompanyUserCard = ({
  id,
  name,
  userName,
  permission,
  isSelected,
  editButtonAction,
  deleteButtonAction,
  handleSelectionChange,
  transferOwnerButtonAction,
  allowTransferOwner = false,
  disableActions = false,
  isOwner = false,
}: CompanyUserCardProps) => {
  const theme = useTheme()
  const initials = getInitials(name)

  return (
    <Stack
      key={`account-card-${id}`}
      width="300px"
      height={allowTransferOwner ? '280px' : '250px'}
      borderRadius="8px"
      border="1px solid #B9BFC7"
      position="relative"
    >
      <Stack
        padding="12px 24px"
        width="calc(100% - 32px)"
        alignItems="flex-start"
      >
        <Stack
          direction="row"
          gap="12px"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" gap="12px">
            <Stack
              width="40px"
              height="40px"
              borderRadius="50%"
              bgcolor="#5ED1A2"
              alignItems="center"
              justifyContent="center"
            >
              <Typography fontWeight="600" fontSize="16px" color="white">
                {initials}
              </Typography>
            </Stack>

            <Stack>
              <Typography
                key={`account-card-name-${id}`}
                fontWeight="700"
                fontSize="16px"
              >
                {name}
              </Typography>
              <Typography
                key={`account-card-username-${id}`}
                fontWeight="400"
                color="#777C84"
                fontSize="12px"
              >
                @{userName}
              </Typography>
            </Stack>
          </Stack>

          <Checkbox
            sx={{
              color: '#4865CC',
              '&.Mui-checked': {
                color: '#4865CC',
              },
            }}
            disabled={disableActions}
            onClick={() => handleSelectionChange(id)}
            checked={isSelected}
          />
        </Stack>
      </Stack>
      <Divider sx={{ margin: '24px', marginTop: '0px', marginBottom: '0px' }} />
      <Stack gap="24px" padding="24px">
        <Stack width="100%" gap="12px" alignItems="flex-start">
          <Stack
            width="100%"
            direction="row"
            gap="12px"
            alignItems="flex-start"
          >
            <PeopleOutlinedIcon sx={{ color: theme.palette.primary.main }} />

            <Typography
              display="flex"
              key={`account-card-permission-title-${id}`}
              fontWeight="600"
              fontSize="14px"
              gap="12px"
            >
              Tipo de usuário
            </Typography>
          </Stack>
          <Typography
            display="flex"
            key={`account-card-person-${id}`}
            fontWeight="400"
            fontSize="14px"
            gap="12px"
          >
            {!isOwner
              ? CompanyUserPermissionTypeEnumUtil.toString(permission)
              : 'Proprietário'}
          </Typography>
        </Stack>
      </Stack>
      <Stack width="100%" gap="8px" justifyContent="center" alignItems="center">
        {allowTransferOwner && !disableActions && (
          <Stack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap="36px"
            color="#4865CC"
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
            >
              <TransferWithinAStationOutlinedIcon />
              <Typography
                key={`account-card-transfer-${id}`}
                fontWeight="600"
                fontSize="14px"
                onClick={() =>
                  transferOwnerButtonAction && transferOwnerButtonAction()
                }
              >
                Transferir titularidade
              </Typography>
            </Stack>
          </Stack>
        )}
        {!disableActions && (
          <Stack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap="36px"
            color="#4865CC"
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
            >
              <EditTableIcon />
              <Typography
                key={`account-card-edit-${id}`}
                fontWeight="600"
                fontSize="14px"
                onClick={() => editButtonAction && editButtonAction(id)}
              >
                Editar
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ cursor: 'pointer' }}
            >
              <DeleteTableIcon />
              <Typography
                key={`account-card-exclude-${id}`}
                fontWeight="600"
                fontSize="14px"
                onClick={() => deleteButtonAction && deleteButtonAction(id)}
              >
                Excluir
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
