import * as XLSX from 'xlsx'

interface SheetData {
  sheetName: string
  columns: string[]
  data: (string | number)[][]
}

export const generateXLS = (sheets: SheetData[], fileName: string): void => {
  const wb = XLSX.utils.book_new()

  sheets.forEach(({ sheetName, columns, data }) => {
    const title = `Relatório - ${sheetName}`
    const creationDate = `Data de Criação: ${new Date().toLocaleString()}`

    const header = [[title], [creationDate], [], columns]
    const xlsResult = [...header, ...data]
    const ws = XLSX.utils.aoa_to_sheet(xlsResult)
    const range = XLSX.utils.decode_range(ws['!ref'] || '')
    ws.A1.s = { font: { bold: true, sz: 14 } }
    ws.A2.s = { font: { bold: true, sz: 12 } }

    if (range.e.c > 0) {
      ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: range.e.c } }]
      ws['!merges'].push({ s: { r: 1, c: 0 }, e: { r: 1, c: range.e.c } })
    }

    for (let col = 0; col < columns.length; col++) {
      const cellAddress = XLSX.utils.encode_cell({ r: 3, c: col })
      if (ws[cellAddress]) {
        ws[cellAddress].s = { font: { bold: true } }
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, sheetName)
  })

  XLSX.writeFile(wb, fileName)
}
