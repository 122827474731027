import { Stack, Typography, useMediaQuery } from '@mui/material'

interface IProps {
  label?: string
  labelColor?: string
  labelCustomStyle?: object
  value: string
  valueColor?: string
  valueCustomStyle?: object
}

export function BigNumbersWidget({
  value,
  label,
  labelColor,
  valueColor,
  labelCustomStyle,
  valueCustomStyle,
}: IProps) {
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')
  return (
    <Stack alignItems="center">
      {label && (
        <Typography
          fontWeight={700}
          fontSize={isSmallScreen ? '12px' : '16px'}
          lineHeight="21.79px"
          color={labelColor || '#777C84'}
          sx={labelCustomStyle}
        >
          {label}
        </Typography>
      )}

      <Typography
        fontWeight={700}
        fontSize={isSmallScreen ? '16px' : '24px'}
        lineHeight="32.69px"
        color={valueColor || '#1E3466'}
        sx={valueCustomStyle}
      >
        {value}
      </Typography>
    </Stack>
  )
}
