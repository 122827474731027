import { Button, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AccountDetailsCard } from 'src/component/account/account-details'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { AccountImportExtractModal } from 'src/component/modal/modal-import-extract'
import { ImportExtractSuccessModal } from 'src/component/modal/modal-import-extract-success'
import { ImportFileModal } from 'src/component/modal/modal-import-file'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { SyncSystemicAccountsModal } from 'src/component/modal/systemicAccount/modal-sync-accounts'
import {
  BankAccountPersonTypeEnum,
  BankAccountPersonTypeEnumUtil,
  BankAccountTypeEnum,
  BankAccountTypeEnumUtil,
} from 'src/service/service-account'
import { CreateAccountStepFourUseCase } from 'src/usecase/bank-account/usecase-account-create-step-four-form'
import { AccountGetCSVColumnsFileUseCase } from 'src/usecase/bank-account/usecase-account-get-csv-columns'
import { ListLedgerAccountsUseCase } from 'src/usecase/bank-account/usecase-account-get-ledger-accounts'
import { UpdateAccountUseCase } from 'src/usecase/bank-account/usecase-account-update'
import { AccountImportOfxFileUseCase } from 'src/usecase/bank-account/usecase-account-upload-file-ofx'

interface CreateAccountPageProps {
  useCase: CreateAccountStepFourUseCase
  uploadOFXFile: AccountImportOfxFileUseCase
  getCsvFileColumnsUseCase: AccountGetCSVColumnsFileUseCase
  listLedgerAccountsUseCase: ListLedgerAccountsUseCase
  updateUseCase: UpdateAccountUseCase
  accountId?: string
  agencyNumber?: string
  personType?: BankAccountPersonTypeEnum
  accountType?: BankAccountTypeEnum
  bankLogo?: string
  bankName: string
  bankNumber?: string
  accountNumber?: string
  amount?: string
  date?: string
  name?: string
  isOpenFinance: boolean
}

export function CreateSystemicAccountStepFourPage(
  props: CreateAccountPageProps,
): any {
  const { companyId } = useParams()
  const { ShowModal, HideModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()
  const [ledgerAccount, setLedgerAccount] = useState<string>('')
  const [ledgerAccountBalance, setLedgerAccountBalance] = useState<string>('')
  const [ledgerAccountStartDate, setLedgerAccountStartDate] =
    useState<string>('')
  // const [ledgerAccounts, setLedgerAccounts] = useState<{
  //   debits: string[]
  //   credits: string[]
  // }>({
  //   debits: [],
  //   credits: [],
  // })
  const navigate = useNavigate()

  // const handleGetData = async () => {
  //   const ledgerAccountResult = await props.listLedgerAccountsUseCase.handle({
  //     companyId: companyId as string,
  //     query: '',
  //   })

  //   if (ledgerAccountResult.isFailure) {
  //     ShowToast(
  //       'error',
  //       'Ocorreu um erro ao buscar a contas bancária. Tente novamente mais tarde.',
  //     )
  //   }

  //   const ledgerAccountData = ledgerAccountResult.getValue()
  //   setLedgerAccounts(ledgerAccountData ?? { debits: [], credits: [] })
  // }

  // useEffect(() => {
  //   handleGetData()
  // }, [props])

  const handleBack = () => {
    navigate(`/company/${companyId}/account/list`)
  }

  const handleValidateCSVFileColumns = async (file: File) => {
    const columnsData = await props.getCsvFileColumnsUseCase.handle({
      file,
      accountId: props.accountId as string,
      companyId: companyId as string,
    })

    HideModal()
    if (columnsData.isFailure) {
      return ShowToast(
        'error',
        'Ocorreu um erro ao processar o arquivo. Tente novamente mais tarde.',
      )
    }

    const columns = await columnsData.getValue()
    if (columns?.columns.length === 0) {
      return ShowToast(
        'warning',
        'O arquivo selecionado não possui nenhuma coluna válida.',
      )
    }

    return navigate(
      `/company/${companyId}/account/${props.accountId}/import/extract/csv`,
      {
        state: { fileColumns: columns?.columns, file },
      },
    )
  }

  const handleUploadOFXFile = async (file: File) => {
    try {
      const ofxFile = await props.uploadOFXFile.handle({
        file,
        companyId: companyId as string,
        accountId: props.accountId as string,
      })

      if (ofxFile.isFailure) {
        HideModal()
        return ShowToast(
          'error',
          'Ocorreu um erro ao processar o arquivo. Tente novamente mais tarde.',
        )
      }

      const ofxResult = ofxFile.getValue()
      HideModal()

      if (ofxResult) {
        ShowModal({
          content: (
            <ImportExtractSuccessModal
              finalBalance={ofxResult.amount}
              totalFailed={ofxResult?.totalFailed}
              totalImported={ofxResult?.totalImported}
              totalRegister={ofxResult?.totalRegister}
            />
          ),
          title: '',
        })
      } else {
        ShowToast(
          'warning',
          'Arquivo importado com sucesso, porém não foi possível recuperar os dados de sucessos / falhas ao importar.',
        )
      }
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao importar o arquivo. Tente novamente mais tarde.',
      )
    }
  }

  const handleUploadConfirmoOFXBalance = async (file: File) => {
    handleUploadOFXFile(file)
  }

  const handleImportModalHandle = (isCsv: boolean) => {
    HideModal()
    if (isCsv) {
      ShowModal({
        content: (
          <ImportFileModal
            showInstructions={true}
            instructions={[
              '• Datas devem estar no seguinte formato DD/MM/AAAA',
              '• Caso uma conta contábil/bancária não seja encontrada na plataforma, ela não será criada automaticamente',
              '• As células não devem conter fórmulas',
              '• Valores devem respeitar a pontuação correta',
              '• , representa centavos',
              '• Não utilizar . para representar mil',
              '• Colulas * representam obrigatoriedade de preenchimento. Caso não sejam preenchidas a linha inteira será desconsiderada',
              '• CPF/CNPJ não devem conter pontuações nem caracteres especiais',
              '• Em valor, o sinal - [menos] deve representar saídas',
            ]}
            controlRegisterId={props.accountId}
            handle={handleValidateCSVFileColumns}
            allowedExtensions={['csv']}
            template={{
              url: '/csv-example/import-extract.csv',
              filename: 'import-extract.csv',
            }}
          />
        ),
        title: 'Importar planilha excel',
        closeButton: false,
      })
    } else {
      ShowModal({
        content: (
          <ImportFileModal
            showInstructions={false}
            controlRegisterId={props.accountId}
            handle={handleUploadConfirmoOFXBalance}
            allowedExtensions={['ofx']}
          />
        ),
        title: 'Importar OFX',
        closeButton: false,
      })
    }
  }

  const handleOpenImportExtractModal = () => {
    ShowModal({
      content: (
        <AccountImportExtractModal
          accountId={props.accountId as string}
          handle={handleImportModalHandle}
        />
      ),
      title: 'Importar extrato',
      closeButton: false,
    })
  }

  const syncAccounts = async () => {
    HideModal()
    const data = {
      id: props.accountId as string,
      companyId: companyId as string,
      ledgerAccount,
      ledgerAccountBalance:
        Number(ledgerAccountBalance?.replace(/[^\d]/g, '')) / 100,
      ledgerAccountStartDate: new Date(
        format(ledgerAccountStartDate, 'dd/MM/yyyy'),
      ),
    }

    const updateData = await props.updateUseCase.handle(data)

    if (updateData.isFailure) {
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a atualização. Tente novamente mais tarde.',
      )
    } else {
      ShowToast('success', 'Dados atualizados com sucesso.')
      handleBack()
    }
  }

  const handleSyncAccountstModal = () => {
    ShowModal({
      content: (
        <SyncSystemicAccountsModal
          currentSelectedAccount={ledgerAccount}
          currentSelectedAccountBalance={ledgerAccountBalance}
          currentSelectedAccountStartDate={ledgerAccountStartDate}
          setLedgerAccount={setLedgerAccount}
          setLedgerAccountBalance={setLedgerAccountBalance}
          setLedgerAccountStartDate={setLedgerAccountStartDate}
          handle={syncAccounts}
        />
      ),
      title: 'Vincular conta contábil',
      closeButton: false,
    })
  }

  return (
    <Stack
      width="100%"
      height="100%"
      direction="row"
      sx={{ backgroundColor: '#F4F8FA' }}
      gap="24px"
      marginTop="32px"
    >
      <Stack gap="20px" width="100%" maxWidth="500px">
        <Typography
          fontWeight="700"
          fontSize="24px"
          lineHeight="32px"
          color="#1E1E1E"
        >
          Parabéns, sua nova conta foi criada!
        </Typography>

        <Stack width="100%" direction="column" gap="20px">
          <AccountDetailsCard
            title={props.name ?? ''}
            amount={
              props.amount
                ? new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(
                    props.isOpenFinance
                      ? Number.parseInt(
                          props.amount
                            .replace(/[,.]/g, '')
                            .replace('R$', '')
                            .trim(),
                        ) / 100
                      : Number.parseInt(
                          props.amount
                            .replace(/[^\d-]/g, '')
                            .replace('R$', '')
                            .trim(),
                        ) / 100,
                  )
                : '-'
            }
            bankName={props.bankName}
            defaultLogo={props.bankLogo}
            id={1}
            personType={BankAccountPersonTypeEnumUtil.toString(
              props.personType as BankAccountPersonTypeEnum,
            )}
            initialDate={format(new Date(props.date as string), 'dd/MM/yyyy')}
            accountType={BankAccountTypeEnumUtil.toString(
              props.accountType as BankAccountTypeEnum,
            )}
          />

          <Stack
            width="100%"
            alignItems="center"
            gap="12px"
            justifyContent="flex-end"
            direction="row"
          >
            <Button
              fullWidth={false}
              variant="outlined"
              onClick={handleSyncAccountstModal}
              sx={{
                padding: '12px 24px 12px 16px',
                gap: '8px',
                width: '260px',
                height: '40px',
              }}
            >
              Vincular conta contábil
            </Button>
            {!props.isOpenFinance && (
              <Button
                fullWidth={false}
                variant="outlined"
                onClick={handleOpenImportExtractModal}
                sx={{
                  padding: '12px 24px 12px 16px',
                  gap: '8px',
                  width: '200px',
                  height: '40px',
                }}
              >
                Importar extrato
              </Button>
            )}
            <Button
              fullWidth={false}
              variant="contained"
              onClick={handleBack}
              sx={{
                padding: '12px 24px 12px 16px',
                gap: '8px',
                width: '110px',
                height: '40px',
              }}
            >
              Finalizar
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
