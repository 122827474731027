export function getInitials(name?: string) {
  if (!name) return ''

  const partes = name?.split(' ')
  if (partes.length === 1) {
    return partes[0][0]
  } else {
    return partes[0][0] + partes[1][0]
  }
}

export function getFirstName(name: string) {
  if (!name) return ''

  const splittedName = name.split(' ')

  return `${splittedName[0]}`
}

export function getFormattedUserName(name?: string) {
  if (!name) return ''

  const partes = name.split(' ')
  if (partes.length === 1) {
    return partes[0]
  } else {
    return `${partes[0]} ` + `${partes[1]}`
  }
}

export function maskBankAgency(value: string): string {
  if (!value) {
    return ''
  }
  value = value.replace(/\D/g, '')

  if (value.length <= 4) {
    return value.replace(/(\d{4})/, '$1')
  } else if (value.length === 5) {
    return value.replace(/(\d{4})(\d{1})/, '$1-$2')
  } else if (value.length === 6) {
    return value.replace(/(\d{5})(\d{1})/, '$1-$2')
  }

  return value
}

export function maskBankAccount(value: string): string {
  if (!value) {
    return ''
  }

  value = value.replace(/\D/g, '')

  if (value.length <= 5) {
    return value.replace(/(\d{1,5})/, '$1')
  } else if (value.length === 6) {
    return value.replace(/(\d{5})(\d{1})/, '$1-$2')
  } else if (value.length === 7) {
    return value.replace(/(\d{6})(\d{1})/, '$1-$2')
  }

  return value
}
