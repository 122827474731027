import {
  CompanyApiRemoteService,
  TConciliatorExecuteDTO,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ConciliateAccountingEntriesUseCase
  implements IUsecase<{ data: TConciliatorExecuteDTO }, void>
{
  constructor(private companyApi: CompanyApiRemoteService) {}

  public async handle({
    data,
  }: {
    data: TConciliatorExecuteDTO
  }): Promise<Result<void>> {
    try {
      // eslint-disable-next-line prettier/prettier
      const response = await this.companyApi.executeConciliation({
        companyId: data.companyId,
        entryId: data.entryId,
        ledgerAccount: data.ledgerAccount,
      })

      if (response.isFailure) {
        return Result.fail(new Error('Erro ao executar a conciliação.'))
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
