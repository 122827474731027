import LaunchIcon from '@mui/icons-material/Launch'
import PersonAddDisabledOutlinedIcon from '@mui/icons-material/PersonAddDisabledOutlined'
import { Stack } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { UsersIcon } from 'src/component/icons/users'
import {
  AddUserToCompanyModal,
  TModalAddUserType,
} from 'src/component/modal/company/modal-add-user-company'
import { CompanyCreateUsersMassModal } from 'src/component/modal/company/modal-company-create-user-mass'
import { FilterModal } from 'src/component/modal/modal-filter'
import {
  ModalFullContext,
  ModalPositionEnum,
} from 'src/component/modal/modal-provider'
import { EditPlanSubscriptionPlanModal } from 'src/component/modal/plans/modal-edit-subscription-plan'
import { SimpleTextModal } from 'src/component/modal/simple-modal-text'
import { ColumnsCommon } from 'src/component/table/table'
import { UserFullContext } from 'src/context/context-user'
import {
  CompanyUserPermissionTypeEnum,
  ICompanyPlan,
  ICompanyStatusEnum,
  IListCompanyFilterDTO,
  ISubscription,
  TCompanyInfoResponse,
  TCompanyUserCreateDTO,
} from 'src/service/service-company'
import { TListUsersResponse } from 'src/service/service-user'
import { CreateCompanyUsersUseCase } from 'src/usecase/company/usecase-company-add-users'
import { EditCompanySubscriptionPlanUseCase } from 'src/usecase/company/usecase-company-edit-subscriptionplan'
import { LeaveCompanyUseCase } from 'src/usecase/company/usecase-company-leave'
import { ListCompanyUseCase } from 'src/usecase/company/usecase-company-list'
import { PauseCompanySubscriptionPlanUseCase } from 'src/usecase/company/usecase-company-pause-subsacription-plan'
import { ListCompanyPlansUseCase } from 'src/usecase/company/usecase-company-plans-list'
import { ListUserSubscriptionsPlansUseCase } from 'src/usecase/company/usecase-company-subscriptions'
import { UserListUseCase } from 'src/usecase/user/usecase-user-list'

export function useCompany(
  listCompaniesUseCase: ListCompanyUseCase,
  addUserToCompanyUseCase: CreateCompanyUsersUseCase,
  listUsersUseCase: UserListUseCase,
  leaveCompanyUseCase: LeaveCompanyUseCase,
  listCompanyPlansUseCase: ListCompanyPlansUseCase,
  pauseCompanySubscriptionPlanUseCase: PauseCompanySubscriptionPlanUseCase,
  editCompanySubscriptionPlanUseCase: EditCompanySubscriptionPlanUseCase,
  listUserSubscriptionsPlansUseCase: ListUserSubscriptionsPlansUseCase,
) {
  const navigate = useNavigate()
  const { CompanyInfo, setCurrentCompanyId, GetUserData, UserInfo } =
    UserFullContext()
  const { ShowToast } = ToastFullContext()
  const { ShowModal, HideModal } = ModalFullContext()
  const [usersData, setUsersData] = useState<TListUsersResponse['users'][]>([])
  const [filter, setFilter] = useState<IListCompanyFilterDTO>(
    {} as IListCompanyFilterDTO,
  )
  const user = GetUserData()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [companies, setCompanies] = useState<
    TCompanyInfoResponse['companies'][]
  >([])
  const [plans, setPlans] = useState<ICompanyPlan[]>([])
  const [filterPlans, setFilterPlans] = useState<string>('')
  const [filterStatus, setFilterStatus] = useState<string>('')
  const [filterDay, setFilterDay] = useState<string>('')
  const [selectedGridIds, setSelectedGridIds] = useState<string[]>([])
  const [subscriptions, setSubscriptions] = useState<ISubscription>()

  const handleListUsers = async () => {
    const usersData = await listUsersUseCase.handle()

    if (usersData.isFailure) {
      return
    }

    const users = usersData.getValue()
    setUsersData(users as TListUsersResponse['users'][])
  }

  const handleGoToSupport = () => {
    navigate('/support')
  }

  const handleListUserSubscriptionsUseCase = async () => {
    try {
      setIsLoading(true)
      const plansResult = await listUserSubscriptionsPlansUseCase.handle()

      if (plansResult.isFailure) {
        setIsLoading(false)
        return ShowToast(
          'error',
          'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
        )
      }

      const plansData = plansResult.getValue()
      setSubscriptions(plansData as ISubscription)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
      )
    }
  }

  const handleListPlansUseCase = async () => {
    try {
      setIsLoading(true)
      const plansResult = await listCompanyPlansUseCase.handle()

      if (plansResult.isFailure) {
        setIsLoading(false)
        return ShowToast(
          'error',
          'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
        )
      }

      const plansData = plansResult.getValue()

      if (plansData === null) {
        setIsLoading(false)
        return ShowToast(
          'error',
          'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
        )
      }
      setPlans(plansData as ICompanyPlan[])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
      )
    }
  }

  const handleGoToDetails = (companyId: string, companyName: string) => {
    setCurrentCompanyId(companyId)
    CompanyInfo({ id: companyId, name: companyName })
    navigate(`/company/${companyId}`)
  }

  const handleListUserCompaniesUsecase = async () => {
    try {
      setIsLoading(true)
      const usecaseResult = await listCompaniesUseCase.handle({})
      setIsLoading(false)

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.',
        )
      }

      const companiesData =
        usecaseResult.getValue() as TCompanyInfoResponse['companies'][]

      await UserInfo({
        id: user?.id,
        name: user?.name,
        companies: companiesData,
        companyName: user?.companyName,
        personType: user?.personType,
        cpfCnpj: user?.cpfCnpj,
        username: user?.username,
        termsOfUse: user?.termsOfUse,
        email: user?.email,
        backupCode: user?.backupCode,
        twoAuthEnabled: user?.twoAuthEnabled,
      })
      setCompanies(companiesData)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.',
      )
    }
  }

  const handleListUserCompanies = async () => {
    try {
      handleListUserCompaniesUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as empresas. Tente novamente mais tarde.',
      )
    }
  }

  const handleHideAndRefetchCompanies = () => {
    handleListUserCompaniesUsecase()
    HideModal()
  }

  const handleAddUsersModal = (companyId: string) => {
    const currentCompany = companies.find((f) => f.id === companyId)
    const ownerId = currentCompany?.owner.id ?? ''
    const modalUsers = usersData
      .filter((f) => f.id !== ownerId)
      .map((user) => {
        const alreadyConfiguredUser = currentCompany?.users?.find(
          (obj) => obj.user.id === user.id,
        )

        return {
          id: user.id,
          name: user.name,
          username: user.username,
          alreadyConfiguredUser: !!alreadyConfiguredUser,
          permission: alreadyConfiguredUser
            ? alreadyConfiguredUser?.permission
            : CompanyUserPermissionTypeEnum.ANALISTA,
        } as TModalAddUserType
      })

    ShowModal({
      content: (
        <AddUserToCompanyModal
          users={modalUsers}
          companyId={companyId}
          handle={handleAddUsersToCompany}
          handleHideAndRefetchCompanies={handleHideAndRefetchCompanies}
        />
      ),
      title: 'Adicionar usuário',
      closeButton: false,
    })
  }

  const columns: GridColDef[] = [
    {
      ...ColumnsCommon,
      flex: 1.5,
      ...{
        field: 'name',
        headerName: 'Nome do cliente',
      },
      renderCell: (params) => `${params.row.name}`,
    },
    {
      ...ColumnsCommon,
      flex: 1,
      ...{
        field: 'plan',
        headerName: 'Plano contratado',
      },
      renderCell: (params) => {
        if (params.row.plan) {
          return params.row.plan.name
        }
        return 'Sem plano contratado'
      },
    },
    {
      ...ColumnsCommon,
      flex: 1,
      ...{
        field: 'status',
        align: 'center',
        headerName: 'Situação',
      },
      renderCell: (params) => (
        <Stack
          justifyContent="center"
          alignItems="center"
          width="62px"
          height="24px"
          gap="8px"
          padding="8px 16px 8px 16px"
          fontWeight="600"
          fontSize="12px"
          borderRadius="16px"
          marginTop="5px"
          sx={{
            backgroundColor:
              params.row.status === ICompanyStatusEnum.ACTIVE
                ? '#5ED1A2'
                : params.row.status === ICompanyStatusEnum.INACTIVE
                  ? '#FCD24C'
                  : 'red',
            color: '#F4F8FA',
          }}
        >
          {params.row.status === ICompanyStatusEnum.ACTIVE
            ? 'Ativo'
            : 'Inativo'}
        </Stack>
      ),
    },
    {
      ...ColumnsCommon,
      flex: 1,
      ...{
        field: 'date',
        headerName: 'Dia de vencimento',
      },
      renderCell: (params) => {
        if (params.row.subscription) {
          return `Dia ${params.row.subscription.info.invoiceDay}`
        }
        return '-'
      },
    },
    {
      ...ColumnsCommon,
      flex: 0.5,
      ...{
        field: 'id',
        headerName: 'Ações',
        align: 'left',
        renderCell: (params) => {
          const currentCompany = companies.find((f) => f.id === params.row.id)
          const ownerId = currentCompany?.owner.id ?? ''
          let hasPermission = true

          if (user.id !== ownerId) {
            const modalUserPermission = currentCompany?.users?.find(
              (obj) => obj.user.id === user.id,
            )?.permission

            if (modalUserPermission !== CompanyUserPermissionTypeEnum.MASTER) {
              hasPermission = false
            }
          }
          return (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              height="100%"
              gap="12px"
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                height="100%"
                sx={{
                  cursor: 'pointer',
                  color:
                    params.row.status !== ICompanyStatusEnum.ACTIVE
                      ? '#D3D3D3'
                      : '#4865CC',
                }}
                onClick={(e) => {
                  if (params.row.status !== ICompanyStatusEnum.ACTIVE) {
                    e.preventDefault()
                    return
                  }
                  e.preventDefault()
                  handleGoToDetails(params.row.id, params.row.name)
                }}
              >
                <LaunchIcon
                  sx={{
                    color:
                      params.row.status !== ICompanyStatusEnum.ACTIVE
                        ? '#D3D3D3'
                        : '#4865CC',
                    fill:
                      params.row.status !== ICompanyStatusEnum.ACTIVE
                        ? '#D3D3D3'
                        : '#4865CC',
                  }}
                />
              </Stack>

              {hasPermission && (
                <Stack
                  justifyContent="center"
                  height="100%"
                  alignItems="center"
                  sx={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.preventDefault()
                    handleAddUsersModal(params.row.id)
                  }}
                >
                  <UsersIcon />
                </Stack>
              )}
              {!hasPermission && (
                <Stack
                  justifyContent="center"
                  height="100%"
                  alignItems="center"
                  sx={{ cursor: 'not-allowed' }}
                >
                  <PersonAddDisabledOutlinedIcon
                    sx={{
                      color: '#D3D3D3',
                      fill: '#D3D3D3',
                    }}
                  />
                </Stack>
              )}
            </Stack>
          )
        },
      },
    },
  ]

  const handleAddUsersToCompany = async (
    companyId: string,
    users: {
      userId: string
      permission: CompanyUserPermissionTypeEnum
    }[],
  ) => {
    const data = {
      companyId,
      users,
      doNotDeleteCurrentUsers: false,
    } as TCompanyUserCreateDTO

    return await addUserToCompanyUseCase.handle(data)
  }

  const handleLeaveCompany = async () => {
    await Promise.all(
      selectedGridIds.map(async (item) => {
        return await leaveCompanyUseCase.handle({ companyId: item })
      }),
    )

    handleListUserCompaniesUsecase()
    HideModal()
  }

  const handleChangePlan = async (selectedPlan: string) => {
    if (!subscriptions) {
      return
    }
    setIsLoading(true)
    const subscriptionPlan = subscriptions?.plans?.find(
      (f) => f.plan.id === selectedPlan,
    )

    if (!subscriptionPlan) {
      return ShowToast('error', 'Nenhum plano selecionado.')
    }

    if (subscriptionPlan.licensesInUse + 1 > subscriptionPlan.licenses) {
      setIsLoading(false)
      return ShowToast(
        'error',
        'O número de licenças disponiveis do plano selecionado é inferior ao número de empresas selecionadas.',
      )
    }

    await Promise.all(
      selectedGridIds.map(async (item) => {
        return await editCompanySubscriptionPlanUseCase.handle({
          companyId: item,
          planId: subscriptionPlan.id,
          subscriptionId: subscriptions.id,
        })
      }),
    )

    ShowToast('success', 'Licenças alteradas com sucesso.')
    handleListUserSubscriptionsUseCase()
    handleListUserCompaniesUsecase()
    HideModal()
  }

  const handlePauseSubscription = async () => {
    let hasPermission = true
    await Promise.all(
      selectedGridIds.map((company) => {
        const currentCompany = companies.find((f) => f.id === company)
        const ownerId = currentCompany?.owner.id ?? ''

        if (user.id !== ownerId) {
          const modalUserPermission = currentCompany?.users?.find(
            (obj) => obj.user.id === user.id,
          )?.permission

          if (modalUserPermission !== CompanyUserPermissionTypeEnum.MASTER) {
            hasPermission = false
          }
        }
        return hasPermission
      }),
    )

    if (!hasPermission) {
      HideModal()
      return ShowToast(
        'error',
        'Você não possui a permissão necessária para executar esta ação em uma ou mais empresas selecionadas.',
      )
    }

    await Promise.all(
      selectedGridIds.map(async (item) => {
        return await pauseCompanySubscriptionPlanUseCase.handle({
          companyId: item,
        })
      }),
    )

    ShowToast('success', 'Processo finalizado com sucesso.')
    handleListUserSubscriptionsUseCase()
    handleListUserCompaniesUsecase()
    HideModal()
  }

  const handleOpenLeaveCompanyModal = () => {
    ShowModal({
      content: (
        <SimpleTextModal
          firstButtonText="Cancelar"
          secondButtonText="Sair"
          firstButtonAction={() => HideModal()}
          secondButtonAction={handleLeaveCompany}
          text="Deseja realmente sair da empresa?"
          height={100}
          width={295}
        />
      ),
      title: 'Sair da empresa.',
      closeButton: false,
    })
  }

  const handleOpenPauseSubscriptionModal = () => {
    ShowModal({
      content: (
        <SimpleTextModal
          firstButtonText="Cancelar"
          secondButtonText="Pausar"
          firstButtonAction={() => HideModal()}
          secondButtonAction={handlePauseSubscription}
          text="Deseja pausar a assinatura da(s) empresa(s) selecionada(s)?"
          height={130}
          width={325}
        />
      ),
      title: 'Pausar assinatura',
      closeButton: false,
    })
  }

  const handleOpenEditSubscriptionModal = async () => {
    if (!subscriptions) {
      return ShowToast(
        'error',
        'Você não possui nenhum plano contratado no momento.',
      )
    }

    let hasPermission = true
    await Promise.all(
      selectedGridIds.map((company) => {
        const currentCompany = companies.find((f) => f.id === company)
        const ownerId = currentCompany?.owner.id ?? ''

        if (user.id !== ownerId) {
          const modalUserPermission = currentCompany?.users?.find(
            (obj) => obj.user.id === user.id,
          )?.permission

          if (modalUserPermission !== CompanyUserPermissionTypeEnum.MASTER) {
            hasPermission = false
          }
        }
        return hasPermission
      }),
    )

    if (!hasPermission) {
      HideModal()
      return ShowToast(
        'error',
        'Você não possui a permissão necessária para executar esta ação em uma ou mais empresas selecionadas.',
      )
    }

    ShowModal({
      content: (
        <EditPlanSubscriptionPlanModal
          plans={plans?.map((item) => {
            let subscriptionLicenses = 0

            const subscriptionPlan = subscriptions?.plans?.find(
              (f) => f.plan.id === item?.id,
            )
            if (subscriptionPlan) {
              subscriptionLicenses =
                subscriptionPlan.licenses - subscriptionPlan.licensesInUse
            }

            return {
              ...item,
              subscription: {
                id: subscriptions?.id,
                licenses: subscriptionLicenses,
              },
            }
          })}
          handleGoToSupport={handleGoToSupport}
          handleChangePlan={handleChangePlan}
        />
      ),
      title: 'Recontratar / Trocar assinatura',
      closeButton: false,
    })
  }

  const handleAddMassUsers = async (
    users: {
      userId: string
    }[],
    permission: CompanyUserPermissionTypeEnum,
  ) => {
    const companies = selectedGridIds.map((mapItem) => {
      return {
        companyId: mapItem,
      }
    })

    await Promise.all(
      companies.map((company) => {
        const usersData = users.map((user) => {
          return {
            userId: user.userId,
            permission,
          }
        })

        const data = {
          companyId: company.companyId,
          users: usersData,
          doNotDeleteCurrentUsers: true,
        }
        return addUserToCompanyUseCase.handle(data)
      }),
    )
    handleListUserCompaniesUsecase()
    HideModal()
  }

  const handleAddMassUsersModal = async () => {
    const companiesSelected = selectedGridIds.map((mapItem) => {
      return {
        companyId: mapItem,
      }
    })

    let hasPermission = true
    await Promise.all(
      companiesSelected.map((company) => {
        const currentCompany = companies.find((f) => f.id === company.companyId)
        const ownerId = currentCompany?.owner.id ?? ''

        if (user.id !== ownerId) {
          const modalUserPermission = currentCompany?.users?.find(
            (obj) => obj.user.id === user.id,
          )?.permission

          if (modalUserPermission !== CompanyUserPermissionTypeEnum.MASTER) {
            hasPermission = false
          }
        }
        return hasPermission
      }),
    )

    if (!hasPermission) {
      ShowToast(
        'error',
        'Você não possui a permissão necessária para executar esta ação em uma ou mais empresas selecionadas.',
      )
    }

    const modalUsers = usersData.map((user) => {
      return {
        id: user.id,
        name: user.name,
        username: user.username,
      }
    })
    ShowModal({
      content: (
        <CompanyCreateUsersMassModal
          users={modalUsers}
          handle={handleAddMassUsers}
        />
      ),
      title: 'Adicionar usuário em massa',
      closeButton: false,
    })
  }

  const handleCreateCompany = () => {
    navigate(`/company/new`)
  }

  const handleFilterCompanies = async (
    planId: string,
    status: string,
    day: string,
  ) => {
    try {
      setIsLoading(true)
      const usecaseResult = await listCompaniesUseCase.handle({
        search: undefined,
        planId: ![null, undefined, ''].includes(planId) ? planId : undefined,
        status: ![null, undefined, ''].includes(status) ? status : undefined,
        paymentDay: ![null, undefined, ''].includes(day) ? day : undefined,
      })
      setIsLoading(false)

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.',
        )
      }

      const companiesData =
        usecaseResult.getValue() as TCompanyInfoResponse['companies'][]

      await UserInfo({
        id: user?.id,
        name: user?.name,
        companies: companiesData,
        companyName: user?.companyName,
        personType: user?.personType,
        cpfCnpj: user?.cpfCnpj,
        username: user?.username,
        termsOfUse: user?.termsOfUse,
        email: user?.email,
        backupCode: user?.backupCode,
        twoAuthEnabled: user?.twoAuthEnabled,
      })
      setCompanies(companiesData)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.',
      )
    }
  }

  const handleOpenFilterModal = () => {
    ShowModal({
      content: (
        <FilterModal
          day={filterDay}
          planOptions={plans.map((plan) => {
            return {
              label: plan.name,
              value: plan.id,
            }
          })}
          selectedPlanOptions={filterPlans}
          statusOptions={[
            {
              label: 'Ativo',
              value: ICompanyStatusEnum.ACTIVE,
            },
            {
              label: 'Pausado',
              value: ICompanyStatusEnum.INACTIVE,
            },
          ]}
          selectedStatus={filterStatus}
          handle={handleFilterCompanies}
          setFilterDay={setFilterDay}
          setFilterPlans={setFilterPlans}
          setFilterStatus={setFilterStatus}
        />
      ),
      closeButton: true,
      title: 'Filtrar por',
      position: ModalPositionEnum.right,
    })
  }

  return {
    usersData,
    filter,
    searchQuery,
    columns,
    companies,
    isLoading,
    selectedGridIds,
    setSearchQuery,
    setSelectedGridIds,
    setFilter,
    setUsersData,
    setIsLoading,
    handleAddUsersToCompany,
    setCompanies,
    handleListUserCompaniesUsecase,
    handleListUserCompanies,
    handleListUsers,
    handleOpenLeaveCompanyModal,
    handleOpenPauseSubscriptionModal,
    handleCreateCompany,
    handleOpenFilterModal,
    handleAddMassUsersModal,
    handleListPlansUseCase,
    handleOpenEditSubscriptionModal,
    handleListUserSubscriptionsUseCase,
  }
}
