import { Button, CircularProgress, Stack } from '@mui/material'
import { useState } from 'react'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { PlanCard } from 'src/component/plan/card'
import { variables, variablesContent } from 'src/component/plan/list-plans'

export const EditPlanSubscriptionPlanModal: React.FC<{
  plans: {
    id: string
    name: string
    value: string
    subscription: {
      id: string | undefined
      licenses: number | null
    }
  }[]
  handleGoToSupport: () => void
  handleChangePlan: (selectedPlan: string) => void
}> = ({ plans, handleGoToSupport, handleChangePlan }) => {
  const { HideModal } = ModalFullContext()
  const [selectedPlan, setSelectedPlan] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const handleChange = () => {
    setLoading(true)
    handleChangePlan(selectedPlan)
    setLoading(false)
  }

  return (
    <Stack width="100%" height="100%" maxHeight="70vh">
      <Stack
        width="100%"
        direction="row"
        gap="32px"
        overflow="auto"
        paddingBottom="12px"
        sx={{
          flexWrap: 'wrap',
          '& > .MuiPaper-root': {
            width: 'calc(50% - 8px)',
            minWidth: '150px',
            marginBottom: '16px',
          },
        }}
      >
        {plans
          .sort(
            (a, b) =>
              (b.subscription?.licenses ?? 0) - (a.subscription?.licenses ?? 0),
          )
          .map((plan, index) => {
            return (
              <PlanCard
                id={plan.id}
                title={plan.name}
                key={index}
                selectionButtonAction={setSelectedPlan}
                variables={variables}
                data={
                  variablesContent.find((f) => f.plan === plan.name)?.content
                }
                selectedPlan={selectedPlan}
                availableLicenses={plan.subscription?.licenses ?? 0}
                navigateToSuport={handleGoToSupport}
              />
            )
          })}
      </Stack>
      <Stack
        direction="row"
        gap="16px"
        width="100%"
        height="100px"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => HideModal()}
        >
          Cancelar
        </Button>
        {!loading && (
          <Button
            fullWidth={false}
            variant="contained"
            disabled={selectedPlan === ''}
            onClick={() => handleChange()}
          >
            Finalizar
          </Button>
        )}
        {loading && (
          <CircularProgress
            sx={{
              justifySelf: 'center',
              justifyItems: 'center',
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}
