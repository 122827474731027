import { CompanyApiRemoteService } from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class AccountingEntryDismemberUseCase
  implements
    IUsecase<
      {
        data: {
          value: string
          partieName: string
          description: string
        }[]
        companyId: string
        entryId: string
      },
      void
    >
{
  constructor(private companyApi: CompanyApiRemoteService) {}

  public async handle({
    data,
    companyId,
    entryId,
  }: {
    data: {
      value: string
      partieName: string
      description: string
    }[]
    companyId: string
    entryId: string
  }): Promise<Result<void>> {
    try {
      // eslint-disable-next-line prettier/prettier
        const response = await this.companyApi.accountingEntryDismeber({
        data,
        companyId,
        entryId,
      })

      if (response.isFailure) {
        return Result.fail(new Error('Erro ao criar o registro.'))
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
