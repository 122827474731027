import { IBankAccountTransfer } from 'src/service/service-bank'

export function generateOFX(
  data: IBankAccountTransfer[],
  actualBalance: number,
): string {
  const header = `OFXHEADER:100
DATA:OFXSGML
VERSION:102
SECURITY:NONE
ENCODING:UTF-8
CHARSET:NONE
COMPRESSION:NONE
OLDFILEUID:NONE
NEWFILEUID:NONE
`

  const now = new Date()
  const formattedNow =
    now
      .toISOString()
      .replace(/[-:.T]/g, '')
      .slice(0, 14) + '[-3:BRT]'

  const bankCode = data[0].bankAccount.bankNumber
  const bankName = data[0].bankAccount.bank
  const accountNumber = data[0].bankAccount.accountNumber
  const accountType = data[0].bankAccount.accountType

  const sortedTransactions = data.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  )
  const firstTransactionDate = sortedTransactions[0].date
  const lastTransactionDate =
    sortedTransactions[sortedTransactions.length - 1].date

  const formattedStartDate =
    new Date(firstTransactionDate)
      .toISOString()
      .replace(/[-:.T]/g, '')
      .slice(0, 14) + '[-3:BRT]'
  const formattedEndDate =
    new Date(lastTransactionDate)
      .toISOString()
      .replace(/[-:.T]/g, '')
      .slice(0, 14) + '[-3:BRT]'

  const transactions = data
    .map((transaction) => {
      const formattedDate =
        new Date(transaction.date)
          .toISOString()
          .replace(/[-:.T]/g, '')
          .slice(0, 14) + '[-3:BRT]'
      const trnAmt =
        transaction.type === 'DEBIT'
          ? `-${Number(transaction.amount).toFixed(2)}`
          : `${Number(transaction.amount).toFixed(2)}`
      return `<STMTTRN>
                <TRNTYPE>${transaction.type === 'DEBIT' ? 'DEBIT' : 'CREDIT'}</TRNTYPE>
                <DTPOSTED>${formattedDate}</DTPOSTED>
                <TRNAMT>${trnAmt}</TRNAMT>
                <FITID>${transaction.externalId || transaction.id}</FITID>
                <MEMO>${transaction.transferCompleteDescription}</MEMO>
              </STMTTRN>`
    })
    .join('\n')

  const body = `<OFX>
<SIGNONMSGSRSV1>
  <SONRS>
    <STATUS>
      <CODE>0</CODE>
      <SEVERITY>INFO</SEVERITY>
    </STATUS>
    <DTSERVER>${formattedNow}</DTSERVER>
    <LANGUAGE>POR</LANGUAGE>
    <FI>
      <ORG>${bankName}</ORG>
      <FID>${bankCode}</FID>
    </FI>
  </SONRS>
</SIGNONMSGSRSV1>
<BANKMSGSRSV1>
  <STMTTRNRS>
    <TRNUID>1</TRNUID>
    <STATUS>
      <CODE>0</CODE>
      <SEVERITY>INFO</SEVERITY>
    </STATUS>
    <STMTRS>
      <CURDEF>BRL</CURDEF>
      <BANKACCTFROM>
        <BANKID>${bankCode}</BANKID>
        <ACCTID>${accountNumber}</ACCTID>
        <ACCTTYPE>${accountType}</ACCTTYPE>
      </BANKACCTFROM>
      <BANKTRANLIST>
        <DTSTART>${formattedStartDate}</DTSTART>
        <DTEND>${formattedEndDate}</DTEND>
        ${transactions}
      </BANKTRANLIST>
      <LEDGERBAL>
        <BALAMT>${actualBalance.toFixed(2)}</BALAMT>
        <DTASOF>${formattedNow}</DTASOF>
      </LEDGERBAL>
    </STMTRS>
  </STMTTRNRS>
</BANKMSGSRSV1>
</OFX>`

  return header + body
}
