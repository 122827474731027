import {
  CompanyApiRemoteService,
  TCompanyCreateDTO,
} from 'src/service/service-company'
import {
  SubscriptionApiRemoteService,
  TSubscriptionCreateDTO,
} from 'src/service/service-subscription'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, string } from 'yup'

export class CreateSubscriptionUseCase
  implements
    IFormUsecase<
      {
        data: TSubscriptionCreateDTO
        userId: string
      },
      void
    >
{
  constructor(private repository: SubscriptionApiRemoteService) {}

  public async handle({
    data,
    userId,
  }: {
    data: TSubscriptionCreateDTO
    userId: string
  }): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.createSubscription(userId, data)

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    name: string().required('Nome é obrigatório'),
    cnpj: string().required('CNPJ é obrigatório'),
  })
}
