import { Stack, Typography } from '@mui/material'

export type AccountTypeCardProps = {
  id: number
  title: string
  handleSelect: () => void
  imageUrl: string
  disabled?: boolean
}

export const AccountTypeCard = ({
  id,
  title,
  handleSelect,
  imageUrl,
  disabled,
}: AccountTypeCardProps) => {
  const handleSelectAccountType = () => {
    if (disabled) return

    return handleSelect()
  }

  return (
    <Stack
      key={`account-type-card-${id}`}
      width="160px"
      height="160px"
      borderRadius="8px"
      gap="12px"
      alignItems="center"
      justifyContent="center"
      padding="12px"
      onClick={handleSelectAccountType}
      boxShadow="0px 4px 4px 0px #00000040"
      sx={{
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        opacity: disabled ? '0.6' : 1,
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        width="64px"
        height="64px"
      >
        <img src={imageUrl} className="App-logo" alt={title} />
      </Stack>
      <Typography
        display="flex"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        fontWeight="400"
        fontSize="16px"
        width="100px"
        flexWrap="wrap"
      >
        {title}
      </Typography>
    </Stack>
  )
}
