import { Button, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BaseRadio } from 'src/component/base-component/base-radio'
// import { ModalFullContext } from 'src/component/modal/modal-provider'
// import { SimpleTextModal } from 'src/component/modal/simple-modal-text'

interface CreateAccountPageProps {
  handleContinue: (isOpenFinance: boolean) => void
}

export function CreateSystemicAccountStepTwoPage(
  props: CreateAccountPageProps,
) {
  const { companyId } = useParams()
  const navigate = useNavigate()
  // const { ShowModal, HideModal } = ModalFullContext()
  const [value, setValue] = useState<string>('YES')

  const handleGoToNextStep = () => {
    props.handleContinue && props.handleContinue(value === 'YES')
  }

  const handleCancel = () => {
    navigate(`/company/${companyId}/account/list`)
  }

  // const handleOpenModalOpenFinanceDisabled = () => {
  //   ShowModal({
  //     content: (
  //       <SimpleTextModal
  //         firstButtonText="Upgrade"
  //         secondButtonText="Continuar"
  //         firstButtonAction={() => HideModal()}
  //         secondButtonAction={() => HideModal()}
  //         text="Seu plano não inclui a conexão com Open Finance. Clique em continuar para seguir sem ou faça upgrade do seu plano agora mesmo."
  //         height={180}
  //         width={320}
  //       />
  //     ),
  //     title: 'Conexão Open Finance',
  //     closeButton: false,
  //   })
  // }

  // const handleOpenModalLimit = () => {
  //   ShowModal({
  //     content: (
  //       <SimpleTextModal
  //         firstButtonText="Sair"
  //         secondButtonText="Alterar plano"
  //         firstButtonAction={() => HideModal()}
  //         secondButtonAction={() => HideModal()}
  //         text="Atenção! Você excedeu o limite de contas disponíveis em seu plano."
  //         height={140}
  //         width={275}
  //       />
  //     ),
  //     title: 'Limite excedido',
  //     closeButton: false,
  //   })
  // }

  return (
    <Stack
      width="100%"
      height="100%"
      maxWidth="780px"
      sx={{ backgroundColor: '#F4F8FA', overflow: 'auto' }}
      gap="24px"
      marginTop="32px"
    >
      <Typography
        fontWeight="700"
        fontSize="24px"
        lineHeight="32px"
        color="#1E1E1E"
      >
        Agora, uma pergunta muito importante: a sua nova conta terá integrações
        via <b style={{ color: '#2197BC' }}>Open Finance?</b>
      </Typography>

      <Stack direction="column" gap="24px">
        <Typography fontWeight="700" fontSize="16px" color="#1E1E1E">
          Mas o que isso significa?
        </Typography>

        <Typography fontWeight="400" fontSize="16px" color="#1E1E1E">
          Nossos sistemas permitem que seja feita conexão direta com a
          instituição bancária da sua escolha, para que os dados do extrato
          sejam lançados automaticamente aqui na plataforma, de modo prático, e
          o mais importante, seguro!
        </Typography>

        <Typography fontWeight="400" fontSize="16px" color="#1E1E1E">
          Claro, caso você não queira, ou descubra que o seu banco não possui
          integrações conosco, pode também importar o seu extrato manualmente.
        </Typography>

        <BaseRadio
          setState={(e) => setValue(e)}
          value={value}
          groupStyle={{
            display: 'flex',
            maxWidth: '780px',
            flexDirection: 'column',
            gap: '16px',
          }}
          options={[
            {
              label:
                'Sim, gostaria de aproveitar os benefícios que o Open Finance oferece!',
              value: 'YES',
            },
            {
              label:
                'Não, farei a importação do extrato desta conta de forma manual.',
              value: 'NO',
            },
          ]}
        />

        <Stack
          width="100%"
          justifyContent="flex-end"
          alignItems="center"
          direction="row"
          gap="32px"
        >
          <Button
            fullWidth={false}
            variant="text"
            onClick={handleCancel}
            sx={{
              padding: '12px 24px 12px 16px',
              gap: '8px',
              width: '110px',
              height: '40px',
            }}
          >
            Cancelar
          </Button>
          <Button
            fullWidth={false}
            variant="contained"
            onClick={handleGoToNextStep}
            sx={{
              padding: '12px 24px 12px 16px',
              gap: '8px',
              width: '120px',
              height: '40px',
            }}
          >
            Continuar
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
