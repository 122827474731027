import { Box } from '@mui/material'
import styled from 'styled-components'

export const CompanySection = styled(Box)`
  width: 100%;
  border-bottom: dotted 1px #777c84;
  padding-bottom: 15px;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0px;
  }
`
