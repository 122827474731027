import { ArrowBack, EditOutlined } from '@mui/icons-material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInputCode } from 'src/component/base-component/base-input-code'
import { BaseInternalHeader } from 'src/component/base-component/base-internal-header'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { BaseSwitch } from 'src/component/base-component/base-switch'
import { DefaultHeader } from 'src/component/header/header'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { EnableProfile2FAModal } from 'src/component/modal/profile/modal-enable-profile-2fa'
import { UserAvatar } from 'src/component/sidebar/styles'
import { CreateForm } from 'src/shared/util/form/form-util'
import { RecoveryEmailUseCase } from 'src/usecase/authentication/usecase-authentication-form-recovery-email'
import { Recovery2FAFinishUseCase } from 'src/usecase/authentication/usecase-authentication-form-recovery-finish-2fa'
import { Recovery2FAInitUseCase } from 'src/usecase/authentication/usecase-authentication-form-recovery-init-2fa'
import { UpdateProfileUseCase } from 'src/usecase/user/usecase-profile-update'
import { UserGetProfileUseCase } from 'src/usecase/user/usecase-user-profile'
import { getInitials } from 'src/utils/stringFormatterUtils'

interface UpdateAccountPageProps {
  userGetProfileUseCase: UserGetProfileUseCase
  updateProfileUseCase: UpdateProfileUseCase
  recoveryEmailUseCase: RecoveryEmailUseCase
  useCaseInit: Recovery2FAInitUseCase
  useCaseFinish: Recovery2FAFinishUseCase
}

export function UserProfilePage(props: UpdateAccountPageProps) {
  const navigate = useNavigate()
  const theme = useTheme()
  const { ShowToast } = ToastFullContext()
  const { ShowModal, HideModal } = ModalFullContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sendEmailEnabled, setSendEmailEnabled] = useState<boolean>(false)
  const [twoAuthEnabled, setTwoAuthEnabled] = useState<boolean>(false)
  const [backupcode, setBackupcode] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [isPasswordType, setIsPasswordType] = useState<boolean>(true)
  const [isConfirmPasswordType, setIsConfirmPasswordType] =
    useState<boolean>(true)

  const updateForm = CreateForm({
    fields: ['name', 'username', 'email', 'password', 'oldPassword'],
    validation: props.updateProfileUseCase.validation,
  })

  const handleGetData = async () => {
    setIsLoading(true)
    const profileResult = await props.userGetProfileUseCase.handle()
    setIsLoading(false)

    if (profileResult.isFailure) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar o perfil. Tente novamente mais tarde.',
      )
    }

    const profile = profileResult.getValue()
    if (!profile) return

    updateForm.fields.name.setState(() => profile.name)
    updateForm.fields.username.setState(() => profile.username)
    updateForm.fields.email.setState(() => profile.email)

    setTwoAuthEnabled(profile.twoAuthEnabled)
    setSendEmailEnabled(!!profile.email)
    setBackupcode(profile.backupCode)
    setUserName(profile.name)
  }

  const handleGetQRCodeUsecase = async () => {
    try {
      const result = await props.useCaseInit.handle()

      if (result.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
        )
      }

      const response = result.getValue()

      if (
        typeof response?.qrcode !== 'string' ||
        typeof response?.token !== 'string'
      ) {
        ShowToast(
          'error',
          'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
        )
        return
      }

      return response
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
      )
    }
  }

  const handleFinish2FAUsecase = async (secret: string, token: string) => {
    try {
      if (!secret) {
        ShowToast(
          'error',
          'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
        )
        HideModal()
        return
      }

      const result = await props.useCaseFinish.handle({
        secret,
        token,
      })

      if (result.isFailure) {
        switch ((result?.error as any)?.type) {
          case 'UserTwoAuthRecoveryInvalidCodeError':
            ShowToast(
              'error',
              'O código inserido está incorreto. Tente novamente.',
            )
            break

          default:
            ShowToast(
              'error',
              'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
            )
            break
        }

        return
      }

      ShowToast(
        'success',
        'Autenticação de 2FA como método de recuperação salva com sucesso',
      )
      HideModal()
      handleGetData()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
      )
    }
  }

  const handleShowModal2FA = () => {
    ShowModal({
      content: (
        <EnableProfile2FAModal
          handleConfirmAction={handleFinish2FAUsecase}
          handleCancelAction={() => HideModal()}
          handleGenerateNewQrCode={() => handleGetQRCodeUsecase()}
          width={550}
          height={600}
        />
      ),
      title: '',
      closeButton: false,
    })
  }

  useEffect(() => {
    handleGetData()
  }, [props])

  const handleUpdateProfile = async () => {
    setIsLoading(true)
    const data = {
      name: updateForm.fields.name.value,
      username: updateForm.fields.username.value,
      email: updateForm.fields.email.value ?? '',
      password: updateForm.fields.password.value,
      oldPassword: updateForm.fields.oldPassword.value,
      twoAuthEnabled,
    }
    const updateData = await props.updateProfileUseCase.handle(data)
    if (updateData.isFailure) {
      let message =
        'Ocorreu um erro ao efetuar a atualização. Tente novamente mais tarde.'

      if (updateData.error?.error === 'User has invalid password') {
        message = 'Senha atual inválida'
      }

      ShowToast('error', message)
    } else {
      ShowToast('success', 'Dados atualizados com sucesso.')
      updateForm.fields.password.setState(() => '')
      updateForm.fields.oldPassword.setState(() => '')
    }
    setIsLoading(false)
  }

  const handleValidate = async () => {
    try {
      const validationResult = await updateForm.validate()
      if (validationResult.isFailure) {
        ShowToast('error', 'Verifique todos os campos')
        return
      }
      handleUpdateProfile()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a atualização. Tente novamente mais tarde.',
      )
    }
  }

  const handleCancel = () => {
    navigate(`/home`)
  }

  return (
    <Stack width="100%" height="100%" sx={{ backgroundColor: '#F4F8FA' }}>
      <DefaultHeader
        breadcumbItems={[
          { title: 'Painel multiempresas', navigateTo: '/home' },
          { title: 'Minha conta' },
        ]}
      />

      <Stack
        width="100%"
        height="calc(100% - 130px)"
        direction="row"
        gap="32px"
        overflow="hidden"
      >
        {isLoading && (
          <Stack
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Stack>
        )}

        {!isLoading && (
          <Stack
            width="100%"
            maxWidth="1024px"
            padding="32px"
            height="100%"
            gap="24px"
            direction="column"
          >
            <BaseInternalHeader
              leftComponent={
                <Button
                  variant="text"
                  startIcon={<ArrowBack />}
                  onClick={() => navigate('/home')}
                >
                  Voltar
                </Button>
              }
            />
            <Stack
              width="100%"
              justifyContent="space-between"
              direction="row"
              alignItems="center"
              alignContent="center"
            >
              <Typography fontWeight="700" fontSize="32px" color="#1E1E1E">
                Minha conta
              </Typography>
            </Stack>

            <Stack width="100%" gap="24px" direction="row">
              <Stack alignItems="center" justifyContent="center">
                <UserAvatar
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50px',
                    fontSize: '32px',
                  }}
                >
                  {getInitials(userName)}
                </UserAvatar>
              </Stack>
              <Stack width="100%" gap="32px" direction="column">
                <Stack width="100%" gap="24px" direction="row">
                  <BaseInput
                    label="Nome"
                    fullWidth
                    iconEnd={
                      <EditOutlined
                        style={{ color: theme.palette.primary.main }}
                      />
                    }
                    {...updateForm.fields.name}
                  />
                  <BaseInput
                    label="Email"
                    fullWidth
                    {...updateForm.fields.email}
                    setState={(e) => {
                      updateForm.fields.email.setState(() => e)
                      if (e) {
                        setSendEmailEnabled(true)
                      } else {
                        setSendEmailEnabled(false)
                      }
                    }}
                    iconEnd={
                      <EditOutlined
                        style={{ color: theme.palette.primary.main }}
                      />
                    }
                  />
                  <BaseInput
                    label="Username"
                    fullWidth
                    {...updateForm.fields.username}
                    iconStart={'@'}
                    iconEnd={
                      <EditOutlined
                        style={{ color: theme.palette.primary.main }}
                      />
                    }
                  />
                </Stack>
                <Stack width="100%" gap="24px" direction="row">
                  <BaseInput
                    label="Senha atual"
                    type={isPasswordType ? 'password' : 'text'}
                    fullWidth
                    {...updateForm.fields.oldPassword}
                    iconEnd={
                      <Box
                        onClick={() => setIsPasswordType(!isPasswordType)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <VisibilityOutlinedIcon
                          style={{ color: theme.palette.primary.main }}
                        />
                      </Box>
                    }
                  />
                  <BaseInput
                    label="Senha"
                    type={isConfirmPasswordType ? 'password' : 'text'}
                    fullWidth
                    {...updateForm.fields.password}
                    iconEnd={
                      <Box
                        onClick={() =>
                          setIsConfirmPasswordType(!isConfirmPasswordType)
                        }
                        sx={{ cursor: 'pointer' }}
                      >
                        <VisibilityOutlinedIcon
                          style={{ color: theme.palette.primary.main }}
                        />
                      </Box>
                    }
                  />
                </Stack>
              </Stack>
            </Stack>

            <Stack
              width="100%"
              gap="24px"
              direction="row"
              justifyContent="space-between"
            >
              <Stack
                width="calc(50% - 24px)"
                borderRadius="16px"
                padding="24px"
                gap="32px"
                sx={{ backgroundColor: theme.palette.common.white }}
              >
                <Typography fontWeight="700" fontSize="24px" color="#1E1E1E">
                  Métodos de segurança
                </Typography>
                <BaseSwitch
                  checked={sendEmailEnabled}
                  setState={(value) => {
                    if (!value) {
                      updateForm.fields.email.setState(() => '')
                      return setSendEmailEnabled(false)
                    }

                    return setSendEmailEnabled(value)
                  }}
                  disabled={!updateForm.fields.email.value}
                  error={null}
                  label="Enviar para email cadastrado"
                />
                <BaseSwitch
                  checked={twoAuthEnabled}
                  setState={(value) => {
                    if (!value) {
                      return setTwoAuthEnabled(false)
                    }

                    return handleShowModal2FA()
                  }}
                  error={null}
                  label="Autenticação em 2 etapas"
                />
              </Stack>

              <Stack
                width="calc(50% - 24px)"
                borderRadius="16px"
                padding="24px"
                gap="32px"
                sx={{ backgroundColor: theme.palette.common.white }}
              >
                <Typography fontWeight="700" fontSize="24px" color="#1E1E1E">
                  PIN de acesso
                </Typography>
                <BaseInputCode
                  length={6}
                  value={backupcode}
                  setState={() => console.log('ok')}
                  error={null}
                  textColor={'#5ED1A2'}
                />
              </Stack>
            </Stack>

            <Stack
              width="100%"
              maxWidth="1024px"
              justifyContent="flex-end"
              alignItems="center"
              direction="row"
              gap="32px"
            >
              <Button
                fullWidth={false}
                onClick={handleCancel}
                variant="outlined"
                sx={{
                  padding: '12px 24px 12px 16px',
                  gap: '8px',
                  width: '110px',
                  height: '40px',
                }}
              >
                Cancelar
              </Button>
              <Button
                fullWidth={false}
                variant="contained"
                onClick={handleValidate}
                sx={{
                  padding: '12px 24px 12px 16px',
                  gap: '8px',
                  width: '180px',
                  height: '40px',
                }}
              >
                {isLoading ? <CircularProgress /> : 'Salvar alterações'}
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
