import { Box, Button, CircularProgress, Stack } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { useState } from 'react'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInputMoney } from 'src/component/base-component/base-input-money'
import { ICompanyAccountEntriesResponse } from 'src/service/service-company'

export const EditConciliatorEntryModal: React.FC<{
  data: ICompanyAccountEntriesResponse | null
  handleConfirmAction: (data: Partial<ICompanyAccountEntriesResponse>) => void
  handleCancelAction: () => void
  buttonTitle: string
}> = ({
  data,
  handleConfirmAction,
  handleCancelAction,
  buttonTitle = 'Criar lançamento',
}) => {
  const [financialCategory, setFinancialCategory] = useState<string>(
    data?.financialCategory ?? '',
  )
  const [debit, setDebit] = useState<string>(data?.debit ?? '')
  const [credit, setCredit] = useState<string>(data?.credit ?? '')
  const [bankTransferDate, setBankTransferDate] = useState<string>(
    data?.bankTransferDate
      ? format(parseISO(data?.bankTransferDate.toString()), 'yyyy-MM-dd')
      : '',
  )
  const [value, setValue] = useState<string>(data?.value ?? '')
  const [loading, setLoading] = useState(false)

  const handleConfirm = async () => {
    let newValue = data?.value ?? ''

    if (value !== '') {
      newValue = (Number(value?.replace(/[^\d]/g, '')) / 100).toString()
    }
    handleConfirmAction &&
      handleConfirmAction({
        id: data?.id as string,
        debit,
        credit,
        bankTransferDate,
        value: newValue,
      })
  }

  const handleCancel = () => {
    setFinancialCategory('')
    setDebit('')
    setCredit('')
    setBankTransferDate('')
    setValue('')
    setLoading(false)
    handleCancelAction && handleCancelAction()
  }

  return (
    <Stack width="100%" height="100%" gap="32px">
      <Box
        sx={{
          marginTop: '24px',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridAutoRows: 'max-content',
            gap: '24px',
            width: '440px',
          }}
        >
          <Stack width="100%" gap="24px" alignItems="center" direction="row">
            <BaseInput
              label="Cat. financeira"
              fullWidth
              value={financialCategory}
              setState={(e) => setFinancialCategory(e)}
              error={null}
            />
            <BaseInput
              label="Conta débito"
              fullWidth
              value={debit}
              setState={(e) => setDebit(e)}
              error={null}
            />
            <BaseInput
              label="Conta Crédito"
              fullWidth
              value={credit}
              setState={(e) => setCredit(e)}
              error={null}
            />
          </Stack>
          <Stack width="100%" gap="24px" alignItems="center" direction="row">
            <BaseInput
              label="Data"
              fullWidth
              type="date"
              value={bankTransferDate}
              setState={(e) => setBankTransferDate(e)}
              error={null}
            />
            <BaseInputMoney
              label="Valor"
              fullWidth
              value={value}
              setState={(e) => setValue(e)}
              error={null}
            />
          </Stack>
        </Box>
      </Box>
      <Stack
        direction="row"
        gap="16px"
        width="100%"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => handleCancel()}
        >
          Cancelar
        </Button>

        {!loading && (
          <Button
            fullWidth={false}
            variant="contained"
            onClick={() => handleConfirm()}
          >
            {buttonTitle}
          </Button>
        )}
        {loading && (
          <CircularProgress
            sx={{
              justifySelf: 'center',
              justifyItems: 'center',
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}
