import { CompanyApiRemoteService } from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IFormUsecase } from 'src/shared/protocol/protocol-form-usecase'
import { object, string } from 'yup'

export class PauseCompanySubscriptionPlanUseCase
  implements
    IFormUsecase<
      { companyId: string; subscriptionId: string; planId: string },
      void
    >
{
  constructor(private repository: CompanyApiRemoteService) {}

  public async handle({
    companyId,
  }: {
    companyId: string
  }): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.pauseCompanySubscriptionPlan({
        companyId,
      })

      if (response.isFailure) {
        return response
      }
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    companyId: string().required('Empresa é obrigatória'),
  })
}
