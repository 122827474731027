import { Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { variables, variablesContent } from 'src/component/plan/list-plans'
import { PlanCardSubscription } from 'src/component/plan/subscription-card'

export type PlanListSubscriptiionProps = {
  plans?: {
    id: string | null
    name: string | null
    value: string | null
    selection?: {
      id: string | null
      licenses: number | null
      alreadyExistPlanLicenses?: number
    }
  }[]
}

export const ResumeSubscription = ({
  plans = [],
}: PlanListSubscriptiionProps) => {
  return (
    <Stack
      width="100%"
      height="100%"
      direction="row"
      gap="32px"
      overflow="auto"
      paddingBottom="12px"
      sx={{
        flexWrap: 'wrap',
        '& > .MuiPaper-root': {
          width: 'calc(50% - 8px)',
          minWidth: '150px',
          marginBottom: '16px',
        },
      }}
    >
      {plans.map((plan, index) => {
        const selectionArray = plan.selection
          ? [
              {
                id: plan.selection.id,
                licenses: plan.selection.licenses ?? 0,
              },
            ]
          : []

        return (
          <PlanCardSubscription
            id={plan.id ?? ''}
            title={plan.name ?? ''}
            value={plan.value ?? ''}
            key={index}
            variables={variables}
            data={variablesContent.find((f) => f.plan === plan.name)?.content}
            selection={selectionArray}
            availableLicenses={plan.selection?.licenses ?? 0}
            isPlanSelectable={plan.name !== 'Enterprise'}
            hideSelection={true}
          />
        )
      })}
    </Stack>
  )
}
