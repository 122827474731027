import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BaseInput } from 'src/component/base-component/base-input'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { DefaultHeader } from 'src/component/header/header'
import { PlanListSubscription } from 'src/component/plan/list-plans-subscription'
import { VerticalStepper } from 'src/component/vertical-stepper/vertical-stepper'
import { ICompanyPlan, ISubscription } from 'src/service/service-company'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'
import { ListCompanyPlansUseCase } from 'src/usecase/company/usecase-company-plans-list'
import { ListUserSubscriptionsPlansUseCase } from 'src/usecase/company/usecase-company-subscriptions'
import { CreateSubscriptionUseCase } from 'src/usecase/company/usecase-subscription-create'
import BlueHeader from '../../assets/background/header-blue.png'
import { UserFullContext } from 'src/context/context-user'
import { ResumeSubscription } from 'src/component/plan/selected-plans-resume'
import { BasePageSubtitle } from 'src/component/base-component/base-page-subtitle'
import { BaseRadio } from 'src/component/base-component/base-radio'

interface CreateSubscriptionPageProps {
  useCase: CreateSubscriptionUseCase
  listCompanyPlansUseCase: ListCompanyPlansUseCase
  listUserSubscriptionsPlansUseCase: ListUserSubscriptionsPlansUseCase
}

export function CreateSubscriptionPage({
  useCase,
  listCompanyPlansUseCase,
  listUserSubscriptionsPlansUseCase,
}: CreateSubscriptionPageProps): any {
  const navigate = useNavigate()
  const { ShowToast } = ToastFullContext()
  const { GetUserData } = UserFullContext()
  const user = GetUserData()
  const theme = useTheme()

  const [currentStep, setCurrentStep] = useState(0)
  const [plans, setPlans] = useState<ICompanyPlan[]>([])
  const [subscriptions, setSubscriptions] = useState<ISubscription>()
  const [selectedPlan, setSelectedPlan] = useState<
    { id: string | null; licenses: number }[]
  >([])
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [cep, setCep] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [complement, setComplemnent] = useState<string>('')
  const [neighborhood, setNeighborhood] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [cardNumber, setCardNumber] = useState<string>('')
  const [holderName, setHolderName] = useState<string>('')
  const [month, setMonth] = useState<string>('')
  const [year, setYear] = useState<string>('')
  const [cvv, setCvv] = useState<string>('')
  const [discountCode, setDiscountCode] = useState<string>('')
  const [cpfCnpj, setCpfCnpj] = useState<string>('')
  const [personType, setPersonType] = useState<string>('')
  const [userName, setUserName] = useState<string>('')

  const [firstPlan, setFirstPlan] = useState<boolean>(true)
  const [selectedPlans, setSelectedPlans] = useState<
    { id: string | null; licenses: number; name: string; value: string }[]
  >([])

  const handleListPlansUseCase = async () => {
    try {
      setLoading(true)
      const plansResult = await listCompanyPlansUseCase.handle()

      if (plansResult.isFailure) {
        setLoading(false)
        return ShowToast(
          'error',
          'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
        )
      }

      const plansData = plansResult.getValue()

      if (plansData === null) {
        setLoading(false)
        return ShowToast(
          'error',
          'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
        )
      }

      const selectedPlans = plansData?.map((plan) => {
        return {
          id: plan.id,
          licenses: 0,
          name: plan.name,
          value: plan.value,
        }
      })

      setSelectedPlans(selectedPlans)
      setPlans(plansData as ICompanyPlan[])
      setLoading(false)
    } catch (error) {
      setLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
      )
    }
  }

  const handleListUserSubscriptionsUseCase = async () => {
    try {
      setLoading(true)
      const plansResult = await listUserSubscriptionsPlansUseCase.handle()

      if (plansResult.isFailure) {
        setLoading(false)
        return ShowToast(
          'error',
          'Ocorreu um erro ao listar os planos já contratados. Tente novamente mais tarde.',
        )
      }

      const plansData = plansResult.getValue()

      setSubscriptions(plansData as ISubscription)
      setFirstPlan(plansData === null)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao listar os planos de contratação. Tente novamente mais tarde.',
      )
    }
  }

  useEffect(() => {
    handleListPlansUseCase()
    handleListUserSubscriptionsUseCase()
  }, [])

  const handleStepOneSelectPlans = (
    selection: { id: string | null; licenses: number }[],
  ) => {
    const newSelectedPlans = selectedPlans.map((plan) => {
      const selectedPlan = selection.find((f) => f.id === plan.id)
      if (selectedPlan) {
        plan.licenses = selectedPlan.licenses
      }
      return plan
    })

    setSelectedPlan(newSelectedPlans)

    if (!selection || selection?.length === 0) {
      return ShowToast('error', 'Nenhum plano informado.')
    }
  }

  const handleStepOneSuccess = async () => {
    if (!selectedPlan || selectedPlan?.length === 0) {
      return ShowToast('error', 'Nenhum plano informado.')
    }

    let totalLicenses = 0
    selectedPlan.map((item) => {
      totalLicenses += item.licenses
      return item
    })

    if (totalLicenses === 0) {
      return ShowToast('error', 'Selecione ao menos um plano.')
    }

    if (totalLicenses < 10 && firstPlan) {
      return ShowToast(
        'error',
        'Selecione ao menos 10 planos para sua primeira contratação.',
      )
    }

    setCurrentStep(1)
  }

  const handleStepTwoSuccess = () => {
    setCurrentStep(2)
  }

  const handleStepTreeSuccess = async () => {
    if (
      !email ||
      !phone ||
      !cep ||
      !neighborhood ||
      !address ||
      !complement ||
      !city ||
      !state ||
      !personType ||
      !userName ||
      !cpfCnpj
    ) {
      return ShowToast('error', 'Todos os campos devem ser preenchidos.')
    }

    if (state.length > 2) {
      return ShowToast(
        'error',
        'O campo estado deve ter no máximo dois caracteres.',
      )
    }

    setCurrentStep(3)
  }

  const handleStepFourSuccess = async () => {
    if (!cardNumber || !holderName || !month || !year || !cvv) {
      return ShowToast('error', 'Todos os campos devem ser preenchidos.')
    }

    try {
      setLoading(true)

      const areaCode = phone.replace(/\D/g, '').substring(0, 2)
      const phoneNumber = phone.replace(/\D/g, '').substring(2)

      const createResult = await useCase.handle({
        data: {
          plans: selectedPlan
            .map((item) => {
              return {
                planId: item.id as string,
                numberOfLicenses: item.licenses,
              }
            })
            .filter((f) => f.numberOfLicenses > 0),
          address: {
            city,
            country: 'BR',
            state,
            zip_code: cep,
            line_1: `${address} - ${neighborhood}`,
            line_2: complement,
          },
          email,
          phones: {
            mobile_phone: {
              area_code: areaCode,
              country_code: '55',
              number: phoneNumber,
            },
          },
          discountCoupon: discountCode,
          card: {
            cvv,
            exp_month: Number(month.padStart(2, '0')),
            exp_year: Number(year),
            holder_document: cpfCnpj.replace(/\D/g, ''),
            holder_name: holderName,
            number: cardNumber,
            brand: '',
            label: '',
          },
        },
        userId: user.id as string,
      })

      if (createResult.isFailure) {
        setLoading(false)
        return ShowToast(
          'error',
          'Ocorreu um erro ao efetuar o processo de pagamento. Tente novamente mais tarde.',
        )
      }

      setLoading(false)
      ShowToast('success', 'Plano contratado com sucesso.')
      return navigate('/home')
    } catch (error) {
      setLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar o processo de pagamento. Tente novamente mais tarde.',
      )
    }
  }

  const handleCancel = () => {
    if (currentStep === 0) {
      return navigate('/home')
    }

    if (currentStep === 1) {
      const selectedPlans = plans?.map((plan) => {
        return {
          id: plan.id,
          licenses: 0,
          name: plan.name,
          value: plan.value,
        }
      })

      setSelectedPlans(selectedPlans)
      setSelectedPlan([])
    }

    setCurrentStep(currentStep - 1)
  }

  function getDiscountPercentage() {
    if (discountCode) {
      if (discountCode === 'ABSCON15') {
        return '15%'
      }
      if (discountCode === 'ABSCON30') {
        return '30%'
      }
      if (discountCode === 'ABSCON50') {
        return '50%'
      }
    }
    return 'Cumpom inválido'
  }

  function calcFinalValue() {
    let discountPercentage = 0
    let total = 0
    if (discountCode) {
      if (discountCode === 'ABSCON15') {
        discountPercentage = 15
      }
      if (discountCode === 'ABSCON30') {
        discountPercentage = 30
      }
      if (discountCode === 'ABSCON50') {
        discountPercentage = 50
      }
    }

    selectedPlan.map((currentPlan) => {
      const planData = plans.find(
        (f) => f.id === currentPlan.id,
      ) as ICompanyPlan
      total += Number(planData.value) * currentPlan.licenses
      return currentPlan
    })

    // Aplicando o desconto, se o total for maior que zero
    if (total > 0) {
      const discountAmount = (total * discountPercentage) / 100
      total -= discountAmount
    }

    if (total <= 0) {
      return null
    }

    return formatMoney(total)
  }

  return (
    <Stack width="100%" height="100%" sx={{ backgroundColor: '#F4F8FA' }}>
      <DefaultHeader
        containerStyle={{
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '32px',
          zIndex: 9,
          position: 'relative',
        }}
        breadcumbItems={[
          { title: 'Painel multiempresas', navigateTo: '/home' },
          { title: 'Contratar plano' },
        ]}
        buttons={
          <Button
            fullWidth={false}
            variant="outlined"
            onClick={handleCancel}
            sx={{
              width: '284px',
              height: '40px',
              color: '#fff',
              borderColor: '#fff',
              padding: 0,
              fontSize: '14px',
            }}
          >
            Voltar para o painel multiempresa
          </Button>
        }
      />
      <Stack
        width="100%"
        height="calc(100% - 125px)"
        direction="row"
        gap="32px"
        overflow="hidden"
      >
        <Stack width="80%" height="100%" padding="24px">
          {currentStep === 0 && (
            <Stack width="100%" height="100%" gap="32px" direction="column">
              <Stack
                width="100%"
                height="85%"
                gap="24px"
                paddingTop="24px"
                paddingRight="12px"
                sx={{ overflowX: 'hidden', overflowY: 'auto' }}
              >
                <Stack paddingRight="12px" width="100%" gap="32px">
                  <BasePageTitle
                    color={theme.palette.primary.main}
                    text="Planos"
                  />
                  <PlanListSubscription
                    plans={plans?.map((item) => {
                      let subscriptionId = null
                      let subscriptionLicenses = 0
                      const subscription = subscriptions?.plans
                        ?.filter((f) => f.id === item.id)
                        .map((planSubscription) => {
                          subscriptionLicenses += planSubscription.licenses
                          return planSubscription
                        })

                      if (subscription && subscription?.length > 0) {
                        subscriptionId = subscription[0].id
                      }
                      return {
                        ...item,
                        subscription: {
                          id: subscriptionId,
                          licenses: subscriptionLicenses,
                        },
                      }
                    })}
                    selectionButtonAction={handleStepOneSelectPlans}
                  />
                </Stack>
              </Stack>
              <Stack
                width="100%"
                height="16%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="32px"
              >
                <Stack
                  width="60%"
                  direction="row"
                  alignItems="center"
                  gap="24px"
                >
                  {selectedPlan && selectedPlan?.length > 0 && (
                    <Stack
                      width="100%"
                      direction="row"
                      alignItems="center"
                      gap="24px"
                    >
                      <Typography fontSize="16px" fontWeight="bold">
                        Resumo:
                      </Typography>
                      <Stack width="100%" gap="12px">
                        {selectedPlan
                          .filter((item) => item.licenses > 0)
                          .map((item, index) => {
                            const currentPlan = plans.find(
                              (f) => f.id === item.id,
                            ) as ICompanyPlan
                            return (
                              <Stack key={index}>
                                <Typography
                                  flexDirection="row"
                                  key={`${index}-title`}
                                  fontSize="14px"
                                >
                                  {`Plano ${currentPlan.name} - ${item.licenses} x ${formatMoney(Number(currentPlan.value))}`}
                                </Typography>
                              </Stack>
                            )
                          })}
                      </Stack>
                    </Stack>
                  )}
                </Stack>

                {!loading ? (
                  <Stack gap="24px" direction="row">
                    <Button
                      variant="outlined"
                      onClick={handleCancel}
                      sx={{ width: '130px' }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleStepOneSuccess}
                      sx={{ width: '130px' }}
                    >
                      Continuar
                    </Button>
                  </Stack>
                ) : (
                  <CircularProgress />
                )}
              </Stack>
            </Stack>
          )}
          {currentStep === 1 && (
            <Stack width="80%" height="100%" gap="32px" direction="column">
              <Stack
                width="100%"
                height="85%"
                gap="24px"
                paddingTop="24px"
                paddingRight="12px"
                sx={{ overflowX: 'hidden', overflowY: 'auto' }}
              >
                <Stack paddingRight="12px" width="100%" gap="32px">
                  <BasePageTitle
                    color={theme.palette.primary.main}
                    text="Resumo"
                  />
                  <ResumeSubscription
                    plans={selectedPlan
                      ?.filter((item) => item.licenses > 0)
                      ?.map((item) => {
                        let subscriptionId = null
                        let subscriptionLicenses = 0
                        const subscription = subscriptions?.plans
                          ?.filter((f) => f.id === item.id)
                          .map((planSubscription) => {
                            subscriptionLicenses += planSubscription.licenses
                            return planSubscription
                          })

                        if (subscription && subscription?.length > 0) {
                          subscriptionId = subscription[0].id
                        }

                        const planData = plans.find((f) => f.id === item.id)
                        return {
                          id: item.id,
                          name: planData?.name as string,
                          value: planData?.value as string,
                          subscription: {
                            id: subscriptionId,
                            licenses: subscriptionLicenses,
                          },
                        }
                      })}
                  />
                </Stack>
              </Stack>
              <Stack
                width="100%"
                height="16%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="32px"
              >
                <Stack
                  width="60%"
                  direction="row"
                  alignItems="center"
                  gap="24px"
                >
                  {selectedPlan && selectedPlan?.length > 0 && (
                    <Stack
                      width="100%"
                      direction="column"
                      alignItems="center"
                      gap="24px"
                    >
                      <Stack width="100%" gap="12px">
                        {selectedPlan
                          .filter((item) => item.licenses > 0)
                          .map((item, index) => {
                            const currentPlan = plans.find(
                              (f) => f.id === item.id,
                            ) as ICompanyPlan
                            return (
                              <Stack key={index}>
                                <Typography
                                  flexDirection="row"
                                  key={`${index}-title`}
                                  fontSize="14px"
                                  fontWeight="600"
                                >
                                  {`Plano ${currentPlan.name} - ${item.licenses} x ${formatMoney(Number(currentPlan.value))}`}
                                </Typography>
                              </Stack>
                            )
                          })}
                      </Stack>
                      {calcFinalValue() && (
                        <Stack
                          width="100%"
                          direction="row"
                          alignItems="fkex-start"
                          gap="24px"
                        >
                          <Typography fontSize="16px" fontWeight="bold">
                            Total:
                          </Typography>
                          <Stack width="100%" gap="12px">
                            <Stack>
                              <Typography flexDirection="row" fontSize="14px">
                                {calcFinalValue()}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  )}
                </Stack>

                {!loading ? (
                  <Stack gap="24px" direction="row">
                    <Button
                      variant="outlined"
                      onClick={handleCancel}
                      sx={{ width: '130px' }}
                    >
                      Voltar
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleStepTwoSuccess}
                      sx={{ width: '130px' }}
                    >
                      Continuar
                    </Button>
                  </Stack>
                ) : (
                  <CircularProgress />
                )}
              </Stack>
            </Stack>
          )}
          {currentStep === 2 && (
            <Stack width="80%" height="100%" gap="32px" direction="column">
              <Stack
                width="100%"
                height="85%"
                gap="24px"
                paddingTop="24px"
                paddingRight="12px"
                sx={{ overflowX: 'hidden', overflowY: 'auto' }}
              >
                <BasePageTitle
                  color={theme.palette.primary.main}
                  text="Dados de faturamento"
                />
                <BasePageSubtitle
                  color="#000000"
                  text="Informe os dados do responsável pelo pagamento da assinatura"
                />
                <Stack width="100%" direction="row" gap="24px">
                  <BaseInput
                    label="Usuário responsável"
                    fullWidth
                    error=""
                    disabled
                    value={user.name as string}
                    setState={(e) => null}
                  />
                </Stack>
                <Stack width="100%" direction="row" gap="24px">
                  <BaseRadio
                    groupStyle={{
                      marginTop: '0px',
                    }}
                    value={personType}
                    setState={setPersonType}
                    options={[
                      { label: 'Pessoa física', value: 'FISICA' },
                      { label: 'Pessoa jurídica', value: 'JURIDICA' },
                    ]}
                  />
                </Stack>
                <Stack width="100%" direction="row" gap="24px">
                  <BaseInput
                    label="Nome/Razão social"
                    fullWidth
                    error=""
                    value={userName}
                    setState={(e) => setUserName(e)}
                  />
                  <BaseInput
                    label="CPF/CNPJ"
                    fullWidth
                    value={cpfCnpj}
                    mask={
                      personType === 'FISICA'
                        ? '999.999.999-99'
                        : '99.999.999/9999-99'
                    }
                    setState={setCpfCnpj}
                    error=""
                  />
                </Stack>
                <Stack width="100%" direction="row" gap="24px">
                  <BaseInput
                    label="Email"
                    fullWidth
                    error=""
                    value={email}
                    setState={(e) => setEmail(e)}
                  />
                  <BaseInput
                    label="Telefone"
                    fullWidth
                    error=""
                    value={phone}
                    mask="(99) 99999-9999"
                    setState={(e) => setPhone(e)}
                  />
                </Stack>

                <Stack width="100%" direction="row" gap="24px">
                  <BaseInput
                    label="Endereço"
                    fullWidth
                    error=""
                    value={address}
                    setState={(e) => setAddress(e)}
                  />
                </Stack>
                <Stack width="100%" direction="row" gap="24px">
                  <BaseInput
                    label="Cep"
                    fullWidth
                    error=""
                    value={cep}
                    setState={(e) => setCep(e)}
                  />
                  <BaseInput
                    label="Complemento"
                    fullWidth
                    error=""
                    value={complement}
                    setState={(e) => setComplemnent(e)}
                  />
                </Stack>

                <Stack width="100%" direction="row" gap="24px">
                  <BaseInput
                    label="Bairro"
                    fullWidth
                    error=""
                    value={neighborhood}
                    setState={(e) => setNeighborhood(e)}
                  />
                  <BaseInput
                    label="Cidade"
                    fullWidth
                    error=""
                    value={city}
                    setState={(e) => setCity(e)}
                  />
                  <BaseInput
                    label="Estado"
                    fullWidth
                    error=""
                    value={state}
                    setState={(e) => setState(e)}
                  />
                </Stack>
              </Stack>
              <Stack
                width="100%"
                height="16%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="32px"
              >
                <Stack
                  width="60%"
                  direction="row"
                  alignItems="center"
                  gap="24px"
                >
                  {selectedPlan && selectedPlan?.length > 0 && (
                    <Stack
                      width="100%"
                      direction="column"
                      alignItems="center"
                      gap="24px"
                    >
                      <Stack width="100%" gap="12px">
                        {selectedPlan
                          .filter((item) => item.licenses > 0)
                          .map((item, index) => {
                            const currentPlan = plans.find(
                              (f) => f.id === item.id,
                            ) as ICompanyPlan
                            return (
                              <Stack key={index}>
                                <Typography
                                  flexDirection="row"
                                  key={`${index}-title`}
                                  fontSize="14px"
                                  fontWeight="600"
                                >
                                  {`Plano ${currentPlan.name} - ${item.licenses} x ${formatMoney(Number(currentPlan.value))}`}
                                </Typography>
                              </Stack>
                            )
                          })}
                      </Stack>
                      {calcFinalValue() && (
                        <Stack
                          width="100%"
                          direction="row"
                          alignItems="fkex-start"
                          gap="24px"
                        >
                          <Typography fontSize="16px" fontWeight="bold">
                            Total:
                          </Typography>
                          <Stack width="100%" gap="12px">
                            <Stack>
                              <Typography flexDirection="row" fontSize="14px">
                                {calcFinalValue()}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  )}
                </Stack>

                {!loading ? (
                  <Stack gap="24px" direction="row">
                    <Button
                      variant="outlined"
                      onClick={handleCancel}
                      sx={{ width: '130px' }}
                    >
                      Voltar
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleStepTreeSuccess}
                      sx={{ width: '130px' }}
                    >
                      Continuar
                    </Button>
                  </Stack>
                ) : (
                  <CircularProgress />
                )}
              </Stack>
            </Stack>
          )}
          {currentStep === 3 && (
            <Stack width="80%" height="100%" gap="32px" direction="column">
              <Stack
                width="100%"
                height="85%"
                gap="24px"
                paddingTop="24px"
                paddingRight="12px"
                sx={{ overflowX: 'hidden', overflowY: 'auto' }}
              >
                <Stack width="100%" direction="row" gap="24px">
                  <BaseInput
                    label="Número do cartão"
                    fullWidth
                    error=""
                    value={cardNumber}
                    setState={(e) => setCardNumber(e)}
                  />
                  <BaseInput
                    label="Nome impresso no catão"
                    fullWidth
                    error=""
                    value={holderName}
                    setState={(e) => setHolderName(e)}
                  />
                </Stack>

                <Stack width="100%" direction="row" gap="24px">
                  <BaseInput
                    label="Mês de validade"
                    fullWidth
                    error=""
                    value={month}
                    setState={(e) => setMonth(e)}
                  />
                  <BaseInput
                    label="Ano"
                    fullWidth
                    error=""
                    value={year}
                    setState={(e) => setYear(e)}
                  />
                  <BaseInput
                    label="Código de segurança"
                    fullWidth
                    error=""
                    value={cvv}
                    setState={(e) => setCvv(e)}
                  />
                </Stack>
                <Stack width="100%" direction="row" gap="24px">
                  <BaseInput
                    label="Cupom de desconto"
                    fullWidth={false}
                    error=""
                    value={discountCode}
                    setState={(e) => setDiscountCode(e)}
                  />
                </Stack>
              </Stack>
              <Stack
                width="100%"
                height="16%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="32px"
              >
                <Stack
                  width="60%"
                  direction="row"
                  alignItems="center"
                  gap="24px"
                >
                  {selectedPlan && selectedPlan?.length > 0 && (
                    <Stack
                      width="100%"
                      direction="row"
                      alignItems="center"
                      gap="24px"
                    >
                      <Stack width="100%" gap="12px">
                        {selectedPlan
                          .filter((item) => item.licenses > 0)
                          .map((item, index) => {
                            const currentPlan = plans.find(
                              (f) => f.id === item.id,
                            ) as ICompanyPlan
                            return (
                              <Stack key={index}>
                                <Typography
                                  flexDirection="row"
                                  key={`${index}-title`}
                                  fontSize="14px"
                                >
                                  {`Plano ${currentPlan.name} - ${item.licenses} x ${formatMoney(Number(currentPlan.value))}`}
                                </Typography>
                              </Stack>
                            )
                          })}
                      </Stack>
                      {calcFinalValue() && (
                        <Stack
                          width="100%"
                          direction="row"
                          alignItems="center"
                          gap="24px"
                        >
                          <Typography fontSize="16px" fontWeight="bold">
                            Total:
                          </Typography>
                          <Stack
                            width="100%"
                            gap="12px"
                            alignItems="flex-start"
                          >
                            <Stack>
                              <Typography flexDirection="row" fontSize="14px">
                                {calcFinalValue()}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  )}
                </Stack>

                {!loading ? (
                  <Stack gap="24px" direction="row">
                    <Button
                      variant="outlined"
                      onClick={handleCancel}
                      sx={{ width: '130px' }}
                    >
                      Voltar
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleStepFourSuccess}
                      sx={{ width: '180px' }}
                    >
                      Contratar plano
                    </Button>
                  </Stack>
                ) : (
                  <CircularProgress />
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack width="20%" height="100%" alignItems="center">
          <VerticalStepper
            currentStep={currentStep}
            setCurrent={setCurrentStep}
            steps={[
              { title: 'Plano' },
              { title: 'Resumo' },
              { title: 'Faturamento' },
              { title: 'Pagamento' },
            ]}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
