import { useState } from 'react'
import { CompanyAccountEntryConciliationStatusEnum } from 'src/service/service-company'

export function useConciliator(companyId: string) {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState<
    CompanyAccountEntryConciliationStatusEnum[]
  >([
    CompanyAccountEntryConciliationStatusEnum.CONCILIATED,
    CompanyAccountEntryConciliationStatusEnum.CONCILIATED_PARTIAL,
    CompanyAccountEntryConciliationStatusEnum.DESPISED,
    CompanyAccountEntryConciliationStatusEnum.NOT_CONCILIATED,
  ])

  return {
    isLoading,
    selectedStatus,
    setSelectedStatus,
    setIsLoading,
  }
}
