import { AxiosInstance } from 'axios'
import { Result } from 'src/shared/protocol/protoco-result'
import { IBankAccount, IBankTransfersImport } from './service-account'

export type IBank = {
  ispb: string
  name: string
  code: string
  fullName: string
}

export enum BankAccountTransferStatusEnum {
  TRANSACAO_EFETIVADA = 'TRANSACAO_EFETIVADA',
  LANCAMENTO_FUTURO = 'LANCAMENTO_FUTURO',
  TRANSACAO_PROCESSANDO = 'TRANSACAO_PROCESSANDO',
}

export enum BankAccountTransferMethodEnum {
  CREDITO = 'CREDITO',
  DEBITO = 'DEBITO',
}

export interface IBankAccountTransfer {
  id: string
  externalId: string
  status: BankAccountTransferStatusEnum
  type: any // validar no back, hoje retorna sempre null
  method: BankAccountTransferMethodEnum
  amount: string
  currency: string
  date: string
  partie: {
    name: string
    type: any // validar no back, hoje retorna sempre null
    document: any // validar no back, hoje retorna sempre null
  }
  transferCompleteDescription: string
  createdAt: string
  updatedAt: string
  bankAccount: IBankAccount
}

export interface IBankAccountTransferResponse {
  transfers: IBankAccountTransfer[]
  balance: string
  pages: number
}

export interface ICashFlowConsolidate {
  total: string
  inflow: string
  outflow: string
}
export interface ICashFlow {
  id: string
  day?: string
  month: string
  year: string
  balance: string
  inflow: string
  outflow: string
  createdAt: string
  updatedAt: string
  bankAccount: IBankAccount
}

export interface ICashFlowResponse {
  cashflows: ICashFlow[]
  consolidate: ICashFlowConsolidate
}

export class BankApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public listBanks = async (): Promise<Result<IBank[]>> => {
    try {
      // Verifica se os dados já estão armazenados no localStorage
      const cachedBanks = localStorage.getItem('listBanks')

      if (cachedBanks) {
        // Retorna os dados do cache
        const banks = JSON.parse(cachedBanks) as IBank[]
        return Result.ok(banks)
      }

      // Se não houver cache, faz a requisição
      const { data }: { data: IBank[] } = await this.service.get('/banks/v1', {
        baseURL: 'https://brasilapi.com.br/api',
      })

      const response = data
        .filter((bank) => bank.code)
        .map((item) => {
          const code = item.code.toString().padStart(3, '0')
          return {
            ...item,
            name: `${code} - ${item.name?.trim()}`,
            fullName: `${code} - ${item.fullName?.trim()}`,
            code: item.code.toString(),
          }
        })

      // Adiciona a opção "Sem instituição financeira" no início da lista
      const noFinancialInstitution = {
        code: '99999',
        name: '99999 - Sem instituição financeira',
        fullName: '99999 - Sem instituição financeira',
        ispb: '99999',
      }

      // Insere a nova opção no início da lista de bancos
      const finalResponse = [noFinancialInstitution, ...response]

      // Armazena os dados no localStorage
      localStorage.setItem('listBanks', JSON.stringify(finalResponse))

      return Result.ok(finalResponse)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public listBankAccountsTransfer = async ({
    accountsIds,
    date,
    page,
  }: {
    accountsIds?: string[]
    date?: string[]
    page: number
  }): Promise<Result<IBankAccountTransferResponse>> => {
    try {
      const { data } = await this.service.get('/v1/bank/transfer', {
        params: {
          accountId:
            accountsIds && accountsIds?.length > 0 ? [...accountsIds] : [],
          date,
          page,
        },
      })

      return Result.ok({
        transfers: data.transfers.map((transfer: IBankAccountTransfer) => {
          const originalDate = new Date(transfer.date)
          // Extrai as partes da data (ano, mês, dia, horas, minutos, segundos) da data original no UTC
          const year = originalDate.getUTCFullYear()
          const month = originalDate.getUTCMonth()
          const day = originalDate.getUTCDate()
          const hours = originalDate.getUTCHours()
          const minutes = originalDate.getUTCMinutes()
          const seconds = originalDate.getUTCSeconds()

          // Cria uma nova data no timezone local, usando os valores UTC
          const formattedDate = new Date(
            year,
            month,
            day,
            hours,
            minutes,
            seconds,
          )

          return {
            ...transfer,
            date: formattedDate,
          }
        }),
        balance: data.balance,
        pages: data.pages,
      })
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public listBankAccountsTransferHistory = async ({
    bankAccountId,
    companyId,
  }: {
    bankAccountId: string
    companyId: string
  }): Promise<Result<IBankTransfersImport[]>> => {
    try {
      const { data } = await this.service.get(
        `/v1/bank/${companyId}/bank-account/${bankAccountId}/import/history`,
      )

      return Result.ok(data.bankTransfersImport.transfersImport)
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public deleteBankAccountsTransferHistory = async ({
    companyId,
    bankAccountId,
    importedTransfersId,
  }: {
    companyId: string
    bankAccountId: string
    importedTransfersId: string
  }): Promise<Result<void>> => {
    try {
      await this.service.delete(
        `/v1/bank/${companyId}/bank-account/${bankAccountId}/import/${importedTransfersId}`,
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public listCashFlow = async ({
    companiesIds,
    accountIds,
    date,
  }: {
    companiesIds?: string[]
    accountIds?: string[]
    date?: string[]
  }): Promise<Result<ICashFlowResponse>> => {
    try {
      const { data } = await this.service.get('/v1/bank/cashflow', {
        params: {
          companyId: companiesIds,
          accountId: accountIds,
          date,
        },
      })

      return Result.ok({
        cashflows: data.cashflows,
        consolidate: data.consolidate,
      })
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }

  public openFinanceSync = async ({
    bankAccountId,
    companyId,
  }: {
    bankAccountId: string
    companyId: string
  }): Promise<Result<void>> => {
    try {
      const result = await this.service.post(
        `/v1/bank/${companyId}/bank-account/${bankAccountId}/open-finance/sync`,
        {
          params: {
            bankAccountId,
            companyId,
          },
        },
      )
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }
}
