import { ArrowDropDown } from '@mui/icons-material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import React, { FC, useState } from 'react'
interface Props {
  options: {
    label: string
    value: string | number
    icon?: React.ReactNode
  }[]
  value?: string | number
  onSelect?: (data?: any) => void
  placeholder?: string
  showLabel?: boolean
  marginTop?: number
  marginBottom?: number
}

export const BaseInputSingleSelect: FC<Props> = ({
  options,
  onSelect,
  placeholder,
  showLabel = true,
  marginBottom = 2,
  marginTop = 2,
  value,
  ...rest
}) => {
  const [internalControlValue, setInternalControlValue] = useState<any>(null)
  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value },
    } = event

    if (onSelect) {
      onSelect(value)
      setInternalControlValue(value)
    }
  }

  return (
    <div>
      <FormControl sx={{ width: '100%', marginTop, marginBottom }} {...rest}>
        {showLabel && !internalControlValue && (
          <InputLabel
            id="demo-multiple-checkbox-label"
            sx={{ color: '#4865CC', marginLeft: 2 }}
            shrink={!!value}
          >
            {value || placeholder || 'Selecione'}
          </InputLabel>
        )}

        <Select
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          IconComponent={() => (
            <ArrowDropDown sx={{ color: '#4865CC', marginRight: 3 }} />
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
                width: 250,
              },
            },
          }}
          sx={{
            color: '#4865CC',
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '100px', // Arredonda os cantos
              borderColor: '#4865CC', // Muda a cor da borda
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#4865CC', // Cor da borda ao passar o mouse
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#4865CC', // Cor da borda quando o campo está focado
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
