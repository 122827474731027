import SearchIcon from '@mui/icons-material/Search'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInternalHeader } from 'src/component/base-component/base-internal-header'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { DefaultHeader } from 'src/component/header/header'
import { MenuDotIcon } from 'src/component/icons/menu-dot'
import { NotificationBellIcon } from 'src/component/icons/notification-bell'
import { Table } from 'src/component/table/table'
import { CreateCompanyUsersUseCase } from 'src/usecase/company/usecase-company-add-users'
import { ListCompanyClassificationRulesUseCase } from 'src/usecase/company/usecase-company-classification-rules-list'
import { CreateAccountClassificationRuleUseCase } from 'src/usecase/company/usecase-company-create-classification-rule'
import { DeleteClassificationRuleUseCase } from 'src/usecase/company/usecase-company-delete-classification-rule'
import { LeaveCompanyUseCase } from 'src/usecase/company/usecase-company-leave'
import { ListCompanyUseCase } from 'src/usecase/company/usecase-company-list'
import { UpdateAccountClassificationRuleUseCase } from 'src/usecase/company/usecase-company-update-classification-rule'
import { UserListUseCase } from 'src/usecase/user/usecase-user-list'
import { useClassificationRules } from './useClassificationRules'
import BlueHeader from '../../assets/background/header-blue.png'
import { UserFullContext } from 'src/context/context-user'
import { ReplyCompanyClassificationRuleUseCase } from 'src/usecase/company/usecase-company-reply-classification-rule'

interface TemporaryProps {
  listCompaniesUseCase: ListCompanyUseCase
  addUserToCompanyUseCase: CreateCompanyUsersUseCase
  listUsersUseCase: UserListUseCase
  leaveCompanyUseCase: LeaveCompanyUseCase
  listClassificationRulesUseCase: ListCompanyClassificationRulesUseCase
  createClassificationRuleUseCase: CreateAccountClassificationRuleUseCase
  deleteClassificationRuleUseCase: DeleteClassificationRuleUseCase
  updateClassificationRuleUseCase: UpdateAccountClassificationRuleUseCase
  replyCompanyClassificationRuleUseCase: ReplyCompanyClassificationRuleUseCase
}

export function ClassificationRulesList(props: TemporaryProps) {
  const { companyId } = useParams()
  const { GetCompanyData } = UserFullContext()

  const {
    searchQuery,
    columns,
    classificationRules,
    selectedGridIds,
    isLoading,
    handleCreateClassificationRule,
    handleListClassificationRules,
    setSearchQuery,
    setSelectedGridIds,
    handleDeleteAccountModal,
    handleListUserCompaniesUsecase,
    handleReplayClassificationRulesModal,
  } = useClassificationRules(
    props.listClassificationRulesUseCase,
    props.createClassificationRuleUseCase,
    props.deleteClassificationRuleUseCase,
    props.updateClassificationRuleUseCase,
    props.listCompaniesUseCase,
    props.replyCompanyClassificationRuleUseCase,
    companyId as string,
  )

  useEffect(() => {
    handleListClassificationRules()
    handleListUserCompaniesUsecase()
  }, [companyId])

  return (
    <Stack width="100%" height="100%" sx={{ backgroundColor: '#F4F8FA' }}>
      <DefaultHeader
        containerStyle={{
          height: '56px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '16px',
          zIndex: 9,
          position: 'relative',
        }}
        breadcumbItems={[
          {
            title: GetCompanyData().name as string,
            navigateTo: `/company/${GetCompanyData().id}`,
          },
          {
            title: 'Regra de classificação',
          },
        ]}
        buttons={
          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '24px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => console.log('empty')}
            >
              <NotificationBellIcon />
            </IconButton>
          </Stack>
        }
      />

      <Stack
        width="100%"
        height="calc(100% - 130px)"
        direction="row"
        gap="32px"
      >
        <Stack
          width="100%"
          height="calc(100% - 64px)"
          padding="32px"
          gap="48px"
        >
          <BaseInternalHeader
            leftComponent={
              <BasePageTitle color="#1E3466" text="Regras de classificação" />
            }
          />

          <Stack width="calc(100% - 64px)" height="100%">
            {!isLoading && (
              <Table
                columns={columns}
                rows={
                  !searchQuery
                    ? classificationRules
                    : classificationRules.filter(
                        (f) =>
                          f.name
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase()) ||
                          f.accountingChartItem.code.includes(searchQuery),
                      )
                }
                loadMore={() => console.log('press me')}
                totalPages={1}
                currentPage={1}
                totalRegisters={classificationRules?.length}
                allowSelectAll={true}
                loading={false}
                selectedItemActions={[
                  {
                    title: 'Excluír regras de classificação',
                    action: () => handleDeleteAccountModal(selectedGridIds),
                  },
                  {
                    title: 'Replicar regras para outras empresas',
                    action: () => handleReplayClassificationRulesModal(),
                  },
                ]}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                showFilterButton={true}
                showSearchButton={true}
                setSelectedGridIds={setSelectedGridIds}
                rightActions={
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    alignSelf="center"
                    gap="32px"
                  >
                    <Button
                      variant="contained"
                      sx={{ width: '128px', height: '40px' }}
                      onClick={handleCreateClassificationRule}
                    >
                      Criar regra
                    </Button>
                    <Box width="280px">
                      <BaseInput
                        fullWidth
                        labelBackground="#F4F8FA"
                        type="text"
                        label="Buscar"
                        setState={(event) => {
                          if (!event) {
                            return setSearchQuery && setSearchQuery('')
                          }

                          setSearchQuery && setSearchQuery(event)
                        }}
                        value={searchQuery ?? ''}
                        error={null}
                        iconStart={<SearchIcon />}
                      />
                    </Box>
                  </Stack>
                }
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
