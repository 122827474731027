import { FormControlLabel, Radio, RadioGroup, useTheme } from '@mui/material'
import { Logo } from './base-logo-component'

export type BaseRadioProps = {
  setState: (value: string) => void
  value: string
  options: {
    label: string
    value: string
    logo?: string
    name?: string
  }[]
  groupStyle: any
}
export const BaseRadio = ({
  setState,
  value,
  options,
  groupStyle,
}: BaseRadioProps) => {
  const theme = useTheme()
  return (
    <RadioGroup
      sx={{
        display: 'grid',
        gridTemplateColumns: 'max-content max-content',
        gap: '32px',
        justifySelf: 'start',
        ...groupStyle,
      }}
      name="personType"
      value={value}
      onChange={(event) => setState(event.target.value)}
    >
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio />}
          label={
            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {option.logo && (
                <Logo
                  name={option.name as string}
                  width={48}
                  height={48}
                  logo={option.logo}
                />
              )}
              {option.label}
            </div>
          }
        />
      ))}
    </RadioGroup>
  )
}
