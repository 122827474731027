import { AxiosInstance } from 'axios'
import { Result } from 'src/shared/protocol/protoco-result'

export type TSubscriptionCreateDTO = {
  discountCoupon?: string
  customPlanValue?: string
  plans: {
    planId: string
    numberOfLicenses: number
  }[]
  email: string
  address: {
    country: string
    state: string
    city: string
    zip_code: string
    line_1: string
    line_2: string
  }
  phones: {
    mobile_phone: {
      country_code: string
      area_code: string
      number: string
    }
  }
  card: {
    number: string
    holder_name: string
    holder_document: string
    exp_month: number
    exp_year: number
    cvv: string
    brand?: string
    label?: string
  }
}

export class SubscriptionApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public createSubscription = async (
    userId: string,
    data: TSubscriptionCreateDTO,
  ): Promise<Result<void>> => {
    try {
      await this.service.post(`/v1/subscriptions/${userId}/subscribe`, data)
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error)
    }
  }
}
