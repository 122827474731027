import {
  CompanyApiRemoteService,
  TCompanyAccountEntryCreate,
} from 'src/service/service-company'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class CreateManualAccountingEntriesUseCase
  implements
    IUsecase<
      {
        data: TCompanyAccountEntryCreate[]
        companyId: string
      },
      void
    >
{
  constructor(private companyApi: CompanyApiRemoteService) {}

  public async handle({
    data,
    companyId,
  }: {
    data: TCompanyAccountEntryCreate[]
    companyId: string
  }): Promise<Result<void>> {
    try {
      // eslint-disable-next-line prettier/prettier
      const response = await this.companyApi.createAccountingEntries({
        data,
        companyId,
      })

      if (response.isFailure) {
        return Result.fail(new Error('Erro ao criar o registro.'))
      }

      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
