import Search from '@mui/icons-material/Search'
import {
  Button,
  Checkbox,
  InputAdornment,
  Stack,
  Typography,
  Chip,
} from '@mui/material'
import { useRef, useState } from 'react'
import { BaseInputSingleSelect } from 'src/component/base-component/base-input-single-select'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { CompanyUserPermissionTypeEnum } from 'src/service/service-company'
import { SearchInput } from '../../base-component/base-multiple-select'
import { blue } from '@mui/material/colors'

export type TModalAddUserType = {
  id: string
  name: string
  username: string
}

export const CompanyCreateUsersMassModal: React.FC<{
  users: TModalAddUserType[]
  handle: (
    users: {
      userId: string
    }[],
    permission: CompanyUserPermissionTypeEnum,
  ) => Promise<void>
}> = ({ users, handle }) => {
  const { HideModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()
  const [modalUsers, setModalUsers] = useState<TModalAddUserType[]>([])
  const [searchText, setSearchText] = useState('')
  const searchTimeout = useRef<NodeJS.Timeout | null>(null)
  const [permission, setPermission] = useState<CompanyUserPermissionTypeEnum>(
    CompanyUserPermissionTypeEnum.ANALISTA,
  )

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setSearchText(newValue)

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }

    searchTimeout.current = setTimeout(() => {
      setSearchText(newValue)
    }, 1000)
  }

  const handleExecute = async () => {
    try {
      const usersToSend = modalUsers.map((map) => {
        return {
          userId: map.id,
        }
      })

      await handle(usersToSend, permission)
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao adicionar o(s) usuário(s) na(s) empresa(s).',
      )
    }
  }

  const handleChangeUserPermission = (
    permission: CompanyUserPermissionTypeEnum,
  ) => {
    setPermission(permission)
  }

  const handleAddOrRemoveUser = (userId: string) => {
    const exist = !!modalUsers.find((f) => f.id === userId)?.id

    if (exist) {
      const tempUsers = modalUsers
        .filter((f) => f.id !== userId)
        .map((user) => user)
      setModalUsers(tempUsers)
    } else {
      const user = users.find((f) => f.id === userId)
      const tempUsers = [...modalUsers, user] as TModalAddUserType[]
      setModalUsers(tempUsers)
    }
  }

  const allowContinue =
    modalUsers.map((map) => {
      return {
        userId: map.id,
        permission,
      }
    })?.length > 0

  return (
    <Stack
      width="500px"
      height="auto"
      maxHeight="595px"
      gap="24px"
      justifyContent="space-between"
    >
      <Stack width="100%" height="90%" gap="24px">
        <Typography fontSize="14px" fontWeight={400} color="#1E1E1E">
          Selecione e configure o(s) usuário(s) que deseja adicionar a(s)
          empresa(s)
        </Typography>
        <SearchInput
          size="small"
          autoFocus
          placeholder="Buscar"
          fullWidth
          aria-pressed
          startAdornment={
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          }
          sx={{ marginTop: '0px', borderRadius: '100px' }}
          onChange={handleSearchChange}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              e.stopPropagation()
            }
          }}
        />

        <Stack direction="row" spacing={1}>
          {modalUsers.map((user) => (
            <Chip
              key={user.id}
              label={user.username}
              onDelete={() => handleAddOrRemoveUser(user.id)}
              variant="outlined"
            />
          ))}
        </Stack>

        {searchText && (
          <Stack
            width="calc(100% - 32px)"
            minHeight="80px"
            maxHeight="310px"
            borderRadius="16px"
            border="1px solid #777C84"
            padding="16px"
            direction="column"
          >
            <Stack width="100" sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
              {users
                ?.filter((user) =>
                  user.username
                    .toLowerCase()
                    .includes(searchText.toLowerCase()),
                )
                .map((user) => (
                  <Stack
                    key={user.id}
                    width="100%"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    @{user.username}
                    <Checkbox
                      checked={!!modalUsers.find((f) => f.id === user.id)?.id}
                      onChange={() => handleAddOrRemoveUser(user.id)}
                      sx={{
                        color: blue[800],
                        '&.Mui-checked': {
                          color: blue[800],
                        },
                        '& .MuiSvgIcon-root': {
                          borderRadius: '4px',
                        },
                      }}
                    />
                  </Stack>
                ))}
            </Stack>
          </Stack>
        )}

        <BaseInputSingleSelect
          placeholder=""
          options={[
            {
              label: 'Analista',
              value: CompanyUserPermissionTypeEnum.ANALISTA,
            },
            {
              label: 'Master',
              value: CompanyUserPermissionTypeEnum.MASTER,
            },
          ]}
          showLabel={false}
          value={permission}
          onSelect={(e) => handleChangeUserPermission(e)}
          marginBottom={0}
          marginTop={0}
        />
      </Stack>

      <Stack
        direction="row"
        gap="24px"
        justifyContent="flex-end"
        alignItems="end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => HideModal()}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Cancelar
        </Button>

        <Button
          fullWidth={false}
          disabled={!allowContinue}
          variant="contained"
          onClick={handleExecute}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Convidar
        </Button>
      </Stack>
    </Stack>
  )
}
