export const ConciliatorIcon = (): JSX.Element => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#4259DF" />
    <path
      d="M23 19.25C21.6672 19.25 20.5767 18.5952 19.6145 18.0185C18.7415 17.4942 17.9172 17 17 17C15.9515 17 15.1632 17.6472 14.5302 18.2802L13.4697 17.2198C14.27 16.4188 15.395 15.5 17 15.5C18.3327 15.5 19.4232 16.1548 20.3855 16.7315C21.2585 17.2558 22.0827 17.75 23 17.75C24.0485 17.75 24.8367 17.1028 25.4697 16.4698L26.5302 17.5302C25.73 18.3312 24.605 19.25 23 19.25ZM23 24.5C21.6672 24.5 20.5767 23.8453 19.6145 23.2685C18.7415 22.7442 17.9172 22.25 17 22.25C15.9515 22.25 15.1632 22.8973 14.5302 23.5303L13.4697 22.4697C14.27 21.6687 15.395 20.75 17 20.75C18.3327 20.75 19.4232 21.4048 20.3855 21.9815C21.2585 22.5058 22.0827 23 23 23C24.0485 23 24.8367 22.3527 25.4697 21.7197L26.5302 22.7803C25.73 23.5813 24.605 24.5 23 24.5Z"
      fill="white"
    />
    <path
      d="M20 30.5C14.21 30.5 9.5 25.79 9.5 20C9.5 14.21 14.21 9.5 20 9.5C25.79 9.5 30.5 14.21 30.5 20C30.5 25.79 25.79 30.5 20 30.5ZM20 11C15.0373 11 11 15.0373 11 20C11 24.9628 15.0373 29 20 29C24.9628 29 29 24.9628 29 20C29 15.0373 24.9628 11 20 11Z"
      fill="white"
    />
  </svg>
)
