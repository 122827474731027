import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactPageBaseWithSloganComponent } from '../../component/base-component/base-page-with-background'
import { useEffect, useState } from 'react'
import { BaseInput } from 'src/component/base-component/base-input'
import { CreateForm } from 'src/shared/util/form/form-util'
import { useNavigate } from 'react-router-dom'
import { BaseInputCode } from 'src/component/base-component/base-input-code'
import { UserFullContext } from 'src/context/context-user'
import { UserOwnDataUseCase } from 'src/usecase/user/usecase-user-own-data'
import { BaseSwitch } from 'src/component/base-component/base-switch'
import { Recovery2FAInitUseCase } from 'src/usecase/authentication/usecase-authentication-form-recovery-init-2fa'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { BaseQRCode } from 'src/component/base-component/base-qrcode'
import { Recovery2FAFinishUseCase } from 'src/usecase/authentication/usecase-authentication-form-recovery-finish-2fa'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Recovery2FAPageProps {
  useCaseInit: Recovery2FAInitUseCase
  useCaseFinish: Recovery2FAFinishUseCase
}

export function Recovery2FAPage(props: Recovery2FAPageProps): any {
  const { ShowToast } = ToastFullContext()

  const navigate = useNavigate()
  const theme = useTheme()

  const [loading, setLoading] = useState(false)
  const [secret, setSecret] = useState<string | null>(null)
  const [qrcode, setQrcode] = useState<string | null>(null)
  const [validateStep, setValidateStep] = useState<boolean>(false)

  const form = CreateForm({
    fields: ['token', 'secret'],
    validation: props.useCaseFinish.validation,
  })

  // First step
  const handleGetQRCodeUsecase = async () => {
    try {
      setLoading(true)

      const result = await props.useCaseInit.handle()

      setLoading(false)

      if (result.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
        )
      }

      const response = result.getValue()

      if (
        typeof response?.qrcode !== 'string' ||
        typeof response?.token !== 'string'
      ) {
        ShowToast(
          'error',
          'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
        )
        return
      }

      setSecret(response?.token)
      setQrcode(response?.qrcode)
      form.fields.secret.setState(response?.token)
    } catch (error) {
      setLoading(false)
      console.log(error)
      ShowToast(
        'error',
        'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
      )
    }
  }

  const handleGetQRCode = async () => {
    try {
      handleGetQRCodeUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
      )
    }
  }

  // Last step
  const handleFinish2FAUsecase = async () => {
    try {
      setLoading(true)

      if (!secret) {
        ShowToast(
          'error',
          'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
        )
        return
      }

      const result = await props.useCaseFinish.handle({
        secret,
        token: form.fields.token.value,
      })

      setLoading(false)

      if (result.isFailure) {
        switch ((result?.error as any)?.type) {
          case 'UserTwoAuthRecoveryInvalidCodeError':
            ShowToast(
              'error',
              'O código inserido está incorreto. Tente novamente.',
            )
            break

          default:
            ShowToast(
              'error',
              'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
            )
            break
        }

        return
      }

      ShowToast(
        'success',
        'Autenticação de 2FA como método de recuperação salva com sucesso',
      )

      navigate('/auth/sign-in')
    } catch (error) {
      setLoading(false)
      console.log(error)
      ShowToast(
        'error',
        'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
      )
    }
  }

  const handleFinish2FA = async () => {
    try {
      form.fields.secret.setState(secret)
      const validationResult = await form.validate()

      if (validationResult.isFailure) {
        ShowToast('error', 'O código deve ter 6 dígitos')
        return
      }

      handleFinish2FAUsecase()
    } catch (error) {
      console.log(error)

      ShowToast(
        'error',
        'Ocorreu um erro ao tentar configurar a autenticação de 2 fatores. Tente novamente mais tarde.',
      )
    }
  }

  const handleNextStep = () => {
    form.fields.token.setState('')
    setValidateStep(true)
  }

  const handlePreviousStep = () => {
    setValidateStep(false)
  }

  useEffect(() => {
    if (validateStep) return
    handleGetQRCode()
  }, [validateStep])

  return (
    <ReactPageBaseWithSloganComponent>
      <Box
        sx={{
          display: 'grid',
          gridAutoRows: 'max-content',
          justifySelfc: 'center',
          justifyItems: 'center',
          marginLeft: '70px',
          marginRight: '130px',
          width: '505px',
        }}
      >
        {/* Etapa 1 */}
        {!validateStep && (
          <Box
            sx={{
              display: 'grid',
              gridAutoRows: 'max-content',
              justifySelfc: 'center',
              justifyItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: '700',
              }}
            >
              Cadastro
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                marginTop: '32px',
                textAlign: 'center',
              }}
            >
              Siga o passo a passo para cadastrar a autenticação em 2 etapas
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                marginTop: '32px',
                color: theme.palette.text.disabled,
              }}
            >
              1 - Baixe um aplicativo de autenticação de dois fatores (ex:
              Google Authenticator). <br />2 - Escaneie o QRCode abaixo no
              aplicativo. <br />
              3- Clique em próximo passo e insira o código variável que aparece
              na sua tela a cada 60 segundos.
            </Typography>
            <Box
              sx={{
                marginTop: '32px',
              }}
            >
              {qrcode && <BaseQRCode qrcodeUrl={qrcode} />}
              {!qrcode && <CircularProgress />}
            </Box>
          </Box>
        )}
        {/* Etapa 2 */}
        {validateStep && (
          <Box
            sx={{
              display: 'grid',
              gridAutoRows: 'max-content',
              justifySelfc: 'center',
              justifyItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: '700',
              }}
            >
              Cadastro
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                marginTop: '32px',
                textAlign: 'center',
              }}
            >
              Insira o código informado no aplicativo
            </Typography>
            <Box
              sx={{
                marginTop: '32px',
              }}
            >
              <BaseInputCode length={6} {...form.fields.token} />
            </Box>
          </Box>
        )}
        <Box
          sx={{
            marginTop: '32px',
            width: '505px',
          }}
        >
          {!validateStep && (
            <Button fullWidth variant="contained" onClick={handleNextStep}>
              Próximo passo
            </Button>
          )}
          {validateStep && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                justifyItems: 'center',
                gap: '16px',
              }}
            >
              <Button fullWidth variant="outlined" onClick={handlePreviousStep}>
                Gerar novo QRCode
              </Button>
              {loading && <CircularProgress />}
              {!loading && (
                <Button fullWidth variant="contained" onClick={handleFinish2FA}>
                  Finalizar cadastro
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </ReactPageBaseWithSloganComponent>
  )
}
