import FilterListIcon from '@mui/icons-material/FilterList'
import { Button, Stack, Typography } from '@mui/material'
import React, { FC } from 'react'

interface Props {
  title: string
  icon?: React.ReactNode
  clickEvent: () => void
}

export const HeaderWithFilter: FC<Props> = ({ title, icon, clickEvent }) => {
  return (
    <Stack direction="row" alignItems="center">
      <Typography>{title}</Typography>
      <Button id={`${title?.trim()}-table-header-button`} onClick={clickEvent}>
        {icon || <FilterListIcon sx={{ color: '#ffffff' }} />}
      </Button>
    </Stack>
  )
}
